import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportVersionStatus } from '@xbs/xbs-enums';
import { InternalLocalFileReportInstance } from '../../models';
import { actions as localReportsActions } from '../../redux/localReports';
import httpService from '../../services/http';

interface PollingWordData {
  [instanceId: number]: number | NodeJS.Timeout;
}

const getReportInstanceData = async (reportId: number, instanceId: number) => {
  const instanceResult = await httpService.request<InternalLocalFileReportInstance>({
    method: 'get',
    apiUrlKey: 'baseUrl',
    relativePath: `internal-local-file-reports/${String(reportId)}/instance/${String(instanceId)}`
  });
  const instanceData = instanceResult.data;
  return instanceData;
};

const getWordReportGeneratingStatus = async (reportId: number, instanceId: number) => {
  return (
    await httpService.request<{ internalLocalfileWordReportInstanceStatusId: number }>({
      method: 'get',
      apiUrlKey: 'baseUrl',
      relativePath: `internal-local-file-reports/${String(reportId)}/instance/${String(
        instanceId
      )}/word-report-generation-status`
    })
  ).data;
};

export const useLocalReportsWordPolling = () => {
  // We'll use the state that comes from the setPollingData's callback. That's to avoid race conditions due
  // to the use of setInterval. That's why we skip naming the state variable here.
  const [_, setPollingData] = useState<PollingWordData>({});
  const dispatch = useDispatch();

  /**
   * @param reportName - To form the required data structure to update the report tile.
   * @param reportId - The report the new instance belongs to id.
   * @param instanceId - The newly created instance id.
   * @param previousInstanceId - The instance from where the new instance was created from.
   */
  const pollWordAndUpdate = useCallback(
    function (reportName: string, reportId: number, instanceId: number, previousInstanceId?: number) {
      const intervalReference = setInterval(async () => {
        try {
          const instanceStatus = await getWordReportGeneratingStatus(reportId, instanceId);
          const generationStatus =
            ReportVersionStatus.ById[instanceStatus.internalLocalfileWordReportInstanceStatusId].Name;
          if (generationStatus !== 'Converting' && generationStatus !== 'Pending') {
            const instanceData = await getReportInstanceData(reportId, instanceId);
            /**
             * TODO (DUO-722): Create a new endpoint that brings the whole report instance data, including
             * 'internalLocalfileReportId' and 'name' fields. After that, refactor 'getReportInstanceData'
             * to call that endpoint and remove this function's 'reportName' argument.
             */
            dispatch(
              localReportsActions.replaceLocalFileReportInstanceData({
                previousInstanceId: previousInstanceId ?? instanceId,
                instanceData: {
                  ...instanceData,
                  internalLocalfileReportId: reportId,
                  status: instanceData.status,
                  name: reportName
                }
              })
            );

            setPollingData((pollingData) => {
              clearInterval(pollingData[instanceId] as number);
              const newPollingData = { ...pollingData };
              newPollingData[instanceId] = -1;
              return newPollingData;
            });
          }
        } catch {}
      }, 5000);

      setPollingData((pollingData) => ({ ...pollingData, [instanceId]: intervalReference }));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      setPollingData((pollingData) => {
        for (const instanceId in pollingData) {
          if (instanceId) {
            clearInterval(pollingData[instanceId] as number);
          }
        }

        return {};
      });
    };
  }, []);

  return { pollWordAndUpdate };
};
