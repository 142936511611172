import { InternalLocalFileSofEntitiesVisibility as Selection } from '@xbs/xbs-enums';
import { Entity } from '../../../../models';
import { EntityStatementOfFactsConfig } from '../../../../models/reports.interface';

export const createIncludeAllEntitiesConfig = (
  primaryEntities: Array<Partial<Entity>>,
  tradingPartners: Array<Partial<Entity>>,
  upe: Partial<Entity>
) => {
  const getIds = (entities: Array<Partial<Entity>>) => {
    return entities.filter((entity) => entity.entityId !== upe.entityId).map((entity: any) => entity.entityId);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const entityStatementOfFactsConfig: Omit<EntityStatementOfFactsConfig, 'displayOrgChart'> = {
    optionSelected: Selection.ByName.IncludeAll.Id,
    showUPE: true,
    entitiesSelected: {
      primaryEntityIds: getIds(primaryEntities),
      tradingPartnersIds: getIds(tradingPartners)
    }
  };

  return entityStatementOfFactsConfig;
};
