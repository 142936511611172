import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Reports } from '../Reports';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/:jurisdictionId" component={Reports} />
        <Route path="/" component={Reports} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
