import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LocalReportsState,
  PartialEntity,
  PreviousReportVersionData,
  SetupData,
  SummariesData,
  TransactionItem,
  SelectedTransactionOrdering,
  UpdateAppendixFilePayload,
  ReorderingAppendixPayload,
  PBAItem
} from './localReports.proptype';
import { fetchLanguages } from './thunks/languages';
import {
  createPrimaryTradingPartnersMap,
  fetchFinalLocalFileReports,
  fetchReportInstancesForJurisdiction,
  fetchTemplates,
  fetchWorkingLocalFileReports
} from './thunks/localReports';
import { fetchContainerPolicies } from './thunks/policies';
import {
  deleteReportVersion,
  fetchAllInternalLocalFileReportVersions,
  fetchInternalLocalFileReportInstance,
  updateReportActiveVersion
} from './thunks/reportVersions';
import { getSignedUrlForAppendix, uploadReportToSignedUrl } from './thunks/upload';
import { FetchLoadingStateEnum } from '../../constants';
import { InternalLocalFileReportInstance, LocalReportData } from '../../models';
import { ReportSummaryAccordions, ReportSummaryCoreSection, CustomAppendix } from '../../models/reports.interface';

export const initialState: LocalReportsState = {
  finalLocalFiles: null,
  emailStatus: null,
  workingLocalFiles: null,
  localFileReports: [],
  reportVersions: [],
  languageList: [],
  templateList: [],
  containerPolicies: [],
  setup: {
    reportTitle: '',
    language: 'en',
    currency: '',
    reportTemplate: '',
    limitWording: false,
    addPageNumber: false
  },
  primaryTradingPartnersMap: null,
  primaryTradingPartnersMapIsCreating: false,
  summaries: {
    executive: '',
    conclusion: '',
    orgChart: ''
  },
  activeReport: null,
  configurationTab: {
    reportSummary: {
      isEntitiesOpen: false,
      isReportsSectionOpen: false,
      isTransactionsOpen: false,
      isPBAsOpen: false,
      reportSections: {
        core: [],
        appendix: []
      }
    },
    entityStatementOfFactsConfig: {
      optionSelected: 0,
      showUPE: false,
      displayOrgChart: true,
      entitiesSelected: {
        primaryEntityIds: [],
        tradingPartnersIds: []
      }
    },
    controlledTransactionsDocumentationConfig: {
      optionSelected: 0,
      transactionsSelectedIds: []
    },
    profitBasedAnalysisConfig: {
      pbaHideFinancials: {}
    },
    appendixConfigState: {
      currentAppendix: ''
    }
  },
  loading: {}
};

const localReportsSlice = createSlice({
  name: 'localReports',
  initialState,
  reducers: {
    setSelectedPrimaryEntities(state, action: PayloadAction<Array<PartialEntity['entityId']>>) {
      const entityIds = new Set(action.payload);

      Object.keys(state.primaryTradingPartnersMap ?? {}).forEach((entityId) => {
        state.primaryTradingPartnersMap![Number(entityId)].selected = entityIds.has(Number(entityId));
      });
      const customSelection =
        state.configurationTab.entityStatementOfFactsConfig?.entitiesSelected?.primaryEntityIds ?? [];
      if (state.configurationTab.entityStatementOfFactsConfig && customSelection.length > 0) {
        const customSelectedEntityIds = customSelection.filter(function (customSelectedEntityId) {
          return entityIds.has(customSelectedEntityId);
        });
        state.configurationTab.entityStatementOfFactsConfig.entitiesSelected.primaryEntityIds = customSelectedEntityIds;
      }
    },
    setSelectedTradingPartners(state, action: PayloadAction<Array<PartialEntity['entityId']>>) {
      const entityIds = new Set(action.payload);

      Object.values(state.primaryTradingPartnersMap ?? {}).forEach((primaryEntity) => {
        Object.keys(primaryEntity.tradingPartners).forEach((tradPartnerId) => {
          state.primaryTradingPartnersMap![primaryEntity.entityId].tradingPartners[Number(tradPartnerId)].selected =
            entityIds.has(Number(tradPartnerId));
        });
      });

      const customSelection =
        state.configurationTab.entityStatementOfFactsConfig?.entitiesSelected?.tradingPartnersIds ?? [];
      if (state.configurationTab.entityStatementOfFactsConfig && customSelection.length > 0) {
        const customSelectedEntityIds = customSelection.filter(function (customSelectedEntityId) {
          return entityIds.has(customSelectedEntityId);
        });
        state.configurationTab.entityStatementOfFactsConfig.entitiesSelected.tradingPartnersIds =
          customSelectedEntityIds;
      }
    },
    setSelectedTransactions(state, action: PayloadAction<Array<TransactionItem['transactionId']>>) {
      const transactionIds = new Set(action.payload);

      Object.values(state.primaryTradingPartnersMap ?? {}).forEach((primaryEntity) => {
        Object.keys(primaryEntity.tradingPartners).forEach((tradPartnerId) => {
          state.primaryTradingPartnersMap![primaryEntity.entityId].tradingPartners[
            Number(tradPartnerId)
          ].transactions.forEach((trans) => {
            trans.selected = transactionIds.has(Number(trans.transactionId));
          });
        });
      });
    },
    setSelectedPBAs(state, action: PayloadAction<Array<PBAItem['pbaId']>>) {
      const pbaIds = new Set(action.payload);

      Object.values(state.primaryTradingPartnersMap ?? {}).forEach((primaryEntity) => {
        Object.keys(primaryEntity.tradingPartners).forEach((tradPartnerId) => {
          state.primaryTradingPartnersMap![primaryEntity.entityId].tradingPartners[
            Number(tradPartnerId)
          ].transactions.forEach((trans) => {
            trans.pbas.forEach((pba) => {
              pba.selected = pbaIds.has(Number(pba.pbaId));
            });
          });
        });
      });
    },
    setSelectedTransactionsOrdering(state, action: PayloadAction<SelectedTransactionOrdering>) {
      const ordering = action.payload;
      Object.values(state.primaryTradingPartnersMap ?? {}).forEach((primaryEntity) => {
        Object.keys(primaryEntity.tradingPartners).forEach((tradPartnerId) => {
          state.primaryTradingPartnersMap![primaryEntity.entityId].tradingPartners[
            Number(tradPartnerId)
          ].transactions.forEach((trans) => {
            trans.ordering = ordering[trans.transactionId];
          });
        });
      });
    },
    setConfigurationTabReportSectionsOptions(state, action: PayloadAction<ReportSummaryCoreSection[]>) {
      state.configurationTab.reportSummary.reportSections.core = action.payload;
    },
    toggleCoreSectionInReportSummaryReportSection(state, action: PayloadAction<ReportSummaryCoreSection['code']>) {
      const index = state.configurationTab.reportSummary.reportSections.core.findIndex(
        (section) => section.code === action.payload
      );
      const value = state.configurationTab.reportSummary.reportSections.core[index].active;
      state.configurationTab.reportSummary.reportSections.core[index].active = !value;
    },
    toggleAppendixSectionInReportSummaryReportSection(state, action: PayloadAction<CustomAppendix['title']>) {
      const index = state.configurationTab.reportSummary.reportSections.appendix.findIndex(
        (section) => section.title === action.payload
      );
      const value = state.configurationTab.reportSummary.reportSections.appendix[index].display;
      state.configurationTab.reportSummary.reportSections.appendix[index].display = !value;
    },
    toggleAccordionInReportSummary(state, action: PayloadAction<keyof ReportSummaryAccordions>) {
      const value = state.configurationTab.reportSummary[action.payload];
      state.configurationTab.reportSummary[action.payload] = !value;
    },
    setConfigurationTabEntityStatementOfFactsConfig(state, action: PayloadAction<any>) {
      state.configurationTab.entityStatementOfFactsConfig = action.payload;
    },
    setControlledTransactionsDocumentationConfig(state, { payload }) {
      state.configurationTab.controlledTransactionsDocumentationConfig = payload;
    },
    setProfitBasedAnalysisConfig(state, { payload }) {
      state.configurationTab.profitBasedAnalysisConfig = payload;
    },
    setCustomAppendices(state, action: PayloadAction<CustomAppendix[]>) {
      state.configurationTab.reportSummary.reportSections.appendix = action.payload;
    },
    addCustomAppendix(state, action: PayloadAction<CustomAppendix>) {
      state.configurationTab.reportSummary.reportSections.appendix.push(action.payload);
    },
    updateNewAppendixInAppendixConfigState(state, action: PayloadAction<CustomAppendix>) {
      const updatedAppendix = action.payload;
      const appendix = state.configurationTab.reportSummary.reportSections.appendix.find(
        (a) => a.identifier === updatedAppendix.identifier
      );
      if (appendix) {
        const appendixIndex = state.configurationTab.reportSummary.reportSections.appendix.indexOf(appendix);
        state.configurationTab.reportSummary.reportSections.appendix[appendixIndex] = updatedAppendix;
      }
    },
    updateAppendixTitleInAppendixConfigState(
      state,
      action: PayloadAction<{ appendixId: CustomAppendix['identifier']; newTitle: CustomAppendix['title'] }>
    ) {
      const { newTitle, appendixId } = action.payload;
      const appendix = state.configurationTab.reportSummary.reportSections.appendix.find(
        (a) => a.identifier === appendixId
      )!;
      const appendixIndex = state.configurationTab.reportSummary.reportSections.appendix.indexOf(appendix);
      state.configurationTab.reportSummary.reportSections.appendix[appendixIndex].title = newTitle;
    },
    setCurrentAppendixInAppendixConfigState(state, action: PayloadAction<string>) {
      const { payload: appendixId } = action;

      const appendix = state.configurationTab.reportSummary.reportSections.appendix.find(
        (a) => a.identifier === appendixId
      );

      if (appendix) {
        state.configurationTab.appendixConfigState.currentAppendix = appendix.identifier;
      } else {
        state.configurationTab.appendixConfigState.currentAppendix = '';
      }
    },
    addNewFileForAppendixInAppendixConfigState(
      state,
      action: PayloadAction<{ file: CustomAppendix['files'][0]; identifier: CustomAppendix['identifier'] }>
    ) {
      const { file, identifier } = action.payload;
      const appendix = state.configurationTab.reportSummary.reportSections.appendix.find(
        (a) => a.identifier === identifier
      );
      if (appendix) {
        const appendixIndex = state.configurationTab.reportSummary.reportSections.appendix.indexOf(appendix);
        appendix.files = [...appendix.files, file];

        state.configurationTab.reportSummary.reportSections.appendix[appendixIndex] = appendix;
      }
    },
    deleteAppendixInAppendixConfigState(state, action: PayloadAction<{ identifier: CustomAppendix['identifier'] }>) {
      const { identifier } = action.payload;

      const appendices = [...state.configurationTab.reportSummary.reportSections.appendix];
      const appendix = appendices.find((a) => a.identifier === identifier)!;
      const appendixIndex = appendices.indexOf(appendix);
      appendices.splice(appendixIndex, 1);
      state.configurationTab.reportSummary.reportSections.appendix = appendices;
    },
    deleteAppendixFileInAppendixConfigState(state, action: PayloadAction<UpdateAppendixFilePayload>) {
      const { file, appendix: appendixPayload }: UpdateAppendixFilePayload = action.payload;
      const appendices = [...state.configurationTab.reportSummary.reportSections.appendix];
      const fileIndex = appendixPayload.files.findIndex((f) => f.order === file.order);
      const appendix = appendices.find((a) => a.order === appendixPayload.order)!;
      const appendixIndex = appendices.indexOf(appendix);

      appendix.files.splice(fileIndex, 1);
      appendix.files = appendix.files.map((file: CustomAppendix['files'][0], i: number) => ({
        ...file,
        order: i + 1
      }));

      appendices.splice(appendixIndex, 1, { ...appendix });
      state.configurationTab.reportSummary.reportSections.appendix = appendices;
    },
    updateAppendixFile(state, action: PayloadAction<UpdateAppendixFilePayload>) {
      const { configurationTab } = state;
      const appendices = [...configurationTab.reportSummary.reportSections.appendix];
      const { file, appendix } = action.payload;
      const appendixIndexFound = appendices.findIndex(
        (appendixItem) => appendixItem.identifier === appendix.identifier
      );
      if (appendixIndexFound >= 0) {
        const appendix = appendices[appendixIndexFound];
        const { files } = appendix;
        const fileIndexFound = files.findIndex((fileItem) => fileItem.name === file.name);
        if (fileIndexFound >= 0) {
          files[fileIndexFound] = { ...file };
        }

        appendices[appendixIndexFound] = {
          ...appendix,
          files
        };

        configurationTab.reportSummary.reportSections.appendix = appendices;
      }

      state.configurationTab = configurationTab;
    },
    reorderAppendix(state, action: PayloadAction<ReorderingAppendixPayload>) {
      const { appendixIdentifier, source, destination } = action.payload;
      if (appendixIdentifier && source >= 0 && destination >= 0) {
        const appendices = [...state.configurationTab.reportSummary.reportSections.appendix];
        const appendix = appendices.splice(source, 1).pop()!;
        appendices.splice(destination, 0, appendix);
        state.configurationTab.reportSummary.reportSections.appendix = [
          ...appendices.map((appendix, i) => ({ ...appendix, order: i + 1 }))
        ];
      }
    },
    setSetup(state, action: PayloadAction<SetupData>) {
      state.setup = action.payload;
    },
    setSummaries(state, action: PayloadAction<SummariesData>) {
      state.summaries = action.payload;
    },
    resetData(state) {
      state.languageList = [...state.languageList, { isoCode: 'reapply_call' }];
      state.templateList = initialState.templateList;
      state.setup = initialState.setup;
      state.primaryTradingPartnersMap = initialState.primaryTradingPartnersMap;
      state.summaries = initialState.summaries;
      state.error = initialState.error;
      state.configurationTab = initialState.configurationTab;
    },
    resetReportVersionsData(state) {
      state.reportVersions = initialState.reportVersions;
    },
    setPreviousVersionData(state, action: PayloadAction<PreviousReportVersionData>) {
      state.setup = action.payload.setupData;
      state.summaries = action.payload.summaries;
    },
    setLocalFileReport(state, action: PayloadAction<LocalReportData[] | null>) {
      state.localFileReports = action.payload;
    },
    replaceLocalFileReportInstanceData(
      state,
      action: PayloadAction<{ previousInstanceId: number; instanceData: LocalReportData }>
    ) {
      if (state.localFileReports === null) {
        return;
      }

      const instanceToUpdateIndex = state.localFileReports?.findIndex(
        (instance) => instance.internalLocalfileReportInstanceId === action.payload.previousInstanceId
      );

      if (instanceToUpdateIndex === -1) {
        return;
      }

      state.localFileReports[instanceToUpdateIndex] = action.payload.instanceData;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLanguages.fulfilled, (state, { payload: languagesList }) => {
        state.languageList = languagesList;
      })
      .addCase(fetchLanguages.rejected, (state) => {
        state.languageList = [{ isoCode: 'error' }];
      })
      .addCase(fetchTemplates.fulfilled, (state, { payload: templateList }) => {
        state.templateList = templateList;
      })
      .addCase(createPrimaryTradingPartnersMap.pending, (state) => {
        state.primaryTradingPartnersMapIsCreating = true;
      })
      .addCase(createPrimaryTradingPartnersMap.fulfilled, (state, { payload: primaryTradingPartnersMap }) => {
        state.primaryTradingPartnersMap = primaryTradingPartnersMap;
        state.primaryTradingPartnersMapIsCreating = false;
      })
      .addCase(fetchAllInternalLocalFileReportVersions.fulfilled, (state: LocalReportsState, { payload }) => {
        const reportVersions: any = payload.map((versionData) => {
          return {
            version: versionData.version,
            internalLocalfileReportInstanceId: versionData.internalLocalfileReportInstanceId,
            // Todo: Remove once report status migration from old UI is done
            reportStatus: versionData.reportStatus ?? 'draft ',
            isUploadedReport: versionData.isUploadedReport,
            createdAt: versionData.createdAt,
            isActive: versionData.isActive,
            finalVersionFlag: versionData.finalVersionFlag,
            languageIso: versionData.languageIso,
            reportCurrency: versionData.reportCurrency,
            binaryPath: versionData.binaryPath
          };
        });
        state.reportVersions = reportVersions;

        const activeReport = payload?.find((report) => report.isActive);
        state.activeReport = activeReport;
      })
      .addCase(updateReportActiveVersion.fulfilled, (state: LocalReportsState, { meta: { arg: params } }) => {
        const { internalLocalfileReportInstanceId } = params;
        if (state.reportVersions === null) {
          return state;
        }

        // do all this with payload when it is available
        const reportVersions = [...state.reportVersions];

        const activeVersion = reportVersions.find((reportVersion) => reportVersion.isActive);
        if (activeVersion) {
          activeVersion.isActive = 0;
        }

        const newActiveVersion = reportVersions.find(
          (reportVersion: any) => reportVersion.internalLocalfileReportInstanceId === internalLocalfileReportInstanceId
        );
        if (newActiveVersion) {
          newActiveVersion.isActive = 1;
        }

        state.reportVersions = reportVersions;
      })
      .addCase(deleteReportVersion.fulfilled, (state: LocalReportsState, { meta: { arg: params } }) => {
        if (state.reportVersions === null) {
          return state;
        }

        const reportVersions = [...state.reportVersions];
        const deletedVersionIndex = reportVersions.findIndex(
          (reportVersion) =>
            reportVersion.internalLocalfileReportInstanceId === params.internalLocalfileReportInstanceId
        );
        if (deletedVersionIndex >= 0) {
          reportVersions.splice(deletedVersionIndex, 1);
        }

        // todo: assign new active version - to be done on backend?

        state.reportVersions = reportVersions;
      })
      .addCase(
        fetchInternalLocalFileReportInstance.fulfilled,
        (state: LocalReportsState, action: PayloadAction<InternalLocalFileReportInstance>) => {
          state.activeReport.tradingPartners = action.payload.tradingPartners;
          state.activeReport.reportTransactions = action.payload.reportTransactions;
        }
      )
      .addCase(
        fetchWorkingLocalFileReports.fulfilled,
        (state: LocalReportsState, { payload: internalLocalFileReport }) => {
          state.workingLocalFiles = internalLocalFileReport;
        }
      )
      .addCase(fetchFinalLocalFileReports.fulfilled, (state: LocalReportsState, { payload: finalLocalReport }) => {
        state.finalLocalFiles = finalLocalReport;
      })
      .addCase(
        fetchReportInstancesForJurisdiction.fulfilled,
        (state: LocalReportsState, { payload: localFileReports }) => {
          state.localFileReports = localFileReports;
        }
      )
      .addCase(fetchContainerPolicies.fulfilled, (state: LocalReportsState, { payload: containerPolicies }) => {
        state.containerPolicies = containerPolicies;
      })
      .addCase(getSignedUrlForAppendix.pending, (state: LocalReportsState) => {
        state.loading.getSignedUrlForAppendix = FetchLoadingStateEnum.loading;
      })
      .addCase(getSignedUrlForAppendix.fulfilled, (state: LocalReportsState) => {
        state.loading.getSignedUrlForAppendix = FetchLoadingStateEnum.fulfilled;
      })
      .addCase(getSignedUrlForAppendix.rejected, (state: LocalReportsState) => {
        state.loading.getSignedUrlForAppendix = FetchLoadingStateEnum.error;
      })
      .addCase(uploadReportToSignedUrl.pending, (state: LocalReportsState) => {
        state.loading.getSignedUrlForAppendix = FetchLoadingStateEnum.loading;
      })
      .addCase(uploadReportToSignedUrl.fulfilled, (state: LocalReportsState) => {
        state.loading.getSignedUrlForAppendix = FetchLoadingStateEnum.fulfilled;
      })
      .addCase(uploadReportToSignedUrl.rejected, (state: LocalReportsState) => {
        state.loading.getSignedUrlForAppendix = FetchLoadingStateEnum.error;
      })
      .addMatcher(
        (action) => action.type.match(/^localReports\/.+\/pending$/),
        (state) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^localReports\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export const { reducer, actions } = localReportsSlice;
export default reducer;
