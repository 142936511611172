import { ComponentType, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReportsCentralTabs, ReportsProps, ReportsTabProps } from './Reports.proptype';
import { formatReportsData } from './ReportsUtilities';
import { useWorkingContainer } from '../../hooks/container/useWorkingContainer';
import { useEntities } from '../../hooks/entity/useEntities';
import { useGlobalGuides } from '../../hooks/global-guides/useGlobalGuides';
import { useMasterFileReportsInfo } from '../../hooks/masterfile-report/useMasterFileReportsInfo';
import { useContainerPolicies } from '../../hooks/policies/useContainerPolicies';
import { useComplianceCentralReportsInfo } from '../../hooks/report/useComplianceCentralReportsInfo';
import { useTransactionsList } from '../../hooks/transaction/useTransactionsList';
import { ContainerPolicy } from '../../models';
import {
  fetchComplianceCentralReportsInfo,
  fetchComplianceCentralMasterFileReportsInfo
} from '../../redux/complianceCentral/thunks/complianceCentral';
import { fetchEntities } from '../../redux/entities/thunks/entities';
import { fetchGlobalGuides } from '../../redux/globalGuides/thunks/globalGuides';
import { fetchContainerPolicies } from '../../redux/localReports/thunks/policies';
import { fetchTransactions } from '../../redux/transactions/thunks/transactions';
import { AppDispatch } from '../../store';
import tokens from '../../styles/designTokens';
import { hasAccess } from '../../utils';

const { LocalReportTab } = ReportsCentralTabs;

const tabs: ReportsTabProps[] = [
  {
    key: LocalReportTab,
    label: LocalReportTab,
    disabled: false
  }
  // {
  //   key: 'Master File',
  //   label: 'Master File',
  //   disabled: true
  // }
];

const Connector = ({ component: Component }: { component: ComponentType<ReportsProps> }) => {
  const [selectedTab, setSelectedTab] = useState(LocalReportTab);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const flags = useFlags();

  const masterFileReportsInfo = useMasterFileReportsInfo();
  const allEntities = useEntities();
  const allTransactions = useTransactionsList();
  const reportsInfo = useComplianceCentralReportsInfo();
  const globalGuides = useGlobalGuides();
  const container = useWorkingContainer();
  const containerPolicies: ContainerPolicy[] = useContainerPolicies();

  const hasAccessToDisableJurisdictionsByContainer = hasAccess(
    flags.tpUiReportsDisableJurisidictionNotAllowedContainer,
    container?.containerId
  );

  useEffect(() => {
    if (!globalGuides) {
      void dispatch(fetchGlobalGuides());
    }
  }, [dispatch, globalGuides]);

  useEffect(() => {
    if (allEntities && allTransactions) {
      setLoading(false);
    } else if (allEntities === null) {
      setLoading(true);
      void dispatch(fetchEntities());
    } else if (allTransactions === null) {
      setLoading(true);
      void dispatch(fetchTransactions());
    }
  }, [dispatch, allEntities, allTransactions]);

  useEffect(() => {
    if (reportsInfo === null) {
      void dispatch(fetchComplianceCentralReportsInfo(container!.containerId));
    }
  }, [dispatch, reportsInfo, container]);

  useEffect(() => {
    if (masterFileReportsInfo === null) {
      void dispatch(fetchComplianceCentralMasterFileReportsInfo(container!.containerId));
    }
  }, [dispatch, masterFileReportsInfo, container]);

  useEffect(() => {
    if (globalGuides === null) {
      void dispatch(fetchGlobalGuides());
    }
  }, [dispatch, globalGuides]);

  useEffect(() => {
    if (containerPolicies.length === 0 && hasAccessToDisableJurisdictionsByContainer) {
      void dispatch(fetchContainerPolicies());
    }
  }, [dispatch, containerPolicies, hasAccessToDisableJurisdictionsByContainer]);

  const { entityReportData, reportCounts } = useMemo(
    () => formatReportsData({ allEntities, allTransactions, reportsInfo, globalGuides }),
    [allEntities, allTransactions, reportsInfo, globalGuides]
  );

  const totalReports = reportCounts.draft + reportCounts.review + reportCounts.published + reportCounts.final;

  const jurisdictionsCircle = {
    percent: 1,
    metric: reportCounts.totalJurisdictions,
    type: 'Jurisdictions',
    color: tokens.core3
  };
  const counterCircles = [
    { percent: reportCounts.draft / totalReports, metric: reportCounts.draft, type: 'Draft', color: '#8D949C' },
    { percent: reportCounts.review / totalReports, metric: reportCounts.review, type: 'Review', color: '#00ACBB' },
    {
      percent: reportCounts.published / totalReports,
      metric: reportCounts.published,
      type: 'Published',
      color: '#6E56DB'
    },
    { percent: reportCounts.final / totalReports, metric: reportCounts.final, type: 'Final', color: tokens.product100 }
  ];
  return (
    <Component
      entityReportData={entityReportData}
      isLoading={isLoading}
      tabs={tabs}
      selectedTab={selectedTab}
      masterFileReportsInfo={masterFileReportsInfo ?? []}
      counterCircles={counterCircles}
      jurisdictionsCircle={jurisdictionsCircle}
      containerPolicies={containerPolicies}
      onSelectTab={(tab: number) => {
        setSelectedTab(tabs[tab].key as ReportsCentralTabs);
      }}
      onNavigate={(path) => {
        history.push(path);
      }}
    />
  );
};

export default Connector;
