import { ReportVersionStatus } from '@xbs/xbs-enums';
import { getAuthInfo, decodeTokens, getAppConfig } from './';
import { ReportStatus } from '../app/LocalReports/LocalReports.proptype';
import { ROLES } from '../app/Router/pureConstants';
let userRole: string | undefined;
let userId: string | undefined;
let xbsEnvironmentShort: string | undefined;

if (getAuthInfo?.()) {
  const { authToken } = getAuthInfo();
  const decodedToken = decodeTokens(authToken);
  const appConfig = getAppConfig();
  xbsEnvironmentShort = appConfig.xbsEnvironmentShort;
  userRole = decodedToken?.role;
  userId = decodedToken?.userId;
}

export function hasEditAccess() {
  return userRole !== ROLES.documentationReview && userRole !== ROLES.reportsOnly;
}

export function hasReportEmailAccess() {
  return (
    userRole &&
    [
      ROLES.psgAdminTransferPricing,
      ROLES.psgAnalystTransferPricing,
      ROLES.salesTransferPricing,
      ROLES.transferPricing
    ].includes(userRole)
  );
}

export function hasNotReportsOnlyAccess() {
  return userRole !== ROLES.reportsOnly;
}

export const canDownloadReport = (status: ReportStatus) => {
  if (!hasEditAccess()) {
    return status === 'final' || status === 'published';
  }

  return true;
};

export const canDownloadWordReport = (_wordStatus: number, wordBinaryPath?: string) => {
  return wordBinaryPath && wordBinaryPath !== '' && ReportVersionStatus.ById[_wordStatus].Name === 'Generated';
};

export function handleRoleRedirect(defaultPath: string) {
  if (userRole === ROLES.documentationReview || userRole === ROLES.reportsOnly) {
    window.location.replace(`/legacy/central/compliance`);
  } else {
    window.location.replace(defaultPath);
  }
}

type ACCESS_RULES = 'CONTAINER' | 'CONTAINER_ALL' | 'UI_ALL' | 'USER';
export function hasAccess(accessLevels: string[], containerId?: number) {
  const env = xbsEnvironmentShort ?? getAppConfig().xbsEnvironmentShort;
  const rules: { [key in ACCESS_RULES]: string } = {
    CONTAINER: `${env}-${String(containerId)}`,
    CONTAINER_ALL: `${env}-*`,
    UI_ALL: `ui-${env}-*`,
    USER: String(userId)
  };

  return Object.values(rules).some((rule) => accessLevels.includes(rule));
}
