import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ConnectorProps } from './TransactionSectionArea.proptype';
import { useReportSummaryTransactionsIsOpen } from '../../../../../../hooks/transaction/useReportSummaryTransactionsIsOpen';
import { actions } from '../../../../../../redux/localReports';

const Connector = ({ component: Component, controlledTransactionSelection, selectedTransactions }: ConnectorProps) => {
  const dispatch = useDispatch();

  const emitToggleTransactionSectionAccordion = useCallback(() => {
    dispatch(actions.toggleAccordionInReportSummary('isTransactionsOpen'));
  }, [dispatch]);

  const selectedTransactionSectionsIsOpen = useReportSummaryTransactionsIsOpen();

  return (
    <Component
      isOpen={selectedTransactionSectionsIsOpen}
      setIsOpen={emitToggleTransactionSectionAccordion}
      controlledTransactionSelection={controlledTransactionSelection}
      selectedTransactions={selectedTransactions}
    />
  );
};

export default Connector;
