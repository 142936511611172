import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ConnectorProps } from './SummariesTab.proptypes';
import { useSummariesData } from '../../../hooks/summaries-data/useSummariesData';
import { actions as localReportsActions } from '../../../redux/localReports';
import { SummariesData } from '../../../redux/localReports/localReports.proptype';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const { executive, conclusion, orgChart } = useSummariesData();

  const onHandlerComplete = useCallback(
    (info: SummariesData) => {
      dispatch(localReportsActions.setSummaries(info));
    },
    [dispatch]
  );

  return (
    <Component
      defaultExecutiveValue={executive}
      defaultConclusionValue={conclusion}
      defaultOrgChartValue={orgChart}
      onComplete={onHandlerComplete}
    />
  );
};

export default Connector;
