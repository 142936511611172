import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, Chip, makeStyles } from '@material-ui/core';
import { CheckCircle, CheckCircleOutline, RadioButtonUnchecked as CircleOutlined } from '@material-ui/icons';
import { ButtonGroup, Button } from '@mui/material';
import { Table } from '../../app/Table';
import { TableColumnDef } from '../../app/Table/Table.proptype';
import { TableDND } from '../../app/TableDND';
import { filterData } from '../../services/filtering';
import tokens from '../../styles/designTokens';
import { SearchBar } from '../SearchBar';

export interface SearchSelectListProps {
  title: string;
  searchBy: string;
  searchByPlaceholder: string;
  columns: TableColumnDef[];
  data: SearchSelectListItem[];
  dndList?: boolean;
  selectedRows: Set<any>;
  onSelectAll: (shouldSelect: boolean) => void;
  onSelectOne: (shouldSelect: boolean, value: any) => void;
  onSelectOrderList?: (list: number[]) => void;
}

// Only rowId is required
export interface SearchSelectListItem {
  [key: string]: any;
  rowId: string | number;
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.default,
      height: '100%',
      overflow: 'hidden',
      '& .MuiSvgIcon-root': {
        fontSize: '1.4rem',
        stroke: theme.palette.background.default
      },
      '& .MuiTableCell-head:first-child': {
        paddingLeft: 0
      },
      '& .MuiTable-stickyHeader': {
        borderCollapse: 'collapse'
      },
      '& tbody': {
        borderTop: '1.1px solid ' + tokens.neutral100
      },
      '&.MuiTableRow-root': {
        backgroundColor: tokens.product15
      },
      '& .MuiTableCell-root': {
        fontSize: '0.875rem'
      },
      '& .selectedIcon': {
        color: tokens.product100,
        strokeWidth: '0px'
      },
      '& .unselectedIcon': {
        color: tokens.core2,
        strokeWidth: '0.8px' // Make the icon path thinner
      },
      '& .selectedRow': {
        '& .MuiTableCell-root': {
          backgroundColor: tokens.product15,
          '&:first-child': {
            color: tokens.product100
          }
        }
      }
    },
    headerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    selectTransactions: {
      alignItems: 'center',
      display: 'flex'
    },
    listContainer: {
      marginTop: '0.5rem',
      height: '100%',
      overflow: 'auto'
    },
    title: {
      fontSize: '1rem'
    },
    counter: {
      backgroundColor: tokens.product15,
      marginLeft: '1rem',
      minWidth: '2.31rem',
      height: '1.87rem',
      color: tokens.product100
    },
    searchBarContainer: {
      width: '80%'
    }
  };
});

export const SearchSelectList = ({
  title,
  searchBy,
  searchByPlaceholder,
  columns,
  data,
  dndList,
  selectedRows,
  onSelectAll,
  onSelectOne,
  onSelectOrderList
}: SearchSelectListProps) => {
  const [filterObject, setFilter] = useState<Record<string, string>>({ [searchBy]: '' });
  const [dnd, setDND] = useState(false);
  const classes = useStyles();

  const onSearchChange = (searchByText: string) => {
    setFilter({ ...filterObject, [searchBy]: searchByText });
  };

  const allSelected = selectedRows.size === data.length;

  const displayableData = useMemo(
    () =>
      data.map((item) => {
        const selected = selectedRows.has(item.rowId);
        return {
          ...item,
          className: selected ? 'selectedRow' : '',
          selectionColumn: (
            <Checkbox
              checked={selected}
              icon={<CircleOutlined className="unselectedIcon" data-testid="unselectedIcon" />}
              checkedIcon={<CheckCircle className="selectedIcon" data-testid="selectedIcon" />}
              onClick={() => {
                onSelectOne(!selected, item.rowId);
              }}
            />
          )
        };
      }),
    [data, selectedRows, onSelectOne]
  );

  const orderNullValues = useCallback((transactionOrdered: any[]) => {
    let i = 0;
    let n = 0;
    let indexT: any = {};
    while (i < transactionOrdered.length) {
      if (transactionOrdered[n].ordering) {
        n++;
      } else {
        indexT = transactionOrdered.splice(n, 1).pop();
        transactionOrdered.push(indexT);
      }

      i++;
    }

    return transactionOrdered;
  }, []);

  const emitOrderedlist = useCallback(
    (list: any[]) => {
      const orderedtransactions: any = {};
      list.forEach((item: any, i: number) => {
        orderedtransactions[item.rowId] = i + 1;
      });
      if (onSelectOrderList) {
        onSelectOrderList(orderedtransactions);
      }
    },
    [onSelectOrderList]
  );

  const setDataInOrder = useCallback(
    (data: any[]) => {
      let transactionOrdered: any[] = data.sort((a: any, b: any) => a.ordering - b.ordering);
      transactionOrdered = orderNullValues(transactionOrdered);
      return transactionOrdered;
    },
    [orderNullValues]
  );

  const setOrderToListTransactionSelectionChange = useCallback(() => {
    const data = displayableData;
    let selectedTransactions = data.filter((t: any) => selectedRows.has(t.rowId));
    selectedTransactions = setDataInOrder(selectedTransactions);
    emitOrderedlist(selectedTransactions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows.size]);

  useEffect(() => {
    setOrderToListTransactionSelectionChange();
  }, [selectedRows.size, setOrderToListTransactionSelectionChange]);

  const changeToDND = () => {
    setDND(true);
  };

  const changeToList = () => {
    setDND(false);
  };

  const listButtons = () => {
    const orderStyles = {
      width: '7rem',
      borderColor: dnd ? tokens.product100 : '#c0c0c0',
      color: dnd ? tokens.product100 : '#808080',
      backgroundColor: dnd ? tokens.product15 : ''
    };
    const listStyles = {
      width: '7rem',
      borderColor: dnd ? '#c0c0c0' : tokens.product100,
      color: dnd ? '#808080' : tokens.product100,
      backgroundColor: dnd ? '' : tokens.product15
    };

    const disabledDND = selectedRows.size === 0;

    return (
      <ButtonGroup color="inherit" aria-label="large button group">
        <Button sx={listStyles} onClick={changeToList}>
          List
        </Button>
        <Button sx={orderStyles} disabled={disabledDND} onClick={changeToDND}>
          Order
        </Button>
      </ButtonGroup>
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContainer}>
        <Box className={classes.selectTransactions}>
          <Box className={classes.title}>{title}</Box>
          <Chip className={classes.counter} label={selectedRows.size} />
        </Box>
        {dndList && listButtons()}
      </Box>

      <Box className={classes.listContainer}>
        {dnd ? (
          <TableDND
            selected={selectedRows}
            data={setDataInOrder(displayableData)}
            orderedList={emitOrderedlist}
            columns={[
              {
                key: data.length > 0 ? Object.keys(data[0])[0] : 'searchColumn',
                header: <div style={{ width: '100%', height: '100%' }} />
              },
              ...columns
            ]}
          />
        ) : (
          <Table
            data={setDataInOrder(filterData(displayableData, filterObject))}
            performTaskToDataAtTheBeginning={dndList ? setDataInOrder : undefined}
            columns={[
              {
                key: data.length > 0 ? Object.keys(data[0])[0] : 'searchColumn',
                header: (
                  <Box className={classes.searchBarContainer}>
                    <SearchBar placeholder={searchByPlaceholder} onSearchChange={onSearchChange} />
                  </Box>
                )
              },
              ...columns,
              {
                align: 'center',
                key: 'selectionColumn',
                header: (
                  <Checkbox
                    data-testid="selectAllCheckbox"
                    checked={allSelected && data.length > 0}
                    icon={<CheckCircleOutline className="unselectedIcon" />}
                    checkedIcon={<CheckCircle className="selectedIcon" />}
                    onClick={() => {
                      onSelectAll(!allSelected);
                    }}
                  />
                )
              }
            ]}
          />
        )}
      </Box>
    </Box>
  );
};
