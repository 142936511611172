import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFinalReports, fetchInternalReports } from './thunks/reports';
import { FinalLocalReport, InternalLocalFileReport, NormalizedStateSlice } from '../../models';
import { getWorkingContainer } from '../baseData/thunks/baseData';

interface ReportsState {
  // ids are entityId
  internal: NormalizedStateSlice<InternalLocalFileReport[]>;
  // ids are entityId
  final: NormalizedStateSlice<FinalLocalReport[]>;
  error?: string;
}

const initialState: ReportsState = {
  internal: { byId: null, allIds: [] },
  final: { byId: null, allIds: [] }
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchInternalReports.fulfilled, (state, { payload, meta: { arg: entityId } }) => {
        if (!state.internal.byId) {
          state.internal.byId = {};
        }

        state.internal.byId[entityId] = payload;

        if (!state.internal.allIds.includes(entityId)) {
          state.internal.allIds.push(entityId);
        }
      })
      .addCase(fetchFinalReports.fulfilled, (state, { payload, meta: { arg: entityId } }) => {
        if (!state.final.byId) {
          state.final.byId = {};
        }

        state.final.byId[entityId] = payload;

        if (!state.final.allIds.includes(entityId)) {
          state.final.allIds.push(entityId);
        }
      })
      .addMatcher(
        (action) => action.type.match(/^reports\/.+\/pending$/),
        (state: ReportsState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^reports\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default reportsSlice.reducer;
