import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { FileList } from './FileList';
import { DragDropFileUpload } from '../../../../../../components';
import { AppendixFileState, CustomAppendixFile } from '../../../../../../models/reports.interface';
import tokens from '../../../../../../styles/designTokens';
import { body1, body2, captionOverline } from '../../../../../../styles/typography';
import { ErrorUploadSvg } from '../Appendix.icons';
import { AppendixFileTableProps } from '../Appendix.proptype';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: '0 0 4px 0 rgba(0,0,0,0.12)',
    backgroundColor: tokens.white,
    paddingTop: '1.375rem',
    paddingBottom: '1.375rem',
    borderRadius: '0.25rem',
    boxSizing: 'border-box',
    position: 'relative'
  },
  sectionContainer: {
    width: '100%',
    paddingLeft: '1.375rem',
    paddingRight: '1.375rem'
  },
  titleOptional: {
    ...body2,
    color: tokens.core2
  },
  titleInput: {
    width: '100%',
    height: '2rem',
    marginTop: '0.57rem',
    border: `solid 1px ${tokens.neutral100}`,
    borderRadius: '0.25rem',
    outline: 'none',
    ...body1,
    color: tokens.core1,
    padding: '0.875rem'
  },
  titleInputFocus: {
    border: `solid 1px ${tokens.purpleLight2}`
  },
  boxError: {
    ...captionOverline,
    backgroundColor: tokens.error15,
    color: tokens.error100,
    borderRadius: '4px',
    padding: '0.5rem',
    margin: '1.375rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      paddingLeft: '0.5rem'
    }
  }
}));

export const AppendixFileTable = ({ title, files, onChangeTitle, onAddFile, onDeleteFile }: AppendixFileTableProps) => {
  const classes = useStyles();
  const [focusInputTitle, setFocusInputTitle] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<CustomAppendixFile[]>([]);
  const [fileAlreadyExist, setFileAlreadyExist] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (files) {
      setFilesToUpload(files);
    }
  }, [files]);

  const existErrorUpload = useMemo(() => {
    return filesToUpload.filter((fileAppendix) => fileAppendix.state === AppendixFileState.FAILED).length > 0;
  }, [filesToUpload]);

  const onHandleFocusTitle = useCallback(() => {
    setFocusInputTitle(true);
  }, []);

  const onHandleBlurTitle = useCallback(() => {
    setFocusInputTitle(false);
  }, []);

  const emitTitle = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeTitle(e.target.value);
  };

  const onAddFileInfo = useCallback(
    (fileUpload: File, currentFiles: CustomAppendixFile[]) => {
      const existFile = currentFiles.find((existFile) => existFile.name === fileUpload.name);
      if (existFile) {
        setFileAlreadyExist(true);
        setTimeout(() => {
          setFileAlreadyExist(false);
        }, 3000);
        return;
      }

      const tempAppendixFile: CustomAppendixFile = {
        name: fileUpload.name,
        order: currentFiles.length + 1,
        uploaded: new Date().toISOString(),
        state: AppendixFileState.CREATED,
        link: ''
      };
      currentFiles.push(tempAppendixFile);
      onAddFile(tempAppendixFile, fileUpload);
    },
    [onAddFile]
  );

  const onChangeFiles = useCallback(
    (filesUpload: File | File[]) => {
      const currentFiles = [...filesToUpload];
      if (filesUpload instanceof File) {
        onAddFileInfo(filesUpload, currentFiles);
      } else {
        for (const file of filesUpload) {
          onAddFileInfo(file, currentFiles);
        }
      }

      setFilesToUpload(currentFiles);
    },
    [filesToUpload, onAddFileInfo]
  );

  const onHandleDeleteFile = useCallback(
    (fileToDelete: CustomAppendixFile) => {
      let currentFiles = [...filesToUpload];
      const indexToDelete = currentFiles.findIndex((file) => file.name === fileToDelete.name);
      if (indexToDelete >= 0) {
        currentFiles.splice(indexToDelete, 1);
      }

      currentFiles = currentFiles.map((file, index) => ({
        ...file,
        order: index + 1
      }));

      setFilesToUpload(currentFiles);
      onDeleteFile(fileToDelete);
    },
    [onDeleteFile, filesToUpload]
  );

  useEffect(() => {
    if (existErrorUpload) {
      setTimeout(() => {
        const errorUploadList = filesToUpload.filter((fileAppendix) => fileAppendix.state === AppendixFileState.FAILED);
        for (const fileAppendix of errorUploadList) {
          onHandleDeleteFile(fileAppendix);
        }
      }, 3000);
    }
  }, [existErrorUpload, filesToUpload, onHandleDeleteFile]);

  return (
    <Box className={classes.root}>
      <Box className={classes.sectionContainer}>
        <Typography className={classes.titleOptional}>
          {t('reports:configurationTab-Appendix-AppendixFileTable-AppendixName-title')}
        </Typography>
        <input
          autoFocus
          type="text"
          data-testid="input-title"
          value={title}
          className={`${classes.titleInput} ${focusInputTitle ? classes.titleInputFocus : ''}`}
          placeholder={t('reports:configurationTab-Appendix-AppendixFileTable-AppendixName-input-placeholder')}
          onChange={emitTitle}
          onBlur={onHandleBlurTitle}
          onFocus={onHandleFocusTitle}
        />
      </Box>

      {(existErrorUpload || fileAlreadyExist) && (
        <Box className={classes.boxError}>
          <ErrorUploadSvg />
          {existErrorUpload && (
            <span>{t('reports:configurationTab-Appendix-AppendixFileTable-Drag&DropBox-ErrorUpload')}</span>
          )}
          {fileAlreadyExist && (
            <span>{t('reports:configurationTab-Appendix-AppendixFileTable-Drag&DropBox-FileAlreadyExist')}</span>
          )}
        </Box>
      )}

      {filesToUpload.length > 0 && <FileList files={filesToUpload} emitDeleteFile={onHandleDeleteFile} />}

      <Box className={classes.sectionContainer}>
        <DragDropFileUpload multiple onChange={onChangeFiles} />
      </Box>
    </Box>
  );
};
