import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Table as MUITable, TableBody, TableCell, TableHead, TableRow, makeStyles, useTheme } from '@material-ui/core';
import { useOverflowShadows } from './hooks/useOverflowShadows';
import { TableProps, realisticObject } from './Table.proptype';
import tokens from '../../styles/designTokens';
import { getRandomId } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      left: 'initial',
      zIndex: 1
    },
    '& .MuiTableCell-root': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
      whiteSpace: 'nowrap',

      '&.MuiTableCell-head': {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightRegular,
        whiteSpace: 'normal'
      },

      '&.MuiTableCell-body': {
        height: 45,
        padding: theme.spacing(0, 1),

        '&:first-child': {
          fontWeight: theme.typography.fontWeightMedium
        },

        '& .MuiIconButton-root': {
          color: theme.palette.secondary.dark,
          padding: theme.spacing(0.5)
        }
      }
    },
    overflowX: 'auto'
  },
  wrappedStickyCell: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      '&.MuiTableCell-head': {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  },
  leftSticky: {
    '&.MuiTableCell-root': {
      borderRight: '1px solid ' + tokens.neutral90,
      left: 0,
      position: 'sticky',
      '&.MuiTableCell-head': {
        paddingLeft: 0,
        paddingRight: 0,
        zIndex: 2
      },
      '&.MuiTableCell-body': {
        padding: 0
      }
    }
  },
  rightSticky: {
    '&.MuiTableCell-root': {
      borderLeft: '1px solid ' + tokens.neutral90,
      position: 'sticky',
      right: 0,
      '&.MuiTableCell-head': {
        paddingLeft: 0,
        paddingRight: 0,
        zIndex: 2
      },
      '&.MuiTableCell-body': {
        padding: 0
      }
    }
  },
  leftStickyShadow: {
    '&::after': {
      background: 'linear-gradient(270deg, rgba(0,0,0,0) 0%, #000000 100%)',
      borderLeft: '1px solid ' + tokens.neutral90,
      content: '""',
      height: '100%',
      opacity: '8%',
      position: 'absolute',
      right: '-8px',
      top: 0,
      width: '8px'
    }
  },
  rightStickyShadow: {
    '&::after': {
      background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, #000000 100%)',
      borderRight: '1px solid ' + tokens.neutral90,
      content: '""',
      height: '100%',
      left: '-8px',
      opacity: '8%',
      position: 'absolute',
      top: 0,
      width: '8px'
    }
  },
  toDashboard: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

export const Table = ({
  data,
  columns,
  selectedIndices,
  className,
  stickyCols,
  columnSpecificStyles,
  onRowClick,
  performTaskToDataAtTheBeginning
}: TableProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [listItems, setListItems] = useState<any[]>([]);
  const [stickyLeftCellId] = useState(getRandomId());
  const [stickyRightCellId] = useState(getRandomId());
  const [bodyStickyRightCellClassName] = useState(getRandomId());
  const [bodyStickyLeftCellClassName] = useState(getRandomId());
  const [movingCellsClassName] = useState(getRandomId());

  const { tableWrapperRef, stickyLeftCellRef, stickyRightCellRef } = useOverflowShadows({
    movingCellsClassName,
    bodyStickyLeftCellClassName,
    bodyStickyRightCellClassName,
    leftStickyShadowClassName: classes.leftStickyShadow,
    rightStickyShadowClassName: classes.rightStickyShadow,
    columns,
    data
  });

  /**
   * Returns the cells aggregated with their location and stickiness attributes. If row data is not passed, it assumes cells are
   * header cells
   */
  const renderCells = useCallback(
    (row?: any) => {
      const locations = {
        left: [] as ReactNode[],
        middle: [] as ReactNode[],
        right: [] as ReactNode[]
      };

      const columnSpecificStylesFromProps: realisticObject = columnSpecificStyles ?? {};

      columns.forEach(({ key, header, align, width }, i) => {
        const cellStyles = columnSpecificStylesFromProps[key] ?? {};

        if (!stickyCols?.[key]) {
          locations.middle.push(
            <TableCell key={key} align={align} className={row ? undefined : movingCellsClassName} style={cellStyles}>
              {row ? row[key] : header}
            </TableCell>
          );
          return;
        }

        locations[stickyCols[key].side][stickyCols[key].position] = (
          <TableCell
            // eslint-disable-next-line react/no-array-index-key
            key={String(i)}
            style={{ minWidth: width, width, ...cellStyles }}
            component="div"
            align={align}
            className={classes.wrappedStickyCell + ` ${stickyCols[key].side}`}
          >
            {row ? row[key] : header}
          </TableCell>
        );
      });

      return [
        locations.left.length > 0 ? (
          <TableCell
            key="leftCell"
            ref={row ? undefined : stickyLeftCellRef}
            id={row ? undefined : stickyLeftCellId}
            className={classes.leftSticky + ' ' + bodyStickyLeftCellClassName}
          >
            {locations.left}
          </TableCell>
        ) : null,
        locations.middle,
        locations.right.length > 0 ? (
          <TableCell
            key="rightCell"
            ref={row ? undefined : stickyRightCellRef}
            id={row ? undefined : stickyRightCellId}
            className={classes.rightSticky + ' ' + bodyStickyRightCellClassName}
          >
            {locations.right}
          </TableCell>
        ) : null
      ];
    },
    [
      columns,
      bodyStickyLeftCellClassName,
      bodyStickyRightCellClassName,
      classes.leftSticky,
      classes.rightSticky,
      classes.wrappedStickyCell,
      movingCellsClassName,
      stickyCols,
      stickyLeftCellId,
      stickyLeftCellRef,
      stickyRightCellId,
      stickyRightCellRef,
      columnSpecificStyles
    ]
  );

  const renderColgroup = useMemo(() => {
    const cols: any[] = [];
    const leftWidth: any[] = [];
    const rightWidth: any[] = [];

    columns.forEach(({ key, width }, i) => {
      if (stickyCols?.[key]) {
        if (stickyCols?.[key].side === 'left') {
          leftWidth.push(width);
        } else {
          rightWidth.push(width);
        }
      } else {
        // eslint-disable-next-line react/no-array-index-key
        cols.push(<col key={String(i)} style={width ? { minWidth: width, width } : {}} />);
      }
    });

    if (leftWidth.length > 0) {
      const calculated = `calc(${leftWidth.join('+')})`;
      cols.unshift(<col key="colgroupLeft" style={{ minWidth: calculated, width: calculated }} />);
    }

    if (rightWidth.length > 0) {
      const calculated = `calc(${rightWidth.join('+')})`;
      cols.push(<col key="colgroupRight" style={{ minWidth: calculated, width: calculated }} />);
    }

    return <colgroup>{cols}</colgroup>;
  }, [columns, stickyCols]);

  const isHeader = useMemo(() => {
    const headerCell = columns.some((col) => col.header && col.header !== '');
    return headerCell;
  }, [columns]);

  useEffect(() => {
    setListItems(performTaskToDataAtTheBeginning ? performTaskToDataAtTheBeginning(data) : data);
  }, [data, performTaskToDataAtTheBeginning]);

  return (
    <div ref={tableWrapperRef}>
      <MUITable stickyHeader className={`${classes.root} ${className ?? ''}`}>
        {renderColgroup}

        {isHeader && (
          <TableHead>
            <TableRow>{renderCells()}</TableRow>
          </TableHead>
        )}

        <TableBody>
          {listItems.map((row, i) => (
            <TableRow
              // eslint-disable-next-line react/no-array-index-key
              key={String(i)}
              className={row.className}
              selected={selectedIndices?.includes(i)}
              onClick={(event) => {
                onRowClick?.(event, i, row);
              }}
            >
              {renderCells(row)}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </div>
  );
};
