import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchBaseData, getWorkingContainer } from './thunks/baseData';
import { Container, Country, Currency, Language, NormalizedStateSlice, PrimaryFunction } from '../../models';

interface BaseDataState {
  containers: NormalizedStateSlice<Container>;
  countries: NormalizedStateSlice<Country>;
  currencies: NormalizedStateSlice<Currency>;
  primaryFunctions: NormalizedStateSlice<PrimaryFunction>;
  languages: NormalizedStateSlice<Language>;
  workingContainerId?: number;
  upeId?: number;
  upeCurrencyId?: number;
  error?: string;
  isBaseDataFetch: boolean;
}

const initialState: BaseDataState = {
  containers: { byId: null, allIds: [] },
  countries: { byId: null, allIds: [] },
  currencies: { byId: null, allIds: [] },
  primaryFunctions: { byId: null, allIds: [] },
  languages: { byId: null, allIds: [] },
  isBaseDataFetch: false
};

const storeInSlice = <T extends Record<string, any>>(slice: NormalizedStateSlice<T>, data: T[], idProp: string) => {
  slice.byId = {};
  slice.allIds = [];
  for (const datum of data ?? []) {
    slice.byId[datum[idProp]] = datum;
    slice.allIds.push(datum[idProp]);
  }
};

const baseDataSlice = createSlice({
  name: 'baseData',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        fetchBaseData.fulfilled,
        (state, { payload: { containers, countries, currencies, primaryFunctions, upe, languages } }) => {
          storeInSlice(state.containers, containers, 'containerId');
          storeInSlice(state.countries, countries, 'countryId');
          storeInSlice(state.currencies, currencies, 'currencyId');
          storeInSlice(state.primaryFunctions, primaryFunctions, 'primaryFunctionId');
          storeInSlice(state.languages, languages, 'languageId');
          state.workingContainerId = upe.container?.containerId;
          state.upeId = upe.entityId;
          state.upeCurrencyId = upe.upeCurrency?.currencyId;
          state.isBaseDataFetch = true;
        }
      )
      .addCase(getWorkingContainer.fulfilled, (state, { payload: upe }) => {
        state.workingContainerId = upe.container?.containerId;
        state.upeId = upe.entityId;
        state.upeCurrencyId = upe.upeCurrency?.currencyId;
      })
      .addMatcher(
        // do not import { saveEntity } from '../entities' to avoid creating a circular dependency
        (action) => action.type === 'entities/save/fulfilled',
        (state, { payload }) => {
          if (payload.upe && !state.upeId) {
            state.upeId = payload.entityId;
            state.upeCurrencyId = payload.upeCurrency?.currencyId;
          }
        }
      )
      .addMatcher(
        (action) => action.type.match(/^baseData\/.+\/pending$/),
        (state) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^baseData\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default baseDataSlice.reducer;
