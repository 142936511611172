import { useDispatch } from 'react-redux';
import { ConnectorControlledTransactionsProps } from './ControlledTransaction.proptype';
import { actions } from '../../../../../redux/localReports';
import { ControlledTransactionsDocumentationConfig } from '../../../../../redux/localReports/localReports.proptype';

const Connector = ({
  component: Component,
  transactions,
  hasCustomization,
  controlledTransactionsConfigState
}: ConnectorControlledTransactionsProps) => {
  const dispatch = useDispatch();

  const dispatchNewControlledTransactionsDocumentationConfig = (
    newControlledTransactionSelection: ControlledTransactionsDocumentationConfig
  ) => {
    dispatch(actions.setControlledTransactionsDocumentationConfig(newControlledTransactionSelection));
  };

  return (
    <Component
      transactions={transactions}
      controlledTransactionSelection={controlledTransactionsConfigState}
      emitNewControlledTransactionSelection={dispatchNewControlledTransactionsDocumentationConfig}
      hasCustomization={hasCustomization}
    />
  );
};

export default Connector;
