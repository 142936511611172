import { useDispatch } from 'react-redux';
import { ConnectorProfitBasedAnalysisProps } from './ProfitBasedAnalysis.proptype';
import { actions } from '../../../../../redux/localReports';
import { ProfitBasedAnalysisConfig } from '../../../../../redux/localReports/localReports.proptype';

const Connector = ({
  component: Component,
  pbas,
  profitBasedAnalysisConfigState,
  hasCustomization
}: ConnectorProfitBasedAnalysisProps) => {
  const dispatch = useDispatch();

  const dispatchNewProfitBasedAnalysisConfig = (newProfitBasedAnalysisSelection: ProfitBasedAnalysisConfig) => {
    dispatch(actions.setProfitBasedAnalysisConfig(newProfitBasedAnalysisSelection));
  };

  return (
    <Component
      pbas={pbas}
      profitBasedAnalysiSelection={profitBasedAnalysisConfigState}
      emitNewProfitBasedAnalysiselection={dispatchNewProfitBasedAnalysisConfig}
      hasCustomization={hasCustomization}
    />
  );
};

export default Connector;
