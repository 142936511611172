import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { PDFiconSvg } from './DragDropFileUploadIcons';
import { useDraggable } from './useDraggable';
import tokens from '../../styles/designTokens';
import { body3, buttonText2, captionOverline } from '../../styles/typography';

const useStyles = makeStyles(() => ({
  uploadFileBox: {
    position: 'relative',
    width: '100%',
    height: '6rem',
    border: 'Dashed 1px',
    borderColor: tokens.neutral100,
    marginTop: '1.5rem',
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  uploadFileBoxDragHover: {
    borderColor: tokens.purpleLight2
  },
  uploadFileDraggableWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: tokens.product15,
    opacity: '80%'
  },
  pdfIconContainer: {
    margin: '0.75rem 1.625rem',
    display: 'flex',
    alignItems: 'center'
  },
  pdfIcon: {
    width: '4.5rem',
    height: '4.5rem',
    borderRadius: '50%',
    backgroundColor: tokens.product15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0.8rem'
  },
  pdfDescription: {
    ...body3,
    color: tokens.product100
  },
  pdfSubDescription: {
    ...captionOverline,
    color: tokens.core2
  },
  browseBtn: {
    ...buttonText2,
    border: 'solid 1px',
    borderRadius: '0.25rem',
    color: tokens.product100,
    borderColor: tokens.product100,
    background: 'transparent',
    height: '2.25rem',
    padding: '0.5rem 1rem',
    margin: '2rem',
    cursor: 'pointer'
  },
  inputFile: {
    display: 'none'
  }
}));

interface DragDropFileUploadProps {
  multiple: boolean;
  onChange?: (arg0: File | File[]) => void;
}

export const DragDropFileUpload = ({ multiple, onChange }: DragDropFileUploadProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [, setFile] = useState<File[] | File | null>(null);
  const areaRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onHandleFiles = useCallback(
    (files: File | File[]) => {
      onChange?.(files);
      setFile(files);
    },
    [onChange]
  );

  const onHandleInputChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const allFiles = ev.target.files;
      if (allFiles) {
        const files = multiple ? [...allFiles] : allFiles[0];
        onHandleFiles(files);
      }
    },
    [onHandleFiles, multiple]
  );

  const onHandleSearchFiles = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const draggable = useDraggable({
    areaRef,
    multiple,
    handleChanges: onHandleFiles
  });

  return (
    <div ref={areaRef} className={`${classes.uploadFileBox} ${draggable ? classes.uploadFileBoxDragHover : ''}`}>
      {draggable && <div className={classes.uploadFileDraggableWrapper} />}
      <Box className={classes.pdfIconContainer}>
        <Box className={classes.pdfIcon}>
          <PDFiconSvg />
        </Box>
        <Box>
          <Typography className={classes.pdfDescription}>
            {t('reports:configurationTab-Appendix-AppendixFileTable-Drag&DropBox-Description')}
          </Typography>
          <Typography className={classes.pdfSubDescription}>
            {t('reports:configurationTab-Appendix-AppendixFileTable-Drag&DropBox-subDescription')}
          </Typography>
        </Box>
      </Box>
      <input
        ref={inputRef}
        className={classes.inputFile}
        accept="application/pdf"
        type="file"
        onChange={onHandleInputChange}
      />
      <button className={classes.browseBtn} type="button" onClick={onHandleSearchFiles}>
        {t('reports:configurationTab-Appendix-AppendixFileTable-Drag&DropBox-button')}
      </button>
    </div>
  );
};
