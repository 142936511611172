import { ReportSectionTemplateOptions } from '@xbs/xbs-enums';
import { Container } from '../../../../models';
import { ReportSummaryCoreSection } from '../../../../models/reports.interface';
import { get } from '../../../../services/localStorage/service';

const defaultConfig: Array<ReportSummaryCoreSection & { order: { default: number; optionOne: number } }> = [
  {
    code: ReportSectionTemplateOptions.ByName.Cover.Name,
    name: ReportSectionTemplateOptions.ByName.Cover.Localization,
    order: { default: 1, optionOne: 1 },
    active: true
  },
  {
    code: ReportSectionTemplateOptions.ByName.ComplianceOverview.Name,
    name: ReportSectionTemplateOptions.ByName.ComplianceOverview.Localization,
    order: { default: 2, optionOne: 2 },
    active: true
  },
  {
    code: ReportSectionTemplateOptions.ByName.LocalDocumentationIndex.Name,
    name: ReportSectionTemplateOptions.ByName.LocalDocumentationIndex.Localization,
    order: { default: 3, optionOne: 3 },
    active: true
  },
  {
    code: ReportSectionTemplateOptions.ByName.ExecutiveSummary.Name,
    name: ReportSectionTemplateOptions.ByName.ExecutiveSummary.Localization,
    order: { default: 4, optionOne: 4 },
    active: true
  },
  {
    code: ReportSectionTemplateOptions.ByName.LegalEntityOverview.Name,
    name: ReportSectionTemplateOptions.ByName.LegalEntityOverview.Localization,
    order: { default: 5, optionOne: 5 },
    active: true
  },
  {
    code: ReportSectionTemplateOptions.ByName.ControlledTransactionOverview.Name,
    name: ReportSectionTemplateOptions.ByName.ControlledTransactionOverview.Localization,
    order: { default: 6, optionOne: 6 },
    active: true
  },
  {
    code: ReportSectionTemplateOptions.ByName.MethodEvaluation.Name,
    name: ReportSectionTemplateOptions.ByName.MethodEvaluation.Localization,
    order: { default: 7, optionOne: 9 },
    active: true
  },
  {
    code: ReportSectionTemplateOptions.ByName.ProfitBasedAnalysis.Name,
    name: ReportSectionTemplateOptions.ByName.ProfitBasedAnalysis.Localization,
    order: { default: 8, optionOne: 7 },
    active: true
  },
  {
    code: ReportSectionTemplateOptions.ByName.TransactionBasedAnalysis.Name,
    name: ReportSectionTemplateOptions.ByName.TransactionBasedAnalysis.Localization,
    order: { default: 9, optionOne: 8 },
    active: true
  }
];

const deleteKeyFromDefaultConfig = (arr: typeof defaultConfig) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return arr.map(({ order, ...props }) => props);
};

const LOCAL_STORAGE_KEY = 'workingContainer';
const REPORT_CUT = 2019;
export const getDefaultReportSectionsOptionsConfig = () => {
  const { taxYear } = JSON.parse(get(LOCAL_STORAGE_KEY)!) as Container;

  if (taxYear < REPORT_CUT) {
    const sorted = defaultConfig.sort((a, b) => a.order.optionOne - b.order.optionOne);
    return deleteKeyFromDefaultConfig(sorted);
  }

  return deleteKeyFromDefaultConfig(defaultConfig);
};

export const confirmReportSectionsOptionsConfigOrder = (currConfig: ReportSummaryCoreSection[]) => {
  const defaultConfig = getDefaultReportSectionsOptionsConfig();
  return defaultConfig.map((defaultSection) => {
    const currConfigSectionIdx = currConfig.findIndex((section) => section.code === defaultSection.code);
    return { ...defaultSection, active: currConfig[currConfigSectionIdx].active };
  });
};
