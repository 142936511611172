import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Chip, Box, Checkbox } from '@mui/material';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReportSectionAreaProps } from './ReportSectionArea.proptype';
import { CustomSwitch } from '../../../../../../components';
import { useWorkingContainer } from '../../../../../../hooks/container/useWorkingContainer';
import { useCustomAppendices } from '../../../../../../hooks/custom-appendices/useCustomAppendices';
import { selectWorkingContainer } from '../../../../../../selectors';
import tokens from '../../../../../../styles/designTokens';
import { body2, captionOverline } from '../../../../../../styles/typography';
import { hasAccess } from '../../../../../../utils';
import { logGoogleAnalyticsEvent } from '../../../../../../utils/sendGoogleAnalyticsEvent';
import { Table } from '../../../../../Table';
import { TableColumnDef } from '../../../../../Table/Table.proptype';
import { ActionTable } from '../../../components/ActionTable/ActionTable';
import { confirmReportSectionsOptionsConfigOrder } from '../../../utils/getDefaultReportSectionsOptionsConfig';

const useStyles = makeStyles(() => ({
  actionTableMargin: {
    margin: '1.5rem 0'
  },
  actionTableError: {
    borderWidth: '4px 0',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderColor: tokens.error100
  },
  table: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  columnHeader: {
    ...captionOverline,
    '& .MuiChip-root': {
      borderRadius: '4px',
      height: '1.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#FEF7E0'
    },
    '& .MuiChip-root.Mui-disabled': {
      opacity: 1,
      color: tokens.error100,
      backgroundColor: tokens.error15
    }
  },
  sectionRow: {
    '& .MuiTableCell-root': {
      ...body2,
      '&.MuiTableCell-body': {
        '& .MuiIconButton-root': {
          color: tokens.white,
          padding: 0
        }
      }
    }
  },
  sectionRowDisabled: {
    '& .MuiTableCell-root': {
      ...body2,
      background: tokens.neutral70,
      color: tokens.core1,
      '&.MuiTableCell-body': {
        '& .MuiIconButton-root': {
          color: tokens.white,
          padding: 0
        },
        '& .MuiSwitch-track': {
          background: '#F4CD52'
        }
      }
    }
  },
  sectionNameRow: {
    paddingLeft: '2rem'
  },
  sectionNameRowDisabled: {
    paddingLeft: '2rem',
    opacity: 0.5
  },
  reportTable: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));

enum ActionTableCode {
  Title = 'title',
  Subtitle = 'subtitle',
  SubtitleError = 'subtitleError'
}

export const ReportSectionArea = ({
  jurisdictionName,
  isOpen,
  setIsOpen,
  coreSections,
  emitToggleCoreSection,
  emitToggleAppendixSection
}: ReportSectionAreaProps) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const classes = useStyles();
  const workingContainer = useSelector(selectWorkingContainer);

  const appendixInfo = useCustomAppendices();
  const container = useWorkingContainer();
  const hasAccessToAppendixSection = hasAccess(flags.tpiqReportGeneratorCustomAppendices, container?.containerId);
  const hasAccessToReportSectionsConfig = hasAccess(
    flags.tpiqReportsUiConfigTabSummaryPanelSectionsContainerProgressive,
    container?.containerId
  );

  const countSelectedSections = useMemo(() => {
    return coreSections.filter((coreSection) => coreSection.active).length;
  }, [coreSections]);

  const countSelectedAppendix = useMemo(() => {
    if (!appendixInfo) return 0;

    return appendixInfo.filter((appendix) => appendix.display).length;
  }, [appendixInfo]);

  const ReportSectionsTableColumns: TableColumnDef[] = [
    {
      key: 'section',
      header: (
        <Box className={classes.columnHeader}>
          <span>
            {t('reports:column-local-report-creation-modal-configuration-report-summary-reports-section-core')}
          </span>
          <Chip disabled={countSelectedSections === 0} label={`${countSelectedSections}/${coreSections.length}`} />
        </Box>
      ),
      width: '75%'
    },
    { key: 'checkbox', header: '', width: '15%' },
    { key: 'action', header: '', width: '10%' }
  ];

  const AppendixSectionsTableColumns: TableColumnDef[] = [
    {
      key: 'section',
      header: (
        <Box className={classes.columnHeader}>
          <span>
            {t('reports:column-local-report-creation-modal-configuration-report-summary-reports-section-appendix')}
          </span>
          <Chip label={`${countSelectedAppendix}/${appendixInfo.length}`} />
        </Box>
      ),
      width: '75%'
    },
    { key: 'toggle', header: '', width: '15%' },
    { key: 'action', header: '', width: '10%' }
  ];

  const handleToggleCoreSection = useCallback(
    (code: string) => {
      emitToggleCoreSection(code);
    },
    [emitToggleCoreSection]
  );

  const handleToggleAppendixSection = useCallback(
    (title: string) => {
      emitToggleAppendixSection(title);
    },
    [emitToggleAppendixSection]
  );

  const reportSections = useMemo(() => {
    let sections = coreSections;

    if (sections.length > 0) {
      sections = confirmReportSectionsOptionsConfigOrder(coreSections);
    }

    return sections.map((sectionInfo, idx) => {
      return {
        section: (
          <span className={sectionInfo.active ? classes.sectionNameRow : classes.sectionNameRowDisabled}>
            {t(`reports:${sectionInfo.name}`)}
          </span>
        ),
        checkbox: (
          <Checkbox
            sx={{ color: tokens.neutral100, '&.Mui-checked': { color: tokens.product100 } }}
            data-testid={`section-option-item-${idx + 1}`}
            checked={sectionInfo.active}
            onChange={() => {
              /* eslint-disable camelcase */
              void logGoogleAnalyticsEvent({
                event_category: `${sectionInfo.name.replace('.', '_')}_click`,
                event_label: `Report configuration report summary report sections click`,
                container_id: workingContainer?.containerId
              });
              handleToggleCoreSection(sectionInfo.code);
            }}
          />
        ),
        className: sectionInfo.active ? classes.sectionRow : classes.sectionRowDisabled
      };
    });
  }, [
    classes.sectionNameRow,
    classes.sectionNameRowDisabled,
    classes.sectionRow,
    classes.sectionRowDisabled,
    coreSections,
    handleToggleCoreSection,
    t,
    workingContainer?.containerId
  ]);

  const appendixSections = useMemo(() => {
    const sections = [...appendixInfo].sort((first, second) => first.order - second.order);
    return sections.map((sectionInfo) => {
      const sectionInfoNumber = `Appendix ${sectionInfo.order}`;
      return {
        section: (
          <span className={sectionInfo.display ? classes.sectionNameRow : classes.sectionNameRowDisabled}>
            {sectionInfo.title.length > 0 ? sectionInfo.title : sectionInfoNumber}
          </span>
        ),
        toggle: (
          <CustomSwitch
            checked={sectionInfo.display}
            onChange={() => {
              void logGoogleAnalyticsEvent({
                event_category: `report_appendix_section_${(sectionInfo.title.length > 0
                  ? sectionInfo.title
                  : sectionInfoNumber
                ).replace(' ', '_')}_click`,
                event_label: `Report configuration report summary appendix sections click`,
                container_id: workingContainer?.containerId
              });
              handleToggleAppendixSection(sectionInfo.title);
            }}
          />
        ),
        className: sectionInfo.display ? classes.sectionRow : classes.sectionRowDisabled
      };
    });
  }, [
    appendixInfo,
    classes.sectionNameRow,
    classes.sectionNameRowDisabled,
    classes.sectionRow,
    classes.sectionRowDisabled,
    handleToggleAppendixSection,
    workingContainer?.containerId
  ]);

  const actionTableMessages: { [key in ActionTableCode]: string } = {
    [ActionTableCode.Title]: t(
      'reports:title-local-report-creation-modal-configuration-report-summary-reports-section'
    ),
    [ActionTableCode.Subtitle]: t(
      'reports:title-local-report-creation-modal-configuration-report-summary-reports-section-jurisdiction',
      { jurisdiction: jurisdictionName }
    ),
    [ActionTableCode.SubtitleError]: t('errors:add-at-least-one-section')
  };

  let actionTableTitle: string | ReactNode = actionTableMessages.title;
  let actionTableSubtitle: string | ReactNode = actionTableMessages.subtitle;

  if (countSelectedSections === 0) {
    actionTableTitle = (
      <Box component="span" color={tokens.error100}>
        {actionTableMessages.title}*
      </Box>
    );
    actionTableSubtitle = (
      <Box component="span" color={tokens.error100}>
        {actionTableMessages.subtitleError}
      </Box>
    );
  }

  return (
    <Box className={clsx(classes.actionTableMargin, countSelectedSections === 0 && classes.actionTableError)}>
      <ActionTable
        id="report-summary-accordion-1"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={actionTableTitle}
        subtitle={actionTableSubtitle}
      >
        <>
          {hasAccessToReportSectionsConfig && (
            <Box className={classes.table}>
              <Table columns={ReportSectionsTableColumns} data={reportSections} />
            </Box>
          )}
          {hasAccessToAppendixSection && (
            <Box className={classes.table}>
              <Table columns={AppendixSectionsTableColumns} data={appendixSections} />
            </Box>
          )}
        </>
      </ActionTable>
    </Box>
  );
};
