import { useDispatch } from 'react-redux';
import { ConnectorLegalEntitiesProps } from './LegalEntities.proptype';
import { actions } from '../../../../../redux/localReports';

const Connector = ({
  component: Component,
  upe,
  primaryEntities,
  tradingPartners,
  hasCustomization,
  entityStatementFactsConfigState
}: ConnectorLegalEntitiesProps) => {
  const dispatch = useDispatch();

  const dispatchLegalEntitiesSelection = (LegalEntitiesSelection: any) => {
    dispatch(actions.setConfigurationTabEntityStatementOfFactsConfig(LegalEntitiesSelection));
  };

  return (
    <Component
      upe={upe}
      emitLegalEntitiesSelection={dispatchLegalEntitiesSelection}
      selectedLegalEntities={entityStatementFactsConfigState}
      selectedTradingPartners={tradingPartners}
      selectedEntities={primaryEntities}
      hasCustomization={hasCustomization}
    />
  );
};

export default Connector;
