import { useCallback, useEffect, useRef } from 'react';
import { useResizedDetection } from './useResizedDetection';
import { TableColumnDef } from '../../Table/Table.proptype';

interface useOverflowShadowsProps {
  movingCellsClassName: string;
  bodyStickyLeftCellClassName: string;
  bodyStickyRightCellClassName: string;
  leftStickyShadowClassName: string;
  rightStickyShadowClassName: string;
  columns: TableColumnDef[];
  data: any[];
}

export const useOverflowShadows = ({
  movingCellsClassName,
  bodyStickyLeftCellClassName,
  bodyStickyRightCellClassName,
  leftStickyShadowClassName,
  rightStickyShadowClassName,
  columns,
  data
}: useOverflowShadowsProps) => {
  const { wrapperRef: tableWrapperRef, resizeEvent } = useResizedDetection();
  const stickyLeftCellRef = useRef<HTMLTableCellElement>(null);
  const stickyRightCellRef = useRef<HTMLTableCellElement>(null);
  const alreadyResizedFirstTime = useRef(false); // useResizeDetection will trigger a first resize that we want to ignore

  const showHideShadows = useCallback(
    (cell: HTMLElement, side: 'left' | 'right', shouldShow: boolean) => {
      if (shouldShow) {
        cell.classList.add(side === 'left' ? leftStickyShadowClassName : rightStickyShadowClassName);
      } else {
        cell.classList.remove(side === 'left' ? leftStickyShadowClassName : rightStickyShadowClassName);
      }
    },
    [leftStickyShadowClassName, rightStickyShadowClassName]
  );

  useEffect(() => {
    const stickyLeftCell = stickyLeftCellRef.current;
    const stickyRightCell = stickyRightCellRef.current;

    const middleSection = document.querySelectorAll(
      `.${movingCellsClassName}`
    ) as unknown as HTMLCollectionOf<HTMLElement>;
    const movingLeftCell = middleSection[0];
    const movingRightCell = middleSection[middleSection.length - 1];
    const allStickyRight = document.querySelectorAll(
      `.${bodyStickyRightCellClassName}`
    ) as unknown as HTMLCollectionOf<HTMLElement>;
    const allStickyLeft = document.querySelectorAll(
      `.${bodyStickyLeftCellClassName}`
    ) as unknown as HTMLCollectionOf<HTMLElement>;

    if (!stickyLeftCell && !stickyRightCell) {
      return;
    }

    const handleInterception = (entries: IntersectionObserverEntry[]) => {
      if (!alreadyResizedFirstTime.current) {
        alreadyResizedFirstTime.current = true;
        return;
      }

      entries.forEach((entry) => {
        if (entry.target === movingLeftCell) {
          for (const cell of allStickyLeft) {
            showHideShadows(cell, 'left', !entry.isIntersecting);
          }
        } else {
          for (const cell of allStickyRight) {
            showHideShadows(cell, 'right', !entry.isIntersecting);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleInterception, {
      root: null,
      rootMargin: `0px ${
        stickyRightCell ? `-${Math.abs(window.innerWidth - stickyRightCell.getBoundingClientRect().left - 2)}px` : '0px'
      } 0px ${stickyLeftCell ? `-${Math.abs(stickyLeftCell.getBoundingClientRect().right - 2)}px` : '0px'}`,
      threshold: 1
    });

    if (movingLeftCell) {
      observer.observe(movingLeftCell);
    }

    if (movingRightCell) {
      observer.observe(movingRightCell);
    }

    return () => {
      if (movingLeftCell) {
        observer.unobserve(movingLeftCell);
      }

      if (movingRightCell) {
        observer.unobserve(movingRightCell);
      }
    };
  }, [
    resizeEvent,
    columns,
    data,
    movingCellsClassName,
    bodyStickyLeftCellClassName,
    bodyStickyRightCellClassName,
    leftStickyShadowClassName,
    rightStickyShadowClassName,
    showHideShadows
  ]);

  return { tableWrapperRef, stickyLeftCellRef, stickyRightCellRef };
};
