export const MenuIcon = () => (
  <svg version="1.1" id="Capa_1" x="0px" y="0px" width="17px" height="17px" viewBox="0 0 436.873 436.873">
    <g>
      <g>
        <g>
          <path
            d="M65.96,284.391C29.595,284.391,0,254.805,0,218.428c0-36.362,29.595-65.945,65.96-65.945s65.96,29.583,65.96,65.945
				C131.914,254.805,102.325,284.391,65.96,284.391z"
          />
        </g>
        <g>
          <path
            d="M214.443,284.391c-36.365,0-65.947-29.586-65.947-65.963c0-36.362,29.583-65.945,65.947-65.945
				c36.365,0,65.957,29.583,65.957,65.945C280.4,254.805,250.809,284.391,214.443,284.391z"
          />
        </g>
        <g>
          <path
            d="M370.929,284.391c-36.365,0-65.957-29.586-65.957-65.963c0-36.362,29.592-65.945,65.957-65.945
				s65.944,29.583,65.944,65.945C436.873,254.805,407.294,284.391,370.929,284.391z"
          />
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
