import { useDispatch } from 'react-redux';
import { ConnectorProps } from './EntitiesTab.proptype';
import { usePrimaryEntitiesList } from '../../../hooks/primary-entities/usePrimaryEntitiesList';
import { useSelectedPrimaryEntities } from '../../../hooks/primary-entities/useSelectedPrimaryEntities';
import { useSelectedTradingPartners } from '../../../hooks/trading-partners/useSelectedTradingPartners';
import { useTradingPartnersList } from '../../../hooks/trading-partners/useTradingPartnersList';
import { actions as localReportsActions } from '../../../redux/localReports';
import { PrimaryEntityItem, TradingPartnerItem } from '../../../redux/localReports/localReports.proptype';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const primaryEntitiesList = usePrimaryEntitiesList();
  const selectedPrimaryEntities = useSelectedPrimaryEntities();
  const tradingPartnersList = useTradingPartnersList();
  const selectedTradingPartners = useSelectedTradingPartners();

  const onPrimaryEntitiesSelectionChange = (selectedIds: number[]) => {
    dispatch(localReportsActions.setSelectedPrimaryEntities(selectedIds));
  };

  const onTradingPartnersSelectionChange = (selectedIds: number[]) => {
    dispatch(localReportsActions.setSelectedTradingPartners(selectedIds));
  };

  return (
    <Component
      primaryEntities={(primaryEntitiesList ?? []).sort((a: PrimaryEntityItem, b: PrimaryEntityItem) =>
        a.code < b.code ? -1 : 1
      )}
      selectedPrimaries={selectedPrimaryEntities.map((entity) => entity.entityId)}
      tradingPartners={(tradingPartnersList ?? []).sort((a: TradingPartnerItem, b: TradingPartnerItem) =>
        a.code < b.code ? -1 : 1
      )}
      selectedPartners={selectedTradingPartners.map((entity) => entity.entityId)}
      onPrimaryEntitiesSelectionChange={onPrimaryEntitiesSelectionChange}
      onTradingPartnersSelectionChange={onTradingPartnersSelectionChange}
    />
  );
};

export default Connector;
