/** eslint-disable   @typescript-eslint/ban-types */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MenuIcon } from './MenuIcon';
import { MenuDotsProps, Option } from '../../ConfigurationTab.proptype';

const ITEM_HEIGHT = 48;
type Action = () => void | any;

export const MenuDots = ({ options, icon: Icon }: MenuDotsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (fn?: Action) => {
    fn?.();
    handleClose();
  };

  const IconMenu = () => {
    return Icon ? <Icon /> : <MenuIcon />;
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <IconMenu />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
            boxShadow: '0 0 11px 0 rgba(0,0,0,0.12)'
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleClose}
      >
        {options?.map((option: Option) => (
          <MenuItem
            key={option.description}
            onClick={() => {
              handleAction(option.action);
            }}
          >
            {option.description}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
