import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ConnectorProps } from './SetupTab.proptype';
import { useCountryById } from '../../../hooks/country/useCountryById';
import { useLanguages } from '../../../hooks/languages/useLanguages';
import { useSetupData } from '../../../hooks/setup-data/useSetupData';
import { useTemplateList } from '../../../hooks/template-report/useTemplateList';
import { useUPECurrency } from '../../../hooks/upe/useUPECurrency';
import { Currency } from '../../../models';
import { actions as localReportsActions } from '../../../redux/localReports';
import { SetupData } from '../../../redux/localReports/localReports.proptype';

const Connector = ({ jurisdictionId, component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const country = useCountryById(jurisdictionId);
  const mneCurrency = useUPECurrency();
  const jurisdictionCurrency = country?.defaultCurrency;
  const templates = useTemplateList();
  const languages = useLanguages();
  const setupData = useSetupData();

  const onHandlerComplete = useCallback(
    (info: SetupData) => {
      dispatch(localReportsActions.setSetup({ ...info, disableTitle: setupData.disableTitle }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const currencyList: Currency[] = useMemo(() => {
    const currencyData: Currency[] = [];

    if (mneCurrency) {
      currencyData.push(mneCurrency);
    }

    if (jurisdictionCurrency) {
      currencyData.push(jurisdictionCurrency);
    }

    currencyData.sort((curr1, curr2) => {
      return curr1.name > curr2.name ? 1 : -1;
    });

    return currencyData;
  }, [mneCurrency, jurisdictionCurrency]);

  const languageList = useMemo(() => {
    return languages
      .filter((lang) => {
        return lang.isoCode !== 'reapply_call';
      })
      .map((lang) => ({
        ...lang,
        displayName: t(`languageIsoInfo:${lang.isoCode}`)
      }))
      .sort((lang1, lang2) => {
        return lang1.displayName > lang2.displayName ? 1 : -1;
      });
  }, [languages, t]);

  const getDefaultCurrency = (): string => {
    return setupData.currency
      ? setupData.currency
      : mneCurrency?.currencyId?.toString() ?? jurisdictionCurrency?.currencyId?.toString() ?? '';
  };

  const getDefaultTemplate = (): string => {
    const localReportFile = templates.find((temp) => temp.useName === 'Local File Report');
    return setupData.reportTemplate.length > 0 ? setupData.reportTemplate : localReportFile?.templateId ?? '';
  };

  return (
    <Component
      defaultTitle={setupData.reportTitle ?? ''}
      currencyOptions={currencyList}
      defaultCurrency={getDefaultCurrency()}
      languageOptions={languageList}
      defaultLanguage={setupData.language}
      reportTemplateOptions={templates}
      defaultReportTemplate={getDefaultTemplate()}
      defaultLimitWording={setupData.limitWording}
      defaultAddPageNumber={setupData.addPageNumber}
      disableTitle={setupData.disableTitle ?? false}
      onComplete={onHandlerComplete}
    />
  );
};

export default Connector;
