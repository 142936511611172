import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CenteredProgress } from '../index';

export const NoSessionMessage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Alert severity="error">{t('common:message-no-session')}</Alert>
      <CenteredProgress />
    </Box>
  );
};
