import { Alert, Slide, Snackbar, type AlertProps, type SlideProps, type SnackbarProps } from '@mui/material';

export interface CustomAlertProps extends Omit<SnackbarProps, 'message'> {
  severity: AlertProps['severity'];
  message: AlertProps['children'];
  handleClose: () => void;
}

export const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="down" />;
};

export const CustomAlert = ({ message, severity, handleClose, ...passThrough }: CustomAlertProps) => {
  return (
    <Snackbar {...passThrough} onClose={handleClose}>
      <Alert severity={severity} sx={{ width: '100%' }} data-testid="custom-alert" onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
