import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { CenteredProgress } from '../../../../components';
import { SearchSelectList, SearchSelectListItem } from '../../../../components/SearchSelectList/SearchSelectList';
import tokens from '../../../../styles/designTokens';
import { EntitiesTabProps } from '../EntitiesTab.proptype';

const height = window.innerHeight;
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '1.43rem 4.75rem 2.37rem 4.75rem'
  },
  containers: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    '&:first-child': {
      marginRight: '1.5rem'
    },
    padding: '1.1875rem 2.125rem',
    width: '100%',
    '& tbody': {
      width: '100%',

      '&.MuiTableBody-root': {
        overflow: 'auto',
        height: `${Math.floor(0.0525 * height - 7.187)}vh`,
        display: 'block',
        width: '119%',

        '& .MuiTableRow-root': {
          color: 'inherit',
          display: 'flex',
          outline: '0',
          borderBottom: '1px solid #e2e3e6',
          justifyContent: 'space-between',
          alignItems: 'center',

          '&.selectedRow': {
            backgroundColor: tokens.product15
          },

          '& .MuiTableCell-root': {
            borderBottom: 'none',
            display: 'flex',
            alignItems: 'center'
          }
        }
      }
    }
  },
  entities: {
    fontFamily: tokens.mediumFont.fontFamily
  }
}));

export const EntitiesTab = ({
  primaryEntities,
  selectedPrimaries,
  tradingPartners,
  selectedPartners,
  onPrimaryEntitiesSelectionChange,
  onTradingPartnersSelectionChange
}: EntitiesTabProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedPrimaryEntities, setSelectedPrimaryEntities] = useState<number[]>(selectedPrimaries);
  const [selectedTradingPartners, setSelectedTradingPartners] = useState<number[]>(selectedPartners);

  const primaryEntitiesData = useMemo<SearchSelectListItem[]>(
    () =>
      primaryEntities.map((entity) => ({
        code: <Box className={classes.entities}>{entity.code}</Box>,
        rowId: entity.entityId
      })),
    [primaryEntities, classes]
  );

  const tradingPartnersData = useMemo<SearchSelectListItem[]>(
    () =>
      tradingPartners.map((entity) => ({
        code: <Box className={classes.entities}>{entity.code}</Box>,
        rowId: entity.entityId
      })),
    [tradingPartners, classes]
  );

  const onSelectAllPrimary = (shouldSelect: boolean) => {
    let newSelectedPrimaryEntities: number[] = [];

    if (shouldSelect) {
      newSelectedPrimaryEntities = (primaryEntities ?? []).map((partialEntity) => partialEntity.entityId);
    }

    setSelectedPrimaryEntities(newSelectedPrimaryEntities);
    onPrimaryEntitiesSelectionChange(newSelectedPrimaryEntities);
  };

  const onSelectOnePrimary = (shouldSelect: boolean, id: number) => {
    const newSelectedPrimaryEntities = [...selectedPrimaryEntities];

    if (shouldSelect) {
      newSelectedPrimaryEntities.push(id);
    } else {
      newSelectedPrimaryEntities.splice(newSelectedPrimaryEntities.indexOf(id), 1);
    }

    setSelectedPrimaryEntities(newSelectedPrimaryEntities);
    onPrimaryEntitiesSelectionChange(newSelectedPrimaryEntities);
  };

  const onSelectAllPartner = (shouldSelect: boolean) => {
    let newSelectedTradingPartners: number[] = [];

    if (shouldSelect) {
      newSelectedTradingPartners = (tradingPartners ?? []).map((partialEntity) => partialEntity.entityId);
    }

    setSelectedTradingPartners(newSelectedTradingPartners);
    onTradingPartnersSelectionChange(newSelectedTradingPartners);
  };

  const onSelectOnePartner = (shouldSelect: boolean, id: number) => {
    const newSelectedTradingPartners = [...selectedTradingPartners];

    if (shouldSelect) {
      newSelectedTradingPartners.push(id);
    } else {
      newSelectedTradingPartners.splice(newSelectedTradingPartners.indexOf(id), 1);
    }

    setSelectedTradingPartners(newSelectedTradingPartners);
    onTradingPartnersSelectionChange(newSelectedTradingPartners);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.containers}>
        {primaryEntitiesData.length > 0 ? (
          <SearchSelectList
            title={t('reports:label-primary-entity_plural')}
            searchBy="code"
            searchByPlaceholder={t('reports:placeholder-find-entity')}
            columns={[]}
            data={primaryEntitiesData}
            selectedRows={new Set(selectedPrimaries)}
            onSelectAll={onSelectAllPrimary}
            onSelectOne={onSelectOnePrimary}
          />
        ) : (
          <CenteredProgress />
        )}
      </Box>
      <Box className={classes.containers}>
        {primaryEntitiesData.length > 0 ? (
          <SearchSelectList
            title={t('reports:label-trading-partner_plural')}
            searchBy="code"
            searchByPlaceholder={t('reports:placeholder-find-entity')}
            columns={[]}
            data={tradingPartnersData}
            selectedRows={new Set(selectedPartners)}
            onSelectAll={onSelectAllPartner}
            onSelectOne={onSelectOnePartner}
          />
        ) : (
          <CenteredProgress />
        )}
      </Box>
    </Box>
  );
};
