import { useSelector } from 'react-redux';
import { PrimaryTradingPartnersMap } from '../../redux/localReports/localReports.proptype';
import { selectPrimaryTradingPartnersMap, selectedPrimaryTradingPartnersMapIsCreating } from '../../selectors';

export const useSelectedPrimaryTradingPartnersMap = (): PrimaryTradingPartnersMap | null => {
  const selectedPrimaryTradingPartnersMap = useSelector(selectPrimaryTradingPartnersMap);

  return selectedPrimaryTradingPartnersMap;
};

export const useSelectedPrimaryTradingPartnersMapIsCreating = (): boolean => {
  return useSelector(selectedPrimaryTradingPartnersMapIsCreating);
};
