import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { ReportVersionStatus, ReportSectionTemplateOptions } from '@xbs/xbs-enums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ConnectorProps } from './LocalReportCreateVersionModal.proptype';
import { CustomAlert, SlideTransition } from '../../components/CustomAlert';
import { useWorkingContainer } from '../../hooks/container/useWorkingContainer';
import { useCountryById } from '../../hooks/country/useCountryById';
import { useLanguages } from '../../hooks/languages/useLanguages';
import { useLocalReportsData } from '../../hooks/localfile-report/useLocalReportsData';
import { useLocalReportsPolling } from '../../hooks/localfile-report/useLocalReportsPolling';
import { useSelectedProfitBasedAnalysisConfig } from '../../hooks/pbas/useSelectedProfitBasedAnalysisConfig';
import { useSelectedPrimaryEntities } from '../../hooks/primary-entities/useSelectedPrimaryEntities';
import { useEntityStatementOfFactsConfigForReportPayload } from '../../hooks/report/useEntityStatementOfFactsConfigForReportPayload';
import { useSelectReportSectionsConfig } from '../../hooks/report/useSelectReportSectionsConfig';
import { useTemplateList } from '../../hooks/template-report/useTemplateList';
import { useSelectedPrimaryTradingPartnersMap } from '../../hooks/trading-partners/useSelectedPrimaryTradingPartnersMap';
import { useSelectedTradingPartners } from '../../hooks/trading-partners/useSelectedTradingPartners';
import { useSelectedControlledTransactionsDocumentationConfig } from '../../hooks/transaction/useSelectedControlledTransactionsDocumentationConfig';
import { useSelectedOrderedTransactions } from '../../hooks/transaction/useSelectedOrderedTransactions';
import { useSelectedTransactions } from '../../hooks/transaction/useSelectedTransactions';
import { useTransactionsList } from '../../hooks/transaction/useTransactionsList';
import { useUPE } from '../../hooks/upe/useUPE';
import { useUPECurrency } from '../../hooks/upe/useUPECurrency';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Entity, Transaction, CheckReportCompSearchesStatusResponse } from '../../models';
import { actions as localReportsActions } from '../../redux/localReports';
import {
  EntityStatementOfFactsConfig,
  ReportPayload,
  SelectedTransactionOrdering,
  SetupData,
  SummariesData
} from '../../redux/localReports/localReports.proptype';
import { checkReportTransactionCSStatus, generateNewReport } from '../../redux/localReports/thunks/localReports';
import {
  fetchInternalLocalFileReportInstance,
  updateReportActiveVersion
} from '../../redux/localReports/thunks/reportVersions';
import { fetchTransactions } from '../../redux/transactions/thunks/transactions';
import { AppDispatch } from '../../store';
import { useNotifications } from '../Notifications';

const Connector = ({
  component: Component,
  isOpen,
  onClose,
  jurisdictionId,
  jurisdictionName,
  reportInfo
}: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const flags = useFlags();
  const [featureFlagIsActive] = useFeatureFlags();
  const localReportsData = useLocalReportsData();
  const languages = useLanguages();
  const templates = useTemplateList();
  const transactions = useTransactionsList();
  const selectedPrimaryEntities = useSelectedPrimaryEntities();
  const selectedPrimaryTradingPartnersMap = useSelectedPrimaryTradingPartnersMap();
  const selectedTradingPartners = useSelectedTradingPartners();
  const selectedTransactions = useSelectedTransactions();
  const selectedTransactionsOrdering = useSelectedOrderedTransactions();
  const selectedControlledTransactionsDocumentationConfig = useSelectedControlledTransactionsDocumentationConfig();
  const selectedProfitBasedAnalysisConfig = useSelectedProfitBasedAnalysisConfig();
  const selectedEntityStatementOfFactsConfig = useEntityStatementOfFactsConfigForReportPayload();
  const selectedReportSectionsOptionsConfig = useSelectReportSectionsConfig();
  const mneCurrency = useUPECurrency();
  const upe = useUPE();
  const container = useWorkingContainer();
  const country = useCountryById(jurisdictionId);
  const [alertState, setAlertState] = useState({ open: false, message: '' });
  const { pollAndUpdate } = useLocalReportsPolling();
  const previousTradingPartners = useMemo(() => {
    return reportInfo?.tradingPartners ?? [];
  }, [reportInfo]);
  const previousReportTransactions = useMemo(() => {
    return reportInfo?.reportTransactions ?? [];
  }, [reportInfo]);
  const [isSelectedPrimaryEntitiesInitialized, setIsSelectedPrimaryEntitiesInitialized] = useState(false);

  const previousReportEntityStatementOfFactsConfig = useMemo(() => {
    const initialStateConfig: EntityStatementOfFactsConfig = {
      optionSelected: 0,
      showUPE: false,
      displayOrgChart: true,
      entitiesSelected: { primaryEntityIds: [], tradingPartnersIds: [] }
    };
    const isConfigDefined =
      reportInfo?.entityStatementOfFactsConfig && Object.keys(reportInfo?.entityStatementOfFactsConfig).length > 0;
    return isConfigDefined ? reportInfo?.entityStatementOfFactsConfig : initialStateConfig;
  }, [reportInfo]);

  const previousControlledTransactionSelection = useMemo(() => {
    const initialState = {
      optionSelected: 0,
      transactionsSelectedIds: []
    };
    const isConfigDefined =
      reportInfo?.controlledTransactionsDocumentationConfig &&
      Object.keys(reportInfo?.controlledTransactionsDocumentationConfig).length > 0;
    return isConfigDefined ? reportInfo?.controlledTransactionsDocumentationConfig : initialState;
  }, [reportInfo]);

  const previousCustomAppendices = useMemo(() => reportInfo?.customAppendicesConfig ?? [], [reportInfo]);

  const hasEmptyAppendix = () => {
    return localReportsData.configurationTab.reportSummary.reportSections.appendix.some(
      (appendix) => appendix.files.length === 0
    );
  };

  const onCreateLocalReportSubmit = async () => {
    if (hasEmptyAppendix()) {
      setAlertState({ message: t('common:theres_appendices_without_attachment'), open: true });
      return;
    }

    await generateNewLocalReportVersion();
    dispatch(localReportsActions.resetData());
    onClose();
  };

  const onCreateLocalReportClose = async () => {
    dispatch(localReportsActions.resetData());
    onClose();
  };

  useEffect(() => {
    const selectedLanguage = languages.find((language) => language.isoCode === reportInfo.languageIso);
    const selectedTemplate = templates.find((template) => template.templateId === reportInfo.templateId);

    const previousSetupData: SetupData = {
      reportTitle: reportInfo.name,
      language: selectedLanguage?.isoCode ?? '',
      currency: String(reportInfo?.reportCurrency.currencyId),
      reportTemplate: selectedTemplate?.templateId ?? '',
      limitWording: Boolean(reportInfo.isLimitationWording),
      addPageNumber: Boolean(reportInfo.shouldDisplayPageNumber),
      disableTitle: !featureFlagIsActive('editReportTitle')
    };

    const summaryData: SummariesData = {
      executive: reportInfo?.executiveSummaryIntro,
      conclusion: reportInfo?.executiveSummaryConclusion,
      orgChart: reportInfo?.orgChart
    };

    dispatch(localReportsActions.setSetup(previousSetupData));
    dispatch(localReportsActions.setSummaries(summaryData));

    if (!reportInfo.tradingPartners || !reportInfo.reportTransactions) {
      void dispatch(
        fetchInternalLocalFileReportInstance({
          reportId: reportInfo.internalLocalfileReportId,
          instanceId: reportInfo.internalLocalfileReportInstanceId
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages]);

  useEffect(() => {
    if (!transactions) {
      void dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    if (
      selectedPrimaryTradingPartnersMap &&
      selectedPrimaryEntities?.length === 0 &&
      !isSelectedPrimaryEntitiesInitialized
    ) {
      const entityIds = reportInfo?.primaryEntities.map((entity: Partial<Entity>) => entity.entityId!);
      dispatch(localReportsActions.setSelectedPrimaryEntities(entityIds));
      setIsSelectedPrimaryEntitiesInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedPrimaryTradingPartnersMap, selectedPrimaryEntities]);

  useEffect(() => {
    if (
      selectedPrimaryTradingPartnersMap &&
      selectedTradingPartners?.length === 0 &&
      !isSelectedPrimaryEntitiesInitialized &&
      previousTradingPartners?.length !== 0
    ) {
      const previousTradingPartnersIds = previousTradingPartners.map(
        (tradingPartner: Partial<Entity>) => tradingPartner.entityId!
      );
      dispatch(localReportsActions.setSelectedTradingPartners(previousTradingPartnersIds));
    }
  }, [
    dispatch,
    selectedPrimaryTradingPartnersMap,
    selectedTradingPartners,
    previousTradingPartners,
    isSelectedPrimaryEntitiesInitialized
  ]);

  useEffect(() => {
    if (
      selectedPrimaryTradingPartnersMap &&
      selectedTransactions?.length === 0 &&
      previousReportTransactions?.length !== 0
    ) {
      const transactionOrdering: SelectedTransactionOrdering = {};
      const transactionSelected = previousReportTransactions.map((transaction: Partial<Transaction>) => {
        transactionOrdering[transaction.transactionId!] = transaction.transactionIdOrder
          ? transaction.transactionIdOrder
          : null;
        return transaction.transactionId!;
      });
      dispatch(localReportsActions.setSelectedTransactions(transactionSelected));
      dispatch(localReportsActions.setSelectedTransactionsOrdering(transactionOrdering));
    }
  }, [dispatch, selectedPrimaryTradingPartnersMap, selectedTransactions, previousReportTransactions]);

  useEffect(() => {
    dispatch(
      localReportsActions.setConfigurationTabEntityStatementOfFactsConfig(previousReportEntityStatementOfFactsConfig)
    );
  }, [previousReportEntityStatementOfFactsConfig, dispatch]);

  const profitBasedConfig: any = useMemo(
    () =>
      (reportInfo?.profitBasedAnalysisConfig && reportInfo?.profitBasedAnalysisConfig[0]?.configuration) ?? {
        pbaHideFinancials: {}
      },
    [reportInfo]
  );

  useEffect(() => {
    dispatch(localReportsActions.setProfitBasedAnalysisConfig(profitBasedConfig));
  }, [profitBasedConfig, dispatch]);

  useEffect(() => {
    if (previousControlledTransactionSelection.optionSelected !== 0) {
      dispatch(
        localReportsActions.setControlledTransactionsDocumentationConfig(previousControlledTransactionSelection)
      );
    }
  }, [previousControlledTransactionSelection, dispatch]);

  useEffect(() => {
    if (previousControlledTransactionSelection.optionSelected !== 0) {
      dispatch(
        localReportsActions.setControlledTransactionsDocumentationConfig(previousControlledTransactionSelection)
      );
    }
  }, [previousControlledTransactionSelection, dispatch]);

  useEffect(() => {
    dispatch(localReportsActions.setCustomAppendices(previousCustomAppendices));
  }, [dispatch, previousCustomAppendices]);

  useEffect(() => {
    if (reportInfo.sectionsOptionsConfig) {
      // For reports generated before this code is merged, the Cover section won't be included in the sectionsOptionsConfig
      // So we need to add it manually
      const options = [
        {
          active:
            reportInfo.sectionsOptionsConfig.find(
              (option) => option.code === ReportSectionTemplateOptions.ByName.Cover.Name
            )?.active ?? true,
          code: ReportSectionTemplateOptions.ByName.Cover.Name,
          name: ReportSectionTemplateOptions.ByName.Cover.Localization
        },
        ...reportInfo.sectionsOptionsConfig.filter(
          (option) => option.code !== ReportSectionTemplateOptions.ByName.Cover.Name
        )
      ];
      dispatch(localReportsActions.setConfigurationTabReportSectionsOptions(options));
    }
  }, [dispatch, reportInfo.sectionsOptionsConfig]);

  const generateNewLocalReportVersion = async () => {
    let reportCompsearchesStatus: CheckReportCompSearchesStatusResponse = {
      pbasWithoutCompsearch: [],
      pbasWithFailedCompsearch: []
    };
    if (flags.checkReportTransactionsCompSearchStatus) {
      reportCompsearchesStatus = unwrapResult(
        await dispatch(
          checkReportTransactionCSStatus({
            selectedTransactionIds: selectedTransactions.map((trans) => trans.transactionId)
          })
        )
      );
    }

    if (
      flags.checkReportTransactionsCompSearchStatus &&
      (reportCompsearchesStatus.pbasWithoutCompsearch.length > 0 ||
        reportCompsearchesStatus.pbasWithFailedCompsearch.length > 0)
    ) {
      reportCompsearchesStatus.pbasWithoutCompsearch.forEach((reportTransactionNotRanCompSearch) => {
        showNotification({
          type: 'error',
          message: `“PBA ${reportTransactionNotRanCompSearch.pbaName} - ${reportTransactionNotRanCompSearch.transactionName}“ does not have a comp search executed. Please execute comp search before generating this report.`
        });
      });

      reportCompsearchesStatus.pbasWithFailedCompsearch.forEach((pbaWithFailedCompsearch) => {
        showNotification({
          type: 'error',
          message: `“PBA ${pbaWithFailedCompsearch.pbaName} - ${pbaWithFailedCompsearch.transactionName}“ has a failed comp search. Please try executing the comp search again before generating this report.`
        });
      });
    } else {
      const reportPayload: ReportPayload = {
        internalLocalfileReportId: reportInfo.internalLocalfileReportId,
        currencyId: localReportsData.setup.currency,
        selectedTransactionIds: selectedTransactions.map((trans) => trans.transactionId),
        selectedTransactionsOrdering,
        entityStatementOfFactsConfig: selectedEntityStatementOfFactsConfig,
        name: localReportsData.setup.reportTitle,
        templateId: localReportsData.setup.reportTemplate,
        containerId: container?.containerId!,
        primaryEntityIds: selectedPrimaryEntities.map((ent) => ent.entityId),
        tradingPartnerIds: selectedTradingPartners.map((ent) => ent.entityId),
        mneCurrency: localReportsData.setup.currency === mneCurrency?.currencyId.toString(),
        mneId: upe?.entityId!,
        executiveSummaryIntro: localReportsData.summaries.executive,
        executiveSummaryConclusion: localReportsData.summaries.conclusion,
        orgChart: localReportsData.summaries.orgChart,
        targetLanguage: localReportsData.setup.language,
        newReportInstance: true,
        isoCode: country?.isoCode,
        domicileName: country?.name,
        domicileId: country?.countryId,
        taxYear: container?.taxYear!,
        shouldDisplayPageNumber: localReportsData.setup.addPageNumber,
        isLimitationWording: localReportsData.setup.limitWording,
        isActive: true,
        container: {
          containerId: container?.containerId!,
          name: container?.name!,
          taxYear: container?.taxYear!,
          isService: container?.isService,
          isPlaceholder: container?.isPlaceholder,
          tenantId: container?.tenantId
        },
        controlledTransactionsDocumentationConfig: selectedControlledTransactionsDocumentationConfig,
        customAppendicesConfig: localReportsData.configurationTab.reportSummary.reportSections.appendix,
        sectionsOptionsConfig: selectedReportSectionsOptionsConfig,
        profitBasedAnalysisConfig: selectedProfitBasedAnalysisConfig
      };

      if (featureFlagIsActive('showPbaReportConfiguration')) {
        reportPayload.profitBasedAnalysisConfig = selectedProfitBasedAnalysisConfig;
      }

      const reportMetadata = unwrapResult(await dispatch(generateNewReport(reportPayload)));
      const reportName = featureFlagIsActive('editReportTitle') ? localReportsData.setup.reportTitle : reportInfo.name;

      dispatch(
        localReportsActions.replaceLocalFileReportInstanceData({
          previousInstanceId: reportInfo.internalLocalfileReportInstanceId,
          instanceData: {
            ...reportInfo,
            name: reportName,
            customAppendicesConfig: localReportsData.configurationTab.reportSummary.reportSections.appendix,
            isUploadedReport: 0,
            status: {
              internalLocalfileReportInstanceStatusId: ReportVersionStatus.ByName.Pending.Id,
              name: ReportVersionStatus.ByName.Pending.Name
            },
            createdAt: new Date().toString()
          }
        })
      );

      pollAndUpdate(
        reportName,
        Number(reportInfo.internalLocalfileReportId),
        Number(reportMetadata.reportInstanceId),
        Number(reportInfo.internalLocalfileReportInstanceId),
        container?.containerId
      );

      void dispatch(
        updateReportActiveVersion({
          reportId: reportInfo.internalLocalfileReportId,
          internalLocalfileReportInstanceId: reportMetadata.reportInstanceId
        })
      );
    }
  };

  return (
    <div>
      <CustomAlert
        open={alertState.open}
        message={alertState.message}
        handleClose={() => {
          setAlertState((prev) => ({ ...prev, open: false }));
        }}
        severity="error"
        TransitionComponent={SlideTransition}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        autoHideDuration={3000}
      />
      {isOpen && (
        <Component
          title={t('reports:label-create-new-report-version')}
          jurisdictionId={jurisdictionId}
          jurisdictionName={jurisdictionName}
          onSubmit={onCreateLocalReportSubmit}
          onClose={onCreateLocalReportClose}
        />
      )}
    </div>
  );
};

export default Connector;
