import { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useCustomAppendices } from '../../../../../../hooks/custom-appendices/useCustomAppendices';
import { CustomAppendix } from '../../../../../../models/reports.interface';
import { selectWorkingContainer } from '../../../../../../selectors';
import appColors from '../../../../../../styles/appColors';
import tokens from '../../../../../../styles/designTokens';
import { captionOverline } from '../../../../../../styles/typography';
import { logGoogleAnalyticsEvent } from '../../../../../../utils/sendGoogleAnalyticsEvent';
import { AppendixNavBtn } from '../../../components/AppendixNavBtn/AppendixNavBtn';
import { useReorderingAppendix } from '../../../utils/useReorderingAppendix';
import { AppendixNavProps } from '../Appendix.proptype';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    marginTop: '1.6rem'
  },
  sectionTitle: {
    ...captionOverline,
    color: tokens.core2,
    textTransform: 'capitalize'
  },
  onDraggingContainer: {
    border: 'solid 1px',
    borderColor: tokens.purpleLight1,
    backgroundColor: appColors.reports.highlight.background,
    borderRadius: '0.25rem',
    marginTop: '0.5rem',
    paddingBottom: '1rem'
  },
  dragContainer: {
    border: 'solid 1px transparent',
    paddingBottom: '1rem',
    marginTop: '0.5rem'
  }
}));

export const AppendixNav = ({
  provider,
  isAppendixDisplay,
  appendixConfigState,
  displayAppendixSection,
  emitAddAppendixInAppendixConfigState,
  emitCurrentAppendixInAppendixConfigState
}: AppendixNavProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const workingContainer = useSelector(selectWorkingContainer);
  const customAppendices = useCustomAppendices();
  const { currentAppendix } = appendixConfigState;
  const { onDragEnd, onDragStart, isDragging } = useReorderingAppendix();

  const changeToSpecificAppendixSection = (appendixIdentifier: CustomAppendix['identifier'] | 'NEW') => {
    displayAppendixSection();
    if (appendixIdentifier === 'NEW') {
      const appendixNumber = customAppendices.length + 1;
      const newAppendix = generateNewAppendix(appendixNumber);
      emitAddAppendixInAppendixConfigState(newAppendix);
      emitCurrentAppendixInAppendixConfigState(newAppendix.identifier);
      return;
    }

    emitCurrentAppendixInAppendixConfigState(appendixIdentifier);
  };

  const generateNewAppendix = useCallback((index: number): CustomAppendix => {
    // TODO: Replace with uuid library, although randomUUID is supported by all major browsers
    const identifier = (window.crypto as any).randomUUID
      ? (window.crypto as any).randomUUID()
      : `custom-appendix-${index}`;

    return {
      title: '',
      display: true,
      order: index,
      identifier,
      files: []
    };
  }, []);

  const appendixAutoGeneratedBtnProps = {
    isAuto: true,
    active: currentAppendix === '' && isAppendixDisplay,
    action: () => {
      /* eslint-disable camelcase */
      void logGoogleAnalyticsEvent({
        event_category: `report_config_automated_appendix_click`,
        event_label: `Report configuration tab automated appendix click`,
        container_id: workingContainer?.containerId
      });
      changeToSpecificAppendixSection('');
    },
    appendixBtn: true
  };

  const addAppendixBtnProps = {
    addButton: true,
    action: () => {
      void logGoogleAnalyticsEvent({
        event_category: `report_config_add_appendix_click`,
        event_label: `Report configuration tab add appendix click`,
        container_id: workingContainer?.containerId
      });
      changeToSpecificAppendixSection('NEW');
    }
  };

  const renderAppendixBtn = (appendix: CustomAppendix, index: number) => {
    const appendixNumber = `Appendix ${appendix.order}`;

    const appendixNavBtnProps = {
      appendixBtn: true,
      key: `appendix-btn:${appendix.identifier}`,
      subText: appendix.title.length > 0 ? appendix.title : appendixNumber,
      active: currentAppendix === appendix.identifier && isAppendixDisplay,
      action: () => {
        changeToSpecificAppendixSection(appendix.identifier);
      }
    };

    return (
      <Draggable key={appendix.identifier} index={index} draggableId={appendix.identifier}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <AppendixNavBtn {...appendixNavBtnProps} />
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Box className={classes.container}>
        <Typography className={classes.sectionTitle}>
          {t('reports:configurationTab-Appendix-AppendixNav-title')}
        </Typography>

        <AppendixNavBtn {...appendixAutoGeneratedBtnProps} />

        <Box className={isDragging ? classes.onDraggingContainer : classes.dragContainer}>
          <AppendixList provider={provider}>
            {customAppendices.map((appendix, i) => renderAppendixBtn(appendix, i))}
          </AppendixList>
        </Box>

        <AppendixNavBtn {...addAppendixBtnProps} />
      </Box>
    </DragDropContext>
  );
};

export const AppendixList = ({ children }: any) => {
  return (
    <Droppable droppableId="appendix-list">
      {(provider) => (
        <Box>
          <div {...provider.droppableProps} ref={provider.innerRef}>
            {children}
            {provider.placeholder}
          </div>
        </Box>
      )}
    </Droppable>
  );
};
