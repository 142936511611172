import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Button, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import editAccess from '../../../components/HigherOrderComponents/EditAccess';
import { selectWorkingContainer } from '../../../selectors';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticsEvent';
import { ContentHeader } from '../../ContentHeader';
import { LocalReportsHeaderProps } from '../LocalReports.proptype';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    height: '3.8rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    width: '100%',
    whiteSpace: 'nowrap',
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

export const LocalReportsHeader = (props: LocalReportsHeaderProps) => {
  const { title, onCreateLocalReport } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const workingContainer = useSelector(selectWorkingContainer);

  const onHandlerCreateLocalReport = useCallback(() => {
    onCreateLocalReport();
  }, [onCreateLocalReport]);

  return (
    <Box className={classes.headerContainer}>
      <ContentHeader primaryEntity={title} title={t('reports:title-local-reports')} link="/" icon={<ArrowBack />} />
      {editAccess(
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            /* eslint-disable camelcase */
            void logGoogleAnalyticsEvent({
              event_category: 'report_repository_create_local_button_click',
              event_label: `Report repository create local report button click`,
              container_id: workingContainer?.containerId
            });
            onHandlerCreateLocalReport();
          }}
        >
          {t('reports:label-create-local-report')}
        </Button>
      )}
    </Box>
  );
};
