import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Reports as Component } from './components';
import Connector from './connector';
import { LocalReports } from '../LocalReports';
import RouteOptions from '../Router/RouteOptions';

const ChildRoutes = () => {
  const { path } = useRouteMatch();
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  return (
    <Switch>
      <Route path={`${path}`}>
        <LocalReports jurisdictionId={jurisdictionId} />
      </Route>
    </Switch>
  );
};

export const Reports = () => (
  <RouteOptions
    RootRoute={<Connector component={Component} />}
    ChildRoutes={[{ path: 'reports/:jurisdictionId', component: <ChildRoutes /> }]}
  />
);
