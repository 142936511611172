import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomAppendix, CustomAppendixFile, AppendixFileState } from '../../../../../../models/reports.interface';
import { actions } from '../../../../../../redux/localReports';
import { getSignedUrlForAppendix, uploadReportToSignedUrl } from '../../../../../../redux/localReports/thunks/upload';
import { selectWorkingContainer } from '../../../../../../selectors';
import { AppDispatch } from '../../../../../../store';
import { ConnectorAppendixGenerateProps } from '../Appendix.proptype';

export const Connector = ({ component: Component, appendix }: ConnectorAppendixGenerateProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const container = useSelector(selectWorkingContainer);

  const dispatchUpdateAppendixTitle = (newTitle: string, appendixId: CustomAppendix['identifier']) => {
    void dispatch(actions.updateAppendixTitleInAppendixConfigState({ newTitle, appendixId }));
  };

  const dispatchAddFileForAppendix = (file: CustomAppendixFile, identifier: CustomAppendix['identifier']) => {
    void dispatch(actions.addNewFileForAppendixInAppendixConfigState({ file, identifier }));
  };

  const dispatchDeleteAppendix = (identifier: CustomAppendix['identifier']) => {
    void dispatch(actions.deleteAppendixInAppendixConfigState({ identifier }));
  };

  const dispatchDeleteFileForAppendix = (file: CustomAppendixFile, appendix: CustomAppendix) => {
    void dispatch(actions.deleteAppendixFileInAppendixConfigState({ file, appendix }));
  };

  const dispatchUploadAppendixFile = async (appendix: CustomAppendix, appendixFile: CustomAppendixFile, file: File) => {
    if (container) {
      dispatchAddFileForAppendix(appendixFile, appendix.identifier);

      try {
        const signedUrlPayload = {
          containerId: container.containerId,
          mimeType: file.type,
          fileExtension: '.pdf',
          appendixFileName: appendixFile.name,
          appendixFileIdentifier: appendix.identifier
        };
        const signedUrlAppendix = unwrapResult(await dispatch(getSignedUrlForAppendix(signedUrlPayload)));
        const awsSignedUrlPath = signedUrlAppendix?.data?.url;
        void dispatch(
          actions.updateAppendixFile({
            appendix,
            file: {
              ...appendixFile,
              state: AppendixFileState.UPLOADING,
              link: awsSignedUrlPath
            }
          })
        );
        await dispatch(uploadReportToSignedUrl({ awsSignedUrlPath, reportFile: file }));
        void dispatch(
          actions.updateAppendixFile({
            appendix,
            file: {
              ...appendixFile,
              state: AppendixFileState.UPLOADED
            }
          })
        );
      } catch {
        void dispatch(
          actions.updateAppendixFile({
            appendix,
            file: {
              ...appendixFile,
              state: AppendixFileState.FAILED
            }
          })
        );
      }
    }
  };

  return (
    <Component
      appendix={appendix}
      updateAppendixTitle={dispatchUpdateAppendixTitle}
      deleteAppendix={dispatchDeleteAppendix}
      deleteFileForAppendix={dispatchDeleteFileForAppendix}
      uploadFileForAppendix={dispatchUploadAppendixFile}
    />
  );
};
