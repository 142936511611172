import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { ReportVersionStatus, ReportReviewStatuses } from '@xbs/xbs-enums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ConnectorProps } from './LocalReports.proptype';
import { CustomAlert, SlideTransition } from '../../components/CustomAlert';
import { useWorkingContainer } from '../../hooks/container/useWorkingContainer';
import { useCountryById } from '../../hooks/country/useCountryById';
import { useCurrencies } from '../../hooks/currency/useCurrencies';
import { useEntities } from '../../hooks/entity/useEntities';
import { useLocalFileReports } from '../../hooks/localfile-report/useLocalFileReports';
import { useLocalReportsData } from '../../hooks/localfile-report/useLocalReportsData';
import { useLocalReportsPolling } from '../../hooks/localfile-report/useLocalReportsPolling';
import { useSelectedProfitBasedAnalysisConfig } from '../../hooks/pbas';
import { useSelectedPrimaryEntities } from '../../hooks/primary-entities/useSelectedPrimaryEntities';
import { useEntityStatementOfFactsConfigForReportPayload } from '../../hooks/report/useEntityStatementOfFactsConfigForReportPayload';
import { useTemplateList } from '../../hooks/template-report/useTemplateList';
import { useSelectedTradingPartners } from '../../hooks/trading-partners/useSelectedTradingPartners';
import { useSelectedControlledTransactionsDocumentationConfig } from '../../hooks/transaction/useSelectedControlledTransactionsDocumentationConfig';
import { useSelectedOrderedTransactions } from '../../hooks/transaction/useSelectedOrderedTransactions';
import { useSelectedTransactions } from '../../hooks/transaction/useSelectedTransactions';
import { useUPE } from '../../hooks/upe/useUPE';
import { useUPECurrency } from '../../hooks/upe/useUPECurrency';
import { LocalReportData, CheckReportCompSearchesStatusResponse } from '../../models';
import { EntityStatementOfFactsConfig } from '../../models/reports.interface';
import { fetchComplianceCentralReportsInfo } from '../../redux/complianceCentral/thunks/complianceCentral';
import { fetchEntities } from '../../redux/entities/thunks/entities';
import { actions as localReportsActions } from '../../redux/localReports';
import { ReportPayload } from '../../redux/localReports/localReports.proptype';
import {
  checkReportTransactionCSStatus,
  fetchReportInstancesForJurisdiction,
  fetchReportInstancesForJurisdictionNew,
  fetchTemplates,
  generateNewReport,
  saveReportStatus
} from '../../redux/localReports/thunks/localReports';
import {
  publishReport,
  unpublishReport,
  updateReportActiveVersion
} from '../../redux/localReports/thunks/reportVersions';
import { AppDispatch } from '../../store';
import { hasAccess } from '../../utils';
import { LocalReportCreationModal } from '../LocalReportCreationModal';
import { createIncludeAllEntitiesConfig } from '../LocalReportCreationTabs/ConfigurationTab/utils/createIncludeAllEntitiesConfig';
import { createIncludeAllTransactionsConfig } from '../LocalReportCreationTabs/ConfigurationTab/utils/createIncludeAllTransactionConfig';
import { useNotifications } from '../Notifications';

const Connector = ({ jurisdictionId, component: Component }: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const flags = useFlags();
  const localReportsData = useLocalReportsData();
  const selectedPrimaryEntities = useSelectedPrimaryEntities();
  const selectedTradingPartners = useSelectedTradingPartners();
  const selectedTransactions = useSelectedTransactions();
  const selectedTransactionsOrdening = useSelectedOrderedTransactions();
  const selectedEntityStatementOfFactsConfig = useEntityStatementOfFactsConfigForReportPayload();
  const selectedControlledTransactionsDocumentationConfig = useSelectedControlledTransactionsDocumentationConfig();
  const selectProfitBasedAnalysisConfig = useSelectedProfitBasedAnalysisConfig();

  const mneCurrency = useUPECurrency();
  const currencies = useCurrencies();
  const entities = useEntities();
  const upe = useUPE();
  const localFileReports = useLocalFileReports();
  const container = useWorkingContainer();
  const country = useCountryById(jurisdictionId)!;
  const reportTemplates = useTemplateList();
  const [showLocalReportCreationModal, setShowLocalReportCreationModal] = useState(false);
  const [alertState, setAlertState] = useState({ open: false, message: '' });
  const { pollAndUpdate } = useLocalReportsPolling();

  useEffect(() => {
    if (entities === null) {
      void dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  useEffect(() => {
    if (entities && reportTemplates?.length === 0) {
      void dispatch(fetchTemplates(entities[0]?.entityId));
    }
  }, [dispatch, entities, reportTemplates]);

  const quickGenerate = useCallback(
    async (report: LocalReportData) => {
      let reportCompsearchesStatus: CheckReportCompSearchesStatusResponse = {
        pbasWithoutCompsearch: [],
        pbasWithFailedCompsearch: []
      };

      const customAppendicesConfig = report.customAppendicesConfig ?? [];

      dispatch(
        localReportsActions.replaceLocalFileReportInstanceData({
          previousInstanceId: report.internalLocalfileReportInstanceId,
          instanceData: {
            ...report,
            customAppendicesConfig,
            isUploadedReport: 0,
            status: {
              internalLocalfileReportInstanceStatusId: ReportVersionStatus.ByName.Pending.Id,
              name: ReportVersionStatus.ByName.Pending.Name
            },
            createdAt: new Date().toString()
          }
        })
      );

      if (flags.checkReportTransactionsCompSearchStatus) {
        reportCompsearchesStatus = unwrapResult(
          await dispatch(
            checkReportTransactionCSStatus({
              selectedTransactionIds: report.reportTransactions.map((transaction: any) => transaction.transactionId)
            })
          )
        );
        const failed = Boolean(
          reportCompsearchesStatus.pbasWithFailedCompsearch.length > 0 ||
            reportCompsearchesStatus.pbasWithoutCompsearch.length > 0
        );
        if (failed) {
          dispatch(
            localReportsActions.replaceLocalFileReportInstanceData({
              previousInstanceId: report.internalLocalfileReportInstanceId,
              instanceData: {
                ...report,
                status: {
                  internalLocalfileReportInstanceStatusId: ReportVersionStatus.ByName.Failed.Id,
                  name: ReportVersionStatus.ByName.Failed.Name
                }
              }
            })
          );
        }
      }

      if (
        flags.checkReportTransactionsCompSearchStatus &&
        (reportCompsearchesStatus.pbasWithoutCompsearch.length > 0 ||
          reportCompsearchesStatus.pbasWithFailedCompsearch.length > 0)
      ) {
        reportCompsearchesStatus.pbasWithoutCompsearch.forEach((reportTransactionNotRanCompSearch) => {
          showNotification({
            type: 'error',
            message: `“PBA ${reportTransactionNotRanCompSearch.pbaName}  - ${reportTransactionNotRanCompSearch.transactionName}“ does not have a comp search executed. Please execute comp search before generating this report.`
          });
        });

        reportCompsearchesStatus.pbasWithFailedCompsearch.forEach((pbaWithFailedCompsearch) => {
          showNotification({
            type: 'error',
            message: `“PBA ${pbaWithFailedCompsearch.pbaName} - ${pbaWithFailedCompsearch.transactionName}“ has a failed comp search. Please try executing the comp search again before generating this report.`
          });
        });
      } else {
        let defaultTemplate;

        const selectedTransactionsOrdeningMap = (reportTransactions: any[]) => {
          const transactionsOrder: any = {};
          for (const transaction of reportTransactions) {
            transactionsOrder[transaction.transactionId!] = transaction.transactionIdOrder;
          }

          return transactionsOrder;
        };

        const entityStatementOfFactsConfig: EntityStatementOfFactsConfig =
          report.entityStatementOfFactsConfig ??
          createIncludeAllEntitiesConfig(report.primaryEntities, report.tradingPartners, upe!);
        const controlledTransactionsDocumentationConfig =
          report.controlledTransactionsDocumentationConfig ??
          createIncludeAllTransactionsConfig(report.reportTransactions);
        const profitBasedConfig = report.profitBasedAnalysisConfig?.[0]?.configuration ?? {
          pbaHideFinancials: {}
        };

        if (!report.templateId && report.primaryEntities.length > 0) {
          const entityId = report.primaryEntities[0].entityId ?? 1;
          const response = unwrapResult(await dispatch(fetchTemplates(entityId)));
          defaultTemplate = response[1]?.templateId;
        }

        const reportPayload: ReportPayload = {
          internalLocalfileReportId: report.internalLocalfileReportId,
          currencyId: report.reportCurrency.currencyId.toString(),
          selectedTransactionIds: report.reportTransactions.map((transaction: any) => transaction.transactionId),
          selectedTransactionsOrdering: selectedTransactionsOrdeningMap(report.reportTransactions),
          name: report.name,
          templateId: report.templateId ?? defaultTemplate,
          containerId: container?.containerId!,
          primaryEntityIds: report.primaryEntities.map((entity: any) => entity.entityId),
          tradingPartnerIds: report.tradingPartners.map((entity: any) => entity.entityId),
          mneCurrency: report.reportCurrency.currencyId.toString() === mneCurrency?.currencyId.toString(),
          mneId: upe?.entityId!,
          executiveSummaryIntro: report.executiveSummaryIntro,
          executiveSummaryConclusion: report.executiveSummaryConclusion,
          entityStatementOfFactsConfig,
          controlledTransactionsDocumentationConfig,
          customAppendicesConfig,
          orgChart: report.orgChart,
          targetLanguage: report.languageIso,
          newReportInstance: true,
          isoCode: country?.isoCode,
          domicileName: country?.name,
          domicileId: country?.countryId,
          taxYear: container?.taxYear!,
          shouldDisplayPageNumber: report.shouldDisplayPageNumber,
          isLimitationWording: report.isLimitationWording,
          isActive: Boolean(report.isActive),
          container: {
            containerId: container?.containerId!,
            name: container?.name!,
            taxYear: container?.taxYear!,
            isService: container?.isService,
            isPlaceholder: container?.isPlaceholder,
            tenantId: container?.tenantId
          },
          sectionsOptionsConfig: report.sectionsOptionsConfig,
          profitBasedAnalysisConfig: profitBasedConfig
        };

        const reportMetadata = unwrapResult(await dispatch(generateNewReport(reportPayload)));

        pollAndUpdate(
          report.name,
          Number(report.internalLocalfileReportId),
          Number(reportMetadata.reportInstanceId),
          Number(report.internalLocalfileReportInstanceId),
          container?.containerId
        );

        void dispatch(
          updateReportActiveVersion({
            reportId: report.internalLocalfileReportId,
            internalLocalfileReportInstanceId: reportMetadata.reportInstanceId
          })
        );
      }
    },
    [
      container,
      country,
      mneCurrency,
      dispatch,
      pollAndUpdate,
      upe,
      showNotification,
      flags.checkReportTransactionsCompSearchStatus
    ]
  );

  const onUpdateReportStatus = async (reportStatus: string, report: LocalReportData) => {
    await dispatch(
      saveReportStatus({
        reportId: report.internalLocalfileReportId,
        reportInstanceId: report.internalLocalfileReportInstanceId,
        reportStatus,
        containerId: container?.containerId
      })
    );
    await (ReportReviewStatuses.ByOldStatus[1].newStatuses.includes(reportStatus)
      ? dispatch(
          publishReport({
            reportId: report.internalLocalfileReportId,
            reportInstanceId: report.internalLocalfileReportInstanceId,
            containerId: container?.containerId
          })
        )
      : dispatch(
          unpublishReport({
            reportId: report.internalLocalfileReportId,
            reportInstanceId: report.internalLocalfileReportInstanceId
          })
        ));

    void dispatch(fetchComplianceCentralReportsInfo(container!.containerId));
  };

  const onCreateLocalReportOpen = () => {
    setShowLocalReportCreationModal(true);
  };

  const onCreateLocalReportClose = async () => {
    dispatch(localReportsActions.resetData());
    setShowLocalReportCreationModal(false);
  };

  const hasEmptyAppendix = () => {
    return localReportsData.configurationTab.reportSummary.reportSections.appendix.some(
      (appendix) => appendix.files.length === 0
    );
  };

  const onCreateLocalReportSubmit = async () => {
    if (hasEmptyAppendix()) {
      setAlertState({ message: t('common:theres_appendices_without_attachment'), open: true });
      return;
    }

    await onCreateLocalReportClose();
    const reportPayload: ReportPayload = {
      currencyId: localReportsData.setup.currency,
      selectedTransactionIds: selectedTransactions.map((trans) => trans.transactionId),
      selectedTransactionsOrdering: selectedTransactionsOrdening,
      entityStatementOfFactsConfig: selectedEntityStatementOfFactsConfig,
      name: localReportsData.setup.reportTitle,
      templateId: localReportsData.setup.reportTemplate,
      containerId: container?.containerId!,
      primaryEntityIds: selectedPrimaryEntities.map((ent) => ent.entityId),
      tradingPartnerIds: selectedTradingPartners.map((ent) => ent.entityId),
      mneCurrency: localReportsData.setup.currency === mneCurrency?.currencyId.toString(),
      mneId: upe?.entityId!,
      executiveSummaryIntro: localReportsData.summaries.executive,
      executiveSummaryConclusion: localReportsData.summaries.conclusion,
      orgChart: localReportsData.summaries.orgChart,
      targetLanguage: localReportsData.setup.language,
      newReportInstance: false,
      isoCode: country?.isoCode,
      domicileName: country?.name,
      domicileId: country?.countryId,
      taxYear: container?.taxYear!,
      shouldDisplayPageNumber: localReportsData.setup.addPageNumber,
      isLimitationWording: localReportsData.setup.limitWording,
      isActive: true,
      container: {
        containerId: container?.containerId,
        name: container?.name,
        taxYear: container?.taxYear,
        isService: container?.isService,
        isPlaceholder: container?.isPlaceholder,
        tenantId: container?.tenantId
      },
      sectionsOptionsConfig: localReportsData.configurationTab.reportSummary.reportSections.core,
      customAppendicesConfig: localReportsData.configurationTab.reportSummary.reportSections.appendix,
      controlledTransactionsDocumentationConfig: selectedControlledTransactionsDocumentationConfig,
      profitBasedAnalysisConfig: selectProfitBasedAnalysisConfig
    };

    const reportMetadata = unwrapResult(await dispatch(generateNewReport(reportPayload)));
    const reportId = Number(reportMetadata.reportId);
    const reportInstanceId = Number(reportMetadata.reportInstanceId);

    setNewlyCreatedReportDataInState(reportPayload, reportId, reportInstanceId);

    let reportCompsearchesStatus: CheckReportCompSearchesStatusResponse = {
      pbasWithoutCompsearch: [],
      pbasWithFailedCompsearch: []
    };

    if (flags.checkReportTransactionsCompSearchStatus) {
      reportCompsearchesStatus = unwrapResult(
        await dispatch(
          checkReportTransactionCSStatus({
            selectedTransactionIds: reportPayload.selectedTransactionIds
          })
        )
      );
    }

    if (
      flags.checkReportTransactionsCompSearchStatus &&
      (reportCompsearchesStatus.pbasWithoutCompsearch.length > 0 ||
        reportCompsearchesStatus.pbasWithFailedCompsearch.length > 0)
    ) {
      reportCompsearchesStatus.pbasWithoutCompsearch.forEach((reportTransactionNotRanCompSearch) => {
        showNotification({
          type: 'error',
          message: `“PBA ${reportTransactionNotRanCompSearch.pbaName}  - ${reportTransactionNotRanCompSearch.transactionName}“ does not have a comp search executed. Please execute comp search before generating this report.`
        });
      });

      reportCompsearchesStatus.pbasWithFailedCompsearch.forEach((pbaWithFailedCompsearch) => {
        showNotification({
          type: 'error',
          message: `“PBA ${pbaWithFailedCompsearch.pbaName} - ${pbaWithFailedCompsearch.transactionName}“ has a failed comp search. Please try executing the comp search again before generating this report.`
        });
      });
    }
  };

  /**
   * Used to show a new report's tile immediately after it's created
   */
  const setNewlyCreatedReportDataInState = (
    reportPayload: ReportPayload,
    reportId: number,
    reportInstanceId: number
  ) => {
    const newReportData: LocalReportData = {
      internalLocalfileReportId: reportId,
      name: reportPayload.name,
      emailStatus: null,
      internalLocalfileReportInstanceId: reportInstanceId,
      version: 1,
      reportStatus: 'draft',
      createdAt: new Date().toString(),
      customerVersionFlag: 0,
      finalVersionFlag: 0,
      reportCurrency: currencies.find((curr) => curr.currencyId === Number(reportPayload.currencyId))!,
      executiveSummaryConclusion: reportPayload.executiveSummaryConclusion,
      orgChart: reportPayload.orgChart,
      executiveSummaryIntro: reportPayload.executiveSummaryIntro,
      languageIso: reportPayload.targetLanguage,
      isUploadedReport: 0,
      isActive: 1,
      binaryPath: '',
      templateId: reportPayload.templateId,
      reportTransactions: selectedTransactions,
      primaryEntities: selectedPrimaryEntities,
      tradingPartners: selectedTradingPartners,
      status: {
        internalLocalfileReportInstanceStatusId: ReportVersionStatus.ByName.Pending.Id,
        name: ReportVersionStatus.ByName.Pending.Name
      },
      wordReportStatusId: 1,
      wordReportBinaryPath: '',
      entityStatementOfFactsConfig: reportPayload.entityStatementOfFactsConfig,
      controlledTransactionsDocumentationConfig: reportPayload.controlledTransactionsDocumentationConfig,
      customAppendicesConfig: reportPayload.customAppendicesConfig,
      sectionsOptionsConfig: reportPayload.sectionsOptionsConfig,
      profitBasedAnalysisConfig: reportPayload.profitBasedAnalysisConfig
    };

    dispatch(localReportsActions.setLocalFileReport([newReportData, ...(localFileReports ?? [])]));
    pollAndUpdate(newReportData.name, reportId, reportInstanceId, undefined, container?.containerId);
  };

  const fetchDataAndStartPendingPollings = useCallback(async () => {
    void dispatch(localReportsActions.setLocalFileReport(null));
    const shouldUseNewRoute = hasAccess(flags.tpUiReportsNewDomicileRoute, container?.containerId);
    const instances = shouldUseNewRoute
      ? unwrapResult(
          await dispatch(
            fetchReportInstancesForJurisdictionNew({
              jurisdictionId,
              containerId: container?.containerId
            })
          )
        )
      : unwrapResult(
          await dispatch(
            fetchReportInstancesForJurisdiction({
              jurisdictionId,
              isActive: true,
              containerId: container?.containerId
            })
          )
        );

    instances.forEach((instance) => {
      const status = ReportVersionStatus.ById[instance.status?.internalLocalfileReportInstanceStatusId].Name;

      if (status === 'Converting' || status === 'Pending') {
        pollAndUpdate(
          instance.name,
          instance.internalLocalfileReportId,
          instance.internalLocalfileReportInstanceId,
          instance.internalLocalfileReportInstanceId,
          container?.containerId
        );
      }
    });
  }, [dispatch, flags.tpUiReportsNewDomicileRoute, container?.containerId, jurisdictionId, pollAndUpdate]);

  useEffect(() => {
    void fetchDataAndStartPendingPollings();
  }, [dispatch, fetchDataAndStartPendingPollings]);

  return (
    <>
      <CustomAlert
        open={alertState.open}
        message={alertState.message}
        handleClose={() => {
          setAlertState((prev) => ({ ...prev, open: false }));
        }}
        severity="error"
        TransitionComponent={SlideTransition}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        autoHideDuration={3000}
      />
      <Component
        localReports={localFileReports}
        country={country}
        fetchDataForReportTiles={fetchDataAndStartPendingPollings}
        quickGenerate={quickGenerate}
        onUpdateReportStatus={onUpdateReportStatus}
        onCreateLocalReportClick={onCreateLocalReportOpen}
      />
      {showLocalReportCreationModal && (
        <LocalReportCreationModal
          title={t('reports:label-create-local-report')}
          jurisdictionId={jurisdictionId}
          jurisdictionName={country?.name}
          onSubmit={onCreateLocalReportSubmit}
          onClose={onCreateLocalReportClose}
        />
      )}
    </>
  );
};

export default Connector;
