import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import {
  Table as MUITable,
  TableBody,
  TableCell,
  // TableHead,
  TableRow,
  makeStyles,
  useTheme,
  Box,
  TableCellProps as MaterialUiTableCellProps,
  Typography,
  Chip,
  TableHead
} from '@material-ui/core';
import tokens from '../../../../../styles/designTokens';
import { captionOverline } from '../../../../../styles/typography';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 0 4px 0 rgba(0,0,0,0.12)',
    borderRadius: '4px'
  },
  tableContainer: {
    height: '100%',
    paddingLeft: '67.5px',
    paddingRight: '67.5px',
    paddingBottom: '50px'
  },
  tableFullWidthContainer: {
    padding: '1rem'
  },
  table: {
    '& .MuiTableCell-stickyHeader': {
      left: 'initial',
      zIndex: 1
    },
    '& .MuiTableCell-root': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
      whiteSpace: 'nowrap',

      '&.MuiTableCell-head': {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightRegular,
        whiteSpace: 'normal'
      },

      '&.MuiTableCell-body': {
        height: 45,
        padding: theme.spacing(0, 1),

        '&:first-child': {
          fontWeight: theme.typography.fontWeightMedium
        },

        '& .MuiIconButton-root': {
          color: theme.palette.secondary.dark,
          padding: theme.spacing(0.5)
        }
      }
    },
    overflowX: 'auto'
  },
  selectedRow: {
    '& .MuiTableCell-root': {
      backgroundColor: tokens.product15,
      '&:first-child': {
        height: '1.25rem',
        color: tokens.product100
      }
    }
  },
  header: {
    paddingLeft: '24px',
    height: '62px',
    display: 'flex',
    alignItems: 'center',
    border: 'solid #E2E3E6',
    borderWidth: '0px 0px 1px 0px'
  },
  accordionOpen: {
    height: 'fit-content',
    width: '100%'
  },
  accordionClose: {
    height: '0px',
    overflow: 'hidden',
    width: '100%'
  },
  subTableContainer: {
    width: '100%',
    height: 'fit-content',
    paddingLeft: '40px'
  },
  subHeader: {
    paddingLeft: '15px',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    border: 'solid',
    borderColor: tokens.neutral90,
    borderWidth: '0px 0px 1px 0px'
  },
  cellContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  UPE: {
    '& .MuiChip-root': {
      borderRadius: '4px',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product25,
      marginLeft: '0.5rem'
    }
  }
}));

interface AccordionProps {
  children: ReactNode;
  open: boolean;
}

interface SelectedTableProps {
  columns: any[];
  rows: any[];
  title: any;
  isSubTable?: boolean;
  subTableTitle?: string;
  subTableColumns?: any[];
  subTableRows?: any[];
  subTableSwitch?: boolean;
  fullWidth?: boolean;
}

export const SelectTable = ({
  rows,
  title,
  columns,
  isSubTable,
  subTableRows,
  subTableTitle,
  subTableSwitch,
  subTableColumns,
  fullWidth
}: SelectedTableProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const renderColgroup = useCallback(
    (subColumns?: any[]): ReactElement => {
      const cols: ReactElement[] = [];
      (subColumns ? subColumns : columns).forEach(({ width }, i) => {
        const colStyle = width ? { minWidth: width, width } : {};
        // eslint-disable-next-line react/no-array-index-key
        cols.push(<col key={String(i)} style={colStyle} />);
      });

      return <colgroup>{cols}</colgroup>;
    },
    [columns]
  );

  const isHeader = useMemo(() => {
    let headerCell = false;
    columns.forEach((col) => {
      if (col.header && col.header !== '') {
        headerCell = true;
      }
    });
    return headerCell;
  }, [columns]);

  const renderCells = useCallback(
    (row?: any): ReactElement[] => {
      const cells: ReactElement[] = [];
      columns.forEach(({ key, header, align, width }, i) => {
        cells.push(
          <TableCell
            // eslint-disable-next-line react/no-array-index-key
            key={String(i)}
            style={{ minWidth: width, width }}
            component="div"
            align={align as MaterialUiTableCellProps['align']}
          >
            <Box className={classes.cellContainer}>
              {row ? row[key] : header}
              {key === 'description' && row?.isUPE && (
                <Box className={classes.UPE}>
                  <Chip size="small" label="UPE" />
                </Box>
              )}
            </Box>
          </TableCell>
        );
      });

      return cells;
    },
    [columns, classes]
  );

  const Accordion = ({ children, open }: AccordionProps) => (
    <div className={classes[open ? 'accordionOpen' : 'accordionClose']}>{children}</div>
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>{title}</Box>
      <Box className={`${classes.tableContainer} ${fullWidth ? classes.tableFullWidthContainer : ''}`}>
        <MUITable className={classes.table}>
          {renderColgroup}

          {isHeader && (
            <TableHead>
              <TableRow>{renderCells()}</TableRow>
            </TableHead>
          )}

          <TableBody>
            {rows.map((row) => (
              <TableRow key={`${Math.random()}`} className={row.selected && classes.selectedRow}>
                {renderCells(row)}
              </TableRow>
            ))}
          </TableBody>
        </MUITable>

        {isSubTable && (
          <Accordion open={subTableSwitch!}>
            <Box className={classes.subTableContainer}>
              <Box className={classes.subHeader}>
                <Typography> {subTableTitle} </Typography>
              </Box>
              <MUITable className={classes.table}>
                {renderColgroup(subTableColumns)}
                <TableBody>
                  {subTableRows!.map((row) => (
                    <TableRow key={`${Math.random()}`} className={row.selected && classes.selectedRow}>
                      {renderCells(row)}
                    </TableRow>
                  ))}
                </TableBody>
              </MUITable>
            </Box>
          </Accordion>
        )}
      </Box>
    </Box>
  );
};
