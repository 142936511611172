import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchComplianceCentralCounter,
  fetchComplianceCentralReportsInfo,
  fetchComplianceCentralMasterFileReportsInfo
} from './thunks/complianceCentral';
import { ComplianceCentralCounter, ReportsInfo, MasterFileReportsInfo } from '../../models';
import { getWorkingContainer } from '../baseData/thunks/baseData';

interface ComplianceCentralState {
  counters: ComplianceCentralCounter;
  reportsInfo: ReportsInfo[] | null;
  masterFileReportsInfo: MasterFileReportsInfo[] | null;
  error?: string;
}

const initialState: ComplianceCentralState = {
  counters: { countriesCount: 0, totalReportsCount: 0, finalReportsCount: 0, pendingReportsCount: 0 },
  reportsInfo: null,
  masterFileReportsInfo: null
};

const complianceCentralSlice = createSlice({
  name: 'complianceCentral',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchComplianceCentralCounter.fulfilled, (state, action: PayloadAction<ComplianceCentralCounter>) => {
        state.counters = action.payload;
      })
      .addCase(fetchComplianceCentralReportsInfo.fulfilled, (state, action: PayloadAction<ReportsInfo[]>) => {
        state.reportsInfo = action.payload;
      })
      .addCase(
        fetchComplianceCentralMasterFileReportsInfo.fulfilled,
        (state, action: PayloadAction<MasterFileReportsInfo[]>) => {
          state.masterFileReportsInfo = action.payload;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^complianceCentral\/.+\/pending$/),
        (state) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^complianceCentral\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default complianceCentralSlice.reducer;
