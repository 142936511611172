import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { Chip } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EntitySectionArea } from './EntitySectionArea';
import { ReportSectionArea } from './ReportSectionArea';
import { ReportSummaryProps } from './ReportSummary.proptype';
import { TransactionSectionArea } from './TransactionSectionArea';
import { useWorkingContainer } from '../../../../../hooks/container/useWorkingContainer';
import tokens from '../../../../../styles/designTokens';
import { body4, captionOverline, title2 } from '../../../../../styles/typography';
import { hasAccess } from '../../../../../utils';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '2.1875rem 0'
  },
  chipCustomized: {
    '& .MuiChip-root': {
      borderRadius: '4px',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product25,
      padding: '0 0.5rem'
    }
  },
  reportTitle: {
    ...title2,
    color: tokens.core3,
    marginBottom: 0
  },
  reportSubTitle: {
    ...body4,
    color: tokens.core3,
    marginTop: 1
  }
}));

export const ReportSummary = ({
  upe,
  jurisdictionName,
  reportTitle,
  hasCustomization,
  selectedTransactions,
  selectedEntities,
  selectedTradingPartners,
  selectedLegalEntities,
  controlledTransactionSelection
}: ReportSummaryProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const flags = useFlags();
  const container = useWorkingContainer();
  const hasAccessToReportSectionsConfig = hasAccess(
    flags.tpiqReportsUiConfigTabSummaryPanelSectionsContainerProgressive,
    container?.containerId
  );
  const hasAccessToAppendixSection = hasAccess(flags.tpiqReportGeneratorCustomAppendices, container?.containerId);

  return (
    <Box>
      {flags.tpiqReportsUiConfigTabSummaryHeader && (
        <Box className={classes.header}>
          <Box>
            <h1 className={classes.reportTitle}>{reportTitle}</h1>
            <h2 className={classes.reportSubTitle}>
              {t('reports:label-local-report-creation-modal-configuration-report-summary-tpiq-doc')}
            </h2>
          </Box>
          {hasCustomization && (
            <Box className={classes.chipCustomized}>
              <Chip
                label={t('reports:label-local-report-creation-modal-configuration-report-summary-customized')}
                size="small"
              />
            </Box>
          )}
        </Box>
      )}

      {(hasAccessToReportSectionsConfig || hasAccessToAppendixSection) && (
        <ReportSectionArea jurisdictionName={jurisdictionName} />
      )}
      <EntitySectionArea
        upe={upe}
        selectedEntities={selectedEntities}
        selectedLegalEntities={selectedLegalEntities}
        selectedTradingPartners={selectedTradingPartners}
      />

      <TransactionSectionArea
        selectedTransactions={selectedTransactions}
        controlledTransactionSelection={controlledTransactionSelection}
      />
    </Box>
  );
};
