import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, List, makeStyles } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { ReportVersionStatus } from '@xbs/xbs-enums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DeleteReportConfirmation } from './DeleteReportConfirmation';
import { ReportTile } from './ReportTile';
import { useWorkingContainer } from '../../../hooks/container/useWorkingContainer';
import { useCountries } from '../../../hooks/country/useCountries';
import { useLanguages } from '../../../hooks/languages/useLanguages';
import { useLocalReportsWordPolling } from '../../../hooks/localfile-report/useLocalReportsConversionPolling';
import { useLocalReportsPolling } from '../../../hooks/localfile-report/useLocalReportsPolling';
import { useUPECurrency } from '../../../hooks/upe/useUPECurrency';
import { LocalReportData } from '../../../models';
import { FinancialDataValueEnteredInEnum } from '../../../models/financial.interface';
import { actions as localReportsActions } from '../../../redux/localReports';
import { ConvertToWordPayload } from '../../../redux/localReports/localReports.proptype';
import { fetchLanguages } from '../../../redux/localReports/thunks/languages';
import {
  convertReportToWord,
  downloadReportInstance,
  fetchReportInstancesForJurisdiction,
  fetchReportInstancesForJurisdictionNew
} from '../../../redux/localReports/thunks/localReports';
import { deleteReport, updateReportActiveVersion } from '../../../redux/localReports/thunks/reportVersions';
import { getSignedUrlForUploadedReport, uploadReportToSignedUrl } from '../../../redux/localReports/thunks/upload';
import { sortData } from '../../../services/filtering';
import { AppDispatch } from '../../../store';
import { getAppConfig, hasAccess } from '../../../utils';
import { LocalReportCreateNewVersion } from '../../LocalReportCreateVersionModal';
import { UploadReportModal } from '../../LocalReportsActions/UploadReportModal/UploadReportModal';
import { UploadReportPayloadProps } from '../../LocalReportsActions/UploadReportModal/UploadReportModal.proptype';
import { getReportName } from '../../Reports/ReportsUtilities';
import { ReportInstanceDownloadProp, ReportStatus, streamlinerURLKey } from '../LocalReports.proptype';

interface LocalReportsListProps {
  reportsData: LocalReportData[];
  onManageVersions: (version: number) => void;
  quickGenerate: (report: LocalReportData) => void;
  onUpdateReportStatus: (status: string, report: LocalReportData) => void;
}

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'auto',
    padding: '2.25rem 2.5625rem 2.25rem 2.1875rem',
    width: '100%',
    '& .MuiListItem-root': {
      '&:first-child': {
        padding: 0
      },
      paddingBottom: 0,
      paddingTop: '0.75rem'
    }
  }
}));

export const LocalReportsList = ({
  reportsData,
  onManageVersions,
  quickGenerate,
  onUpdateReportStatus
}: LocalReportsListProps) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const flags = useFlags();
  const { xbsEnvironmentShort } = getAppConfig();
  const { pollAndUpdate } = useLocalReportsPolling();
  const { pollWordAndUpdate } = useLocalReportsWordPolling();
  const [isDeleteReportModalOpen, setIsDeleteReportModalOpen] = useState(false);
  const [isUploadReportModalOpen, setIsUploadReportModalOpen] = useState(false);
  const [isCreateReportVersionModalOpen, setIsCreateReportVersionModalOpen] = useState(false);
  const [isUploadingReport, setIsUploadingReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState<
    LocalReportData | { name: string; internalLocalfileReportId: number }
  >({ name: '', internalLocalfileReportId: -1 });
  const container = useWorkingContainer();
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  const countries = useCountries();
  const jurisdictionLanguages = useLanguages();
  const upeCurrency = useUPECurrency();
  const defaultCurrency = countries.find((country) => country.countryId === jurisdictionId)?.defaultCurrency;
  const jurisdictionName = countries.find((country) => country.countryId === jurisdictionId)?.name;

  const streamlinerURLs = {
    editorEnv: `https://tp-ui.${xbsEnvironmentShort}xbs.com/report-editor`,
    editorHostname: `https://${window.location.hostname}/report-editor`,
    reviewHostname: `https://${window.location.hostname}/report-review`
  };

  const currencyOptions = [
    { id: FinancialDataValueEnteredInEnum.MNE, name: FinancialDataValueEnteredInEnum[1] },
    { id: FinancialDataValueEnteredInEnum.Entity, name: FinancialDataValueEnteredInEnum[2] }
  ];

  useEffect(() => {
    void dispatch(fetchLanguages({ jurisdictionId, taxYear: container?.taxYear! }));
  }, [container?.taxYear, dispatch, jurisdictionId]);

  const startDeleteReport = (report: LocalReportData) => {
    setIsDeleteReportModalOpen(true);
    setSelectedReport(report);
  };

  const openReportModal = (report: LocalReportData) => {
    setIsUploadReportModalOpen(true);
    setSelectedReport(report);
  };

  const onNewReportVersion = (report: LocalReportData) => {
    setIsCreateReportVersionModalOpen(true);
    setSelectedReport(report);
  };

  const executeDeleteReport = async () => {
    const shouldUseNewRoute = hasAccess(flags.tpUiReportsNewDomicileRoute, container?.containerId);

    await dispatch(deleteReport({ reportId: selectedReport.internalLocalfileReportId }));
    await (shouldUseNewRoute
      ? dispatch(
          fetchReportInstancesForJurisdictionNew({
            jurisdictionId,
            containerId: container?.containerId
          })
        )
      : dispatch(
          fetchReportInstancesForJurisdiction({
            jurisdictionId,
            isActive: true,
            containerId: container?.containerId
          })
        ));

    setSelectedReport({ name: '', internalLocalfileReportId: -1 });
  };

  const executeUploadReport = async (
    uploadReportPayload: UploadReportPayloadProps,
    reportFile: File,
    report: LocalReportData
  ) => {
    setIsUploadingReport(true);
    const uploadedReportInfo = unwrapResult(await dispatch(getSignedUrlForUploadedReport(uploadReportPayload)));
    const internalLocalfileReportInstanceId = uploadedReportInfo?.data?.internalLocalfileReportInstanceId;
    const awsSignedUrlPath = uploadedReportInfo?.data?.url;
    void dispatch(uploadReportToSignedUrl({ awsSignedUrlPath, reportFile }));

    dispatch(
      localReportsActions.replaceLocalFileReportInstanceData({
        previousInstanceId: report.internalLocalfileReportInstanceId,
        instanceData: {
          ...report,
          isUploadedReport: 1,
          status: {
            internalLocalfileReportInstanceStatusId: ReportVersionStatus.ByName.Pending.Id,
            name: ReportVersionStatus.ByName.Pending.Name
          },
          createdAt: new Date().toString()
        }
      })
    );

    pollAndUpdate(
      report.name,
      Number(report.internalLocalfileReportId),
      Number(internalLocalfileReportInstanceId),
      Number(report.internalLocalfileReportInstanceId),
      container?.containerId
    );

    void dispatch(
      updateReportActiveVersion({
        reportId: report.internalLocalfileReportId,
        internalLocalfileReportInstanceId
      })
    );
    setIsUploadingReport(false);
    setIsUploadReportModalOpen(false);
  };

  const onDownloadPDF = useCallback(
    (reportToDownload: LocalReportData) => {
      if (reportToDownload && container) {
        const payload = {
          filePath: reportToDownload.binaryPath,
          name: getReportName({
            reportName: reportToDownload.name,
            reportTemplateId: reportToDownload.templateId,
            version: reportToDownload.version
          }),
          containerId: container.containerId,
          container
        };

        void dispatch(downloadReportInstance(payload));
      }
    },
    [container, dispatch]
  );

  const onDownloadWord = useCallback(
    (reportToDownload: LocalReportData) => {
      if (reportToDownload && container) {
        const payload: ReportInstanceDownloadProp = {
          filePath: reportToDownload.wordReportBinaryPath,
          name: getReportName({
            reportName: reportToDownload.name,
            reportTemplateId: reportToDownload.templateId,
            version: reportToDownload.version
          }),
          containerId: container.containerId,
          container,
          wordBucket: true
        };

        void dispatch(downloadReportInstance(payload));
      }
    },
    [container, dispatch]
  );

  const onConvertToWord = useCallback(
    async (report: LocalReportData) => {
      if (report && container) {
        const payload: ConvertToWordPayload = {
          containerId: container.containerId,
          internalLocalfileReportId: report.internalLocalfileReportId,
          internalLocalfileReportInstanceId: report.internalLocalfileReportInstanceId
        };
        await dispatch(convertReportToWord(payload));
        dispatch(
          localReportsActions.replaceLocalFileReportInstanceData({
            previousInstanceId: report.internalLocalfileReportInstanceId,
            instanceData: {
              ...report,
              isUploadedReport: 0,
              status: {
                internalLocalfileReportInstanceStatusId: ReportVersionStatus.ByName.Converting.Id,
                name: ReportVersionStatus.ByName.Converting.Name
              },
              createdAt: new Date().toString()
            }
          })
        );

        pollWordAndUpdate(
          report.name,
          Number(report.internalLocalfileReportId),
          Number(report.internalLocalfileReportInstanceId),
          Number(report.internalLocalfileReportInstanceId)
        );
      }
    },
    [container, dispatch, pollWordAndUpdate]
  );

  const rows = useMemo(() => {
    const sortLocalReports = sortData(reportsData, { sortBy: 'createdAt', sortOrder: 'desc' });
    return sortLocalReports.map((report) => {
      return (
        <ReportTile
          key={report.internalLocalfileReportInstanceId}
          status={report.reportStatus ?? 'draft'}
          errorType={report.errorType}
          wordStatus={report.wordReportStatusId ?? 0}
          wordReportBinaryPath={report.wordReportBinaryPath}
          emailStatus={report.emailStatus}
          name={report.name}
          primaryEntities={report.primaryEntities}
          tradingPartners={report.tradingPartners}
          transactions={report.reportTransactions}
          version={report.version}
          language={t(`languageIsoInfo:${report.languageIso}`)}
          date={report.createdAt}
          generationStatus={report.status?.internalLocalfileReportInstanceStatusId}
          isUploaded={Boolean(report.isUploadedReport)}
          quickGenerate={() => {
            quickGenerate(report);
          }}
          jurisdictionName={jurisdictionName}
          containerYear={container?.taxYear}
          internalLocalfileReportInstanceId={report.internalLocalfileReportInstanceId}
          internalLocalfileReportId={report.internalLocalfileReportId}
          openReportPreview={() => {
            const streamlinerURL = `${
              streamlinerURLs[flags.tpiqReportsUiAccessStreamlinerPreview as streamlinerURLKey]
            }/${report.internalLocalfileReportId}`;
            window.open(streamlinerURL, '_blank');
          }}
          startDeleteReport={() => {
            startDeleteReport(report);
          }}
          openReportModal={() => {
            openReportModal(report);
          }}
          onDownloadPDF={() => {
            onDownloadPDF(report);
          }}
          onConvertWord={() => {
            void onConvertToWord(report);
          }}
          onDownloadWord={() => {
            onDownloadWord(report);
          }}
          onManageVersions={() => {
            onManageVersions(report.internalLocalfileReportId);
          }}
          onNewReportVersion={() => {
            onNewReportVersion(report);
          }}
          onUpdateReportStatus={(newReportStatus: ReportStatus) => {
            onUpdateReportStatus(newReportStatus, report);
          }}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsData]);

  const languageList = useMemo(() => {
    return jurisdictionLanguages
      .map((lang) => ({
        ...lang,
        name: t(`languageIsoInfo:${lang.isoCode}`)
      }))
      .sort((lang1, lang2) => {
        return lang1.name > lang2.name ? 1 : -1;
      });
  }, [jurisdictionLanguages, t]);

  return (
    <Box className={classes.container}>
      <List disablePadding>{rows}</List>
      <DeleteReportConfirmation
        open={isDeleteReportModalOpen}
        nameReport={selectedReport.name}
        setIsConfirmationOpen={setIsDeleteReportModalOpen}
        executeDeleteReport={executeDeleteReport}
      />
      <UploadReportModal
        open={isUploadReportModalOpen}
        currencyOptions={currencyOptions}
        languageOptions={languageList}
        executeUploadReport={executeUploadReport}
        setIsUploadReportModalOpen={setIsUploadReportModalOpen}
        reportInfo={selectedReport}
        container={container!}
        upeCurrency={upeCurrency!}
        defaultCurrency={defaultCurrency!}
        isUploadingReport={isUploadingReport}
      />
      {isCreateReportVersionModalOpen ? (
        <LocalReportCreateNewVersion
          reportInfo={selectedReport as LocalReportData}
          jurisdictionId={jurisdictionId}
          jurisdictionName={jurisdictionName ?? ''}
          isOpen={isCreateReportVersionModalOpen}
          onClose={() => {
            setIsCreateReportVersionModalOpen(false);
          }}
        />
      ) : null}
    </Box>
  );
};
