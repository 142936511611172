import BasisGrotesqueBoldPro from './BasisGrotesquePro-Bold.woff';
import BasisGrotesqueProMedium from './BasisGrotesquePro-Medium.woff';
import BasisGrotesquePro from './BasisGrotesquePro-Regular.woff';

const fontWeight = 400;

const isRebrand = localStorage.getItem('isRebrand') === 'true';

export const bodyFont = isRebrand
  ? {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight
    }
  : {
      fontFamily: 'Basis Grotesque Pro',
      fontStyle: 'normal',
      fontWeight,
      src: `
    local('Basis Grotesque Pro'),
    url(${BasisGrotesquePro as string})
    format('woff')
  `
    };

export const titleFont = isRebrand
  ? {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 700
    }
  : {
      fontFamily: 'Basis Grotesque Pro Bold',
      fontStyle: 'normal',
      fontWeight,
      src: `
    local('Basis Grotesque Pro Bold'),
    url(${BasisGrotesqueBoldPro as string})
    format('woff')
  `
    };

export const subtitleFont = bodyFont;

export const mediumFont = isRebrand
  ? {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight
    }
  : {
      fontFamily: 'Basis Grotesque Pro Medium',
      fontStyle: 'normal',
      fontWeight,
      src: `
    local('Basis Grotesque Pro Medium'),
    url(${BasisGrotesqueProMedium as string})
    format('woff')
  `
    };

export const regularFont = isRebrand
  ? {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight
    }
  : {
      fontFamily: 'Basis Grotesque Pro',
      fontStyle: 'normal',
      fontWeight,
      src: `
    local('Basis Grotesque Pro'),
    url(${BasisGrotesquePro as string})
    format('woff')
  `
    };
