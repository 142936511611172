import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  makeStyles,
  Table as MUITable,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  LinearProgress
} from '@material-ui/core';
import { CustomAppendixFile, AppendixFileState } from '../../../../../../models/reports.interface';
import tokens from '../../../../../../styles/designTokens';
import { body1, body2, captionOverline } from '../../../../../../styles/typography';
import { DeleteIcon, ErrorUploadSvg } from '../Appendix.icons';
import { FileItemProps, FileListProps } from '../Appendix.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '1.8rem',
    marginBottom: '1.25rem',

    '& .MuiTableRow-root': {
      cursor: 'default',
      '&:hover .MuiTableCell-root': {
        backgroundColor: tokens.neutral60
      },
      '&:hover .MuiTableCell-alignLeft': {
        backgroundColor: theme.palette.common.white
      },
      '& .MuiTableCell-alignLeft': {
        border: 'none'
      }
    },

    '& .MuiTableCell-root': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
      whiteSpace: 'nowrap',

      '& .MuiTableCell-body': {
        height: 45,
        padding: theme.spacing(0, 1),

        '&:first-child': {
          fontWeight: theme.typography.fontWeightMedium
        }
      }
    },
    overflowX: 'auto'
  },
  fileName: {
    ...body2,
    color: tokens.core2
  },
  fileDate: {
    ...body1,
    color: tokens.core2
  },
  indexCol: { width: '5%' },
  deleteCol: { width: '5%' },
  fileNameCol: { width: '60%', marginLeft: '1rem' },
  uploadedCol: { width: '30%', textAlign: 'center' },
  headerTable: {
    display: 'flex',
    border: 'solid',
    borderWidth: '0px 0px 1px 0px',
    borderColor: tokens.neutral90,
    paddingBottom: '0.6rem',
    paddingLeft: '1.6rem'
  },
  fileIndex: {
    backgroundColor: tokens.neutral70,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    color: tokens.core1,
    ...captionOverline,
    height: '1.75rem',
    width: '1.75rem',
    display: 'flex'
  },
  table: {
    padding: '0rem 2rem'
  },
  deleteBtn: {
    width: '0.5rem',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingWrapper: {
    width: '100%'
  },
  boxError: {
    ...captionOverline,
    backgroundColor: tokens.error15,
    color: tokens.error100,
    borderRadius: '4px',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      paddingLeft: '0.5rem'
    }
  }
}));

const FileItem = ({ file, emitDeleteFile }: FileItemProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [hoverRows, setHoverRow] = useState<number>(Number.NaN);

  const onHover = () => {
    setHoverRow(file.order);
  };

  const onMouseLeave = () => {
    setHoverRow(Number.NaN);
  };

  const deleteFile = useCallback(
    (file: CustomAppendixFile) => {
      emitDeleteFile(file);
    },
    [emitDeleteFile]
  );

  const formatFileName = useCallback((fileName: string) => {
    const lettersArray = fileName?.split('');
    if (lettersArray?.length > 55) {
      lettersArray.length = 55;
      lettersArray.push('...');
      return lettersArray.join('');
    }

    return fileName;
  }, []);

  const getDate = useCallback((uploadedTime: Date) => {
    const date = uploadedTime.toUTCString().split(' ');
    return `${date[1]} ${date[2]} ${date[3]}`;
  }, []);

  const getTime = useCallback((uploadedTime: Date) => {
    const strTime = uploadedTime.toLocaleTimeString(undefined, { hourCycle: 'h12' });
    return strTime;
  }, []);

  const formatDate = useCallback(
    (uploadedTime: Date) => {
      return `${getDate(uploadedTime)} ${getTime(uploadedTime)}`;
    },
    [getDate, getTime]
  );

  return (
    <TableRow onMouseEnter={onHover} onMouseMove={onHover} onMouseLeave={onMouseLeave}>
      <TableCell align="left">
        <Box className={classes.fileIndex}>{file.order}</Box>
      </TableCell>
      <TableCell>
        <Typography className={classes.fileName}>{formatFileName(file.name)}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.fileDate} align="right">
          {file.state === AppendixFileState.CREATED && (
            <Box className={classes.loadingWrapper}>
              <LinearProgress />
            </Box>
          )}
          {file.state === AppendixFileState.UPLOADING && (
            <Box className={classes.loadingWrapper}>
              <LinearProgress />
            </Box>
          )}
          {file.state === AppendixFileState.UPLOADED && formatDate(new Date(file.uploaded))}
          {file.state === AppendixFileState.FAILED && (
            <Box className={classes.boxError}>
              <ErrorUploadSvg />
              <span>FAILURE UPLOAD</span>
            </Box>
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Box className={classes.deleteBtn}>
          {hoverRows === file.order && (
            <Box
              data-testid="delete-button"
              onClick={() => {
                deleteFile(file);
              }}
            >
              <DeleteIcon />
            </Box>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export const FileList = ({ files, emitDeleteFile }: FileListProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box className={classes.headerTable}>
        <Typography className={classes.fileNameCol}>
          {t('reports:configurationTab-Appendix-AppendixFileTable-FileList-fileCol')}
        </Typography>
        <Typography className={classes.uploadedCol}>
          {t('reports:configurationTab-Appendix-AppendixFileTable-FileList-uploadedCol')}
        </Typography>
      </Box>
      <Box className={classes.table}>
        <MUITable>
          <colgroup>
            <col className={classes.indexCol} />
            <col className={classes.fileNameCol} />
            <col className={classes.uploadedCol} />
            <col className={classes.deleteCol} />
          </colgroup>
          <TableBody>
            {files.map((file: CustomAppendixFile) => (
              <FileItem key={`fileItem-${file.order}`} file={file} emitDeleteFile={emitDeleteFile} />
            ))}
          </TableBody>
        </MUITable>
      </Box>
    </Box>
  );
};
