import { createAsyncThunk } from '@reduxjs/toolkit';
import decodeJWT from 'jwt-decode';
import { Container, Country, Currency, Entity, Language, PrimaryFunction } from '../../../models';
import httpService from '../../../services/http';

interface JWTToken {
  // eslint-disable-next-line camelcase
  given_name: string;
  // eslint-disable-next-line camelcase
  family_name: string;
  'cognito:username': string;
}

export const fetchBaseData = createAsyncThunk<
  {
    containers: Container[];
    currencies: Currency[];
    countries: Country[];
    primaryFunctions: PrimaryFunction[];
    languages: Language[];
    upe: Entity;
  },
  void,
  { rejectValue: Error }
>('baseData/fetch', async (_, { rejectWithValue }) => {
  try {
    const [
      { data: containersResponse },
      { data: countriesResponse },
      { data: upeResponse },
      { data: currenciesResponse },
      { data: primaryFunctionResponse }
    ] = await Promise.all([
      httpService.request<{ data: Container[] }>({
        method: 'get',
        apiUrlKey: 'principleUrl',
        relativePath: 'container'
      }),
      httpService.request<{ data: Country[] }>({ method: 'get', apiUrlKey: 'baseUrl', relativePath: 'countries' }),
      httpService.request<{ data: Entity }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: 'containers/workingContainer'
      }),
      httpService.request<{ data: Currency[] }>({ method: 'get', apiUrlKey: 'baseUrl', relativePath: 'currencies' }),
      httpService.request<{ data: PrimaryFunction[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: 'primary-functions'
      })
    ]);

    // TODO: Remove this once legacy UI is deprecated
    const token = localStorage.getItem('idToken');
    if (token) {
      const decodedToken = decodeJWT(token);

      const user = {
        firstName: (decodedToken as JWTToken).given_name || 'User',
        lastName: (decodedToken as JWTToken).family_name || '',
        username: (decodedToken as JWTToken)['cognito:username']
      };

      localStorage.setItem('user', JSON.stringify(user));
    }

    // TODO: Remove this once legacy UI is deprecated
    localStorage.setItem('workingContainer', JSON.stringify(upeResponse?.data?.container));
    localStorage.setItem('countries', JSON.stringify(countriesResponse?.data));
    localStorage.setItem('containerUpeId', JSON.stringify(upeResponse?.data?.entityId || 0));
    localStorage.setItem('containerUpeCurrency', JSON.stringify(upeResponse?.data?.upeCurrency));
    localStorage.setItem('containerUpeCurrencyScale', JSON.stringify(upeResponse?.data?.upeCurrencyScale || 0));
    localStorage.setItem(
      'containersList',
      JSON.stringify(
        containersResponse?.data.map((container) => ({
          ...container,
          displayName: `${container.name} ${container.taxYear}`
        }))
      )
    );
    localStorage.setItem('workspaceChoice', 'createDocumentation');

    return {
      containers: containersResponse?.data ?? [],
      countries: countriesResponse?.data ?? [],
      currencies: currenciesResponse?.data ?? [],
      primaryFunctions: primaryFunctionResponse?.data ?? [],
      // in languages missing service to gel all supported languages
      languages: [
        {
          languageId: 1,
          name: 'English - United States',
          nativeName: 'English',
          localizationCode: 'EN-US',
          dateFormat: ''
        },
        {
          languageId: 2,
          name: 'Spanish - Colombia',
          nativeName: 'Spanish',
          localizationCode: 'ES-CO',
          dateFormat: ''
        }
      ],
      // in case the current container has no UPE, we receive an object with 'container' property only.
      upe: upeResponse?.data ?? {}
    };
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const getWorkingContainer = createAsyncThunk<Entity, void, { rejectValue: Error }>(
  'baseData/getWorkingContainer',
  async (_, { rejectWithValue }) => {
    try {
      // in case the current container has no UPE, we receive an object with 'container' property only.
      return (
        (
          await httpService.request<{ data: Entity }>({
            method: 'get',
            apiUrlKey: 'baseUrl',
            relativePath: 'containers/workingContainer'
          })
        ).data.data ?? {}
      );
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);
