import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ConnectorProps } from './LocalReportCreationModal.proptypes';
import { useConfigurationTabEnabled } from '../../hooks/configuration-tab/useConfigurationTabEnabled';
import { useWorkingContainer } from '../../hooks/container/useWorkingContainer';
import { useLanguages } from '../../hooks/languages/useLanguages';
import { usePrimaryEntitiesList } from '../../hooks/primary-entities/usePrimaryEntitiesList';
import { useSelectedPrimaryEntities } from '../../hooks/primary-entities/useSelectedPrimaryEntities';
import { useSetupData } from '../../hooks/setup-data/useSetupData';
import { useSummariesData } from '../../hooks/summaries-data/useSummariesData';
import { useTemplateList } from '../../hooks/template-report/useTemplateList';
import {
  useSelectedPrimaryTradingPartnersMap,
  useSelectedPrimaryTradingPartnersMapIsCreating
} from '../../hooks/trading-partners/useSelectedPrimaryTradingPartnersMap';
import { useSelectedTradingPartners } from '../../hooks/trading-partners/useSelectedTradingPartners';
import { useSelectedTransactions } from '../../hooks/transaction/useSelectedTransactions';
import { useTransactionsList } from '../../hooks/transaction/useTransactionsList';
import { fetchLanguages } from '../../redux/localReports/thunks/languages';
import { createPrimaryTradingPartnersMap, fetchTemplates } from '../../redux/localReports/thunks/localReports';
import { fetchTransactions } from '../../redux/transactions/thunks/transactions';
import { hasAccess } from '../../utils';

const Connector = ({
  title,
  jurisdictionId,
  jurisdictionName,
  component: Component,
  onClose,
  onSubmit
}: ConnectorProps) => {
  const flags = useFlags();
  const dispatch = useDispatch();
  const transactions = useTransactionsList();
  const primaryTradingPartnersMap = useSelectedPrimaryTradingPartnersMap();
  const selectedPrimaryTradingPartnersMapIsCreating = useSelectedPrimaryTradingPartnersMapIsCreating();
  const setupData = useSetupData();
  const summariesData = useSummariesData();
  const selectedTransactions = useSelectedTransactions();
  const selectedPrimaryEntities = useSelectedPrimaryEntities();
  const selectedTradingPartners = useSelectedTradingPartners();

  const container = useWorkingContainer();
  const primaryEntities = usePrimaryEntitiesList();
  const templates = useTemplateList();
  const languages = useLanguages();
  const isConfigurationTabEnabled = useConfigurationTabEnabled();

  const hasAccessToReportSectionsConfig = hasAccess(
    flags.tpiqReportsUiConfigTabSummaryPanelSectionsContainerProgressive,
    container?.containerId
  );

  useEffect(() => {
    if (!transactions) {
      dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    if (transactions && primaryTradingPartnersMap === null && !selectedPrimaryTradingPartnersMapIsCreating) {
      dispatch(createPrimaryTradingPartnersMap(jurisdictionId));
    }
  }, [dispatch, jurisdictionId, transactions, primaryTradingPartnersMap, selectedPrimaryTradingPartnersMapIsCreating]);

  useEffect(() => {
    if (templates.length === 0 && primaryEntities.length > 0) {
      dispatch(fetchTemplates(primaryEntities[0].entityId));
    }
  }, [dispatch, templates, primaryEntities]);

  useEffect(() => {
    if (!languages || languages.length === 0 || languages.some((l) => l.isoCode === 'reapply_call')) {
      dispatch(fetchLanguages({ jurisdictionId, taxYear: container?.taxYear! }));
    }
  }, [dispatch, jurisdictionId, languages, container]);

  const stageCompleteness = [
    setupData && setupData.reportTitle.length > 0 && setupData.currency !== '',
    selectedPrimaryEntities.length > 0 && selectedTradingPartners.length > 0,
    selectedTransactions.length > 0,
    (summariesData.conclusion?.length > 0 ?? false) && (summariesData.executive?.length > 0 ?? false),
    hasAccessToReportSectionsConfig ? isConfigurationTabEnabled : true
  ];

  return (
    <Component
      title={title}
      jurisdictionId={jurisdictionId}
      jurisdictionName={jurisdictionName}
      stageCompleteness={stageCompleteness}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default Connector;
