export interface AddToggleButtonOptions {
  icon: string;
  tooltip: string;
  onAction: () => void;
}

export interface AddMenuButtonOptions {
  icon: string;
  fetch: (callback: (items: string) => void) => void;
}

export interface EditorInstance {
  ui: {
    registry: {
      addIcon: (name: string, icon: string) => void;
      addToggleButton: (name: string, options: AddToggleButtonOptions) => void;
      addMenuButton: (name: string, options: AddMenuButtonOptions) => void;
    };
  };
}

export interface EditorSetupActionIcon {
  name: string;
  icon: string;
}

export interface EditorSetupActionToggleButton {
  name: string;
  icon: string;
  tooltip: string;
  onAction: () => void;
}

export type EditorInitConfiguration<T = Record<string, unknown>> = T & {
  width?: string;
  height?: string;
  menubar?: boolean;
  branding?: boolean;
  plugins?: string[];
  toolbar?: string;
  maximize?: boolean;
  // eslint-disable-next-line camelcase
  paste_data_images?: boolean;
};

export enum EditorTheme {
  TransferPricing = 'TransferPricing'
}

export type ThemeProp<T = Record<string, unknown>> = T & { children: React.ReactNode };

export interface EditorProps {
  value: string | undefined;
  theme: keyof typeof EditorTheme;
  id?: string;
  disabled?: boolean;
  error?: boolean;
  init?: EditorInitConfiguration;
  placeholder?: string;
  onEditorChange: (content: string | undefined) => void;
  height?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onSetupActions?: {
    icons?: EditorSetupActionIcon[];
    toggleButtons?: EditorSetupActionToggleButton[];
  };
  convertUrls?: boolean;
}
