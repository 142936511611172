import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Chip, makeStyles, Radio, Checkbox, Typography, FormControlLabel } from '@material-ui/core';
import { InternalLocalFileSofEntitiesVisibility as Selection } from '@xbs/xbs-enums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LegalEntitiesProps } from './LegalEntities.proptype';
import { CustomSwitch } from '../../../../../components';
import {
  OptionSelected,
  PrimaryEntityItem,
  TradingPartnerItem
} from '../../../../../redux/localReports/localReports.proptype';
import { selectWorkingContainer } from '../../../../../selectors';
import tokens from '../../../../../styles/designTokens';
import { body1, captionOverline, title1, title2 } from '../../../../../styles/typography';
import { logGoogleAnalyticsEvent } from '../../../../../utils/sendGoogleAnalyticsEvent';
import { SelectTable } from '../../components/SelectTable/SelectTable';
import { TableColumn } from '../../ConfigurationTab.proptype';

const useStyles = makeStyles(() => ({
  header: {
    marginTop: '46px',
    marginBottom: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerTitle: {
    color: tokens.core3,
    ...title2
  },
  headerSubTitle: {
    color: tokens.core1,
    ...body1
  },
  chartSwitchBox: {
    width: '100%',
    height: '84px',
    display: 'flex',
    padding: '20px',
    borderRadius: '4px',
    marginBottom: '24px',
    alignItems: 'center',
    backgroundColor: tokens.white,
    justifyContent: 'space-between',
    boxShadow: '0 0 4px 0 rgba(0,0,0,0.12)',
    '& .MuiSwitch-track': {
      backgroundColor: '#F4CD52'
    }
  },
  chartSwitchBoxTitle: {
    color: tokens.core3,
    ...title1
  },
  chartSwitchBoxSubTitle: {
    color: tokens.core2,
    ...body1
  },
  radioBtnStyle: {
    color: tokens.product100,
    height: '1.25rem',
    width: '1.25rem'
  },
  tableTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableTitleDescription: {
    ...title1,
    color: tokens.core3,
    marginRight: '1.25rem'
  },
  tableTitleChip: {
    backgroundColor: tokens.product15,
    ...captionOverline,
    color: tokens.product100,
    padding: 'none'
  },
  checkboxSelection: {
    height: '1.125rem',
    width: '1.125rem'
  },
  chip: {
    '& .MuiChip-root': {
      borderRadius: '0.25rem',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product25
    }
  },
  customizedChip: {
    '& .MuiChip-root': {
      borderRadius: '0.25rem',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product25,
      marginLeft: '0.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem'
    }
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  displayOrgChartBox: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  displayOrgChartSwitchLabel: {
    textTransform: 'uppercase',
    marginRight: '1.3rem',
    letterSpacing: '0.6px',
    fontSize: '12px',
    color: tokens.neutral100
  },
  displayOrgChartSwitchLabelActive: {
    textTransform: 'uppercase',
    marginRight: '1.3rem',
    letterSpacing: '0.6px',
    fontSize: '12px',
    color: tokens.product100
  }
}));

function toggleEntityFromList(entitiesList: number[], entitySelected: TradingPartnerItem | PrimaryEntityItem) {
  let isItemSelectedAlready = false;
  let elementPosition;
  for (elementPosition = 0; elementPosition < entitiesList.length; elementPosition++) {
    const entity: number = entitiesList[elementPosition];
    isItemSelectedAlready = entitySelected.entityId === entity;
    if (isItemSelectedAlready) {
      entitiesList.splice(elementPosition, 1);
      break;
    }
  }

  if (!isItemSelectedAlready) {
    entitiesList.push(entitySelected.entityId);
  }
}

export const LegalEntities = ({
  upe,
  selectedLegalEntities,
  selectedEntities,
  selectedTradingPartners,
  emitLegalEntitiesSelection,
  hasCustomization
}: LegalEntitiesProps) => {
  const classes = useStyles();
  const flags = useFlags();
  const { t } = useTranslation();
  const workingContainer = useSelector(selectWorkingContainer);

  const { optionSelected, entitiesSelected, displayOrgChart } = selectedLegalEntities;
  const openSubTable = optionSelected === Selection.ByName.Custom.Id;

  const { control, reset, getValues } = useForm({
    defaultValues: {
      displayOrgChart: selectedLegalEntities === undefined || selectedLegalEntities === null ? true : displayOrgChart
    }
  });

  const customSelectedPrimaryEntityIds: number[] = useMemo(
    () => entitiesSelected?.primaryEntityIds || [],
    [entitiesSelected]
  );
  const customSelectedPrimaryTradingPartnersIds: number[] = useMemo(
    () => entitiesSelected?.tradingPartnersIds || [],
    [entitiesSelected]
  );

  const getSelectedEntities = useMemo(() => {
    return selectedEntities.filter((entity) => entity.entityId !== upe.entityId);
  }, [upe, selectedEntities]);

  const getSelectedTradingPartners = useMemo(() => {
    return selectedTradingPartners.filter((entity) => entity.entityId !== upe.entityId);
  }, [upe, selectedTradingPartners]);

  const tableColumns: TableColumn[] = [
    { key: 'description', header: '', width: '85%' },
    { key: 'action', header: '', width: '15%' }
  ];

  const subTableColumns: TableColumn[] = [
    { key: 'description', header: '', width: '85%' },
    { key: 'action', header: '', width: '15%' }
  ];

  const emitSelectionOfUPE = useMemo(() => {
    const { showUPE } = selectedLegalEntities;
    return () => {
      emitLegalEntitiesSelection({
        ...selectedLegalEntities,
        showUPE: !showUPE
      });
    };
  }, [emitLegalEntitiesSelection, selectedLegalEntities]);

  const handlesCustomEntitySelection = useCallback(
    (entitySelected: PrimaryEntityItem | TradingPartnerItem, iteratorName: string) => {
      const primaryEntityIds = [...customSelectedPrimaryEntityIds];
      const tradingPartnersIds = [...customSelectedPrimaryTradingPartnersIds];
      switch (iteratorName) {
        case 'customSelectedPrimaryEntityIds':
          toggleEntityFromList(primaryEntityIds, entitySelected);
          break;

        case 'customSelectedPrimaryTradingPartnersIds':
          toggleEntityFromList(tradingPartnersIds, entitySelected);
          break;

        default:
          break;
      }

      emitLegalEntitiesSelection({
        ...selectedLegalEntities,
        entitiesSelected: { primaryEntityIds, tradingPartnersIds }
      });
    },
    [
      customSelectedPrimaryEntityIds,
      customSelectedPrimaryTradingPartnersIds,
      emitLegalEntitiesSelection,
      selectedLegalEntities
    ]
  );

  const getUpeRow = useMemo(() => {
    return {
      isUPE: true,
      description: upe.code,
      selected: selectedLegalEntities.showUPE,
      action: (
        <Checkbox
          checked={selectedLegalEntities.showUPE}
          className={classes.checkboxSelection}
          style={selectedLegalEntities.showUPE ? { color: tokens.product100 } : undefined}
          onChange={() => {
            emitSelectionOfUPE();
          }}
        />
      )
    };
  }, [upe, classes.checkboxSelection, emitSelectionOfUPE, selectedLegalEntities.showUPE]);

  const getSubTableDataRows = useMemo(() => {
    const upeRow = getUpeRow;
    const rows = [
      ...getSelectedEntities.map((e: PrimaryEntityItem) => {
        const selected = Boolean(customSelectedPrimaryEntityIds.some((entityId: number) => entityId === e.entityId));
        return {
          description: e.code,
          selected,
          action: (
            <Checkbox
              checked={selected}
              className={classes.checkboxSelection}
              style={selected ? { color: tokens.product100 } : undefined}
              onChange={() => {
                handlesCustomEntitySelection(e, 'customSelectedPrimaryEntityIds');
              }}
            />
          )
        };
      }),
      ...getSelectedTradingPartners.map((e: TradingPartnerItem) => {
        const selected = Boolean(
          customSelectedPrimaryTradingPartnersIds.some((entityId: number) => entityId === e.entityId)
        );
        return {
          description: e.code,
          selected,
          action: (
            <Checkbox
              checked={selected}
              className={classes.checkboxSelection}
              style={selected ? { color: tokens.product100 } : undefined}
              onChange={() => {
                handlesCustomEntitySelection(e, 'customSelectedPrimaryTradingPartnersIds');
              }}
            />
          )
        };
      })
    ];

    rows.sort(function (a, b) {
      return a.description > b.description ? 1 : b.description > a.description ? -1 : 0;
    });
    rows.unshift(upeRow);
    return rows;
  }, [
    customSelectedPrimaryEntityIds,
    getUpeRow,
    classes.checkboxSelection,
    customSelectedPrimaryTradingPartnersIds,
    getSelectedEntities,
    getSelectedTradingPartners,
    handlesCustomEntitySelection
  ]);

  const tableTitle = useMemo(() => {
    const entitySelectedCount = getSubTableDataRows.filter((a) => a.selected).length;
    const EntityCount = getSubTableDataRows.length;

    const ChipLabel = `${entitySelectedCount}/${EntityCount} SELECTED`;

    return (
      <Box className={classes.tableTitle}>
        <Typography className={classes.tableTitleDescription}>
          {t('reports:configurationTab-legalEntities-card-table-title')}
        </Typography>
        <Box className={classes.chip}>
          <Chip size="small" label={ChipLabel} />
        </Box>
      </Box>
    );
  }, [getSubTableDataRows, classes, t]);

  const emitOrganizationalChartToggle = useCallback(
    (newState: boolean) => {
      emitLegalEntitiesSelection({
        ...selectedLegalEntities,
        displayOrgChart: newState
      });
    },
    [emitLegalEntitiesSelection, selectedLegalEntities]
  );

  const emitRadioSelection = useCallback(
    (newValue: number, newState: any) => {
      newState.optionSelected = newValue;
      newState.showUPE = newValue === Selection.ByName.IncludeAll.Id;
      newState.entitiesSelected =
        newValue === Selection.ByName.IncludeAll.Id
          ? {
              primaryEntityIds: getSelectedEntities.map((e) => e.entityId),
              tradingPartnersIds: getSelectedTradingPartners.map((e) => e.entityId)
            }
          : { primaryEntityIds: [], tradingPartnersIds: [] };
      return newState;
    },
    [getSelectedEntities, getSelectedTradingPartners]
  );

  const handleRadioBtnChange = useCallback(
    function (e: React.ChangeEvent<HTMLInputElement>) {
      const newValue = Number(e.target.value) as OptionSelected;
      const newSelection = emitRadioSelection(newValue, { ...selectedLegalEntities });
      emitLegalEntitiesSelection(newSelection);
    },
    [selectedLegalEntities, emitRadioSelection, emitLegalEntitiesSelection]
  );

  const tableData = useMemo(() => {
    const selected = {
      includeAll: optionSelected === Selection.ByName.IncludeAll.Id,
      excludeAll: optionSelected === Selection.ByName.ExcludeAll.Id,
      custom: optionSelected === Selection.ByName.Custom.Id
    };

    return [
      {
        description: 'Include All',
        selected: selected.includeAll,
        action: (
          <Radio
            checked={selected.includeAll}
            value={Selection.ByName.IncludeAll.Id}
            className={classes.radioBtnStyle}
            style={{ color: selected.includeAll ? tokens.product100 : tokens.core2 }}
            onChange={(e) => {
              /* eslint-disable camelcase */
              void logGoogleAnalyticsEvent({
                event_category: `report_config_entities_include_all_click`,
                event_label: `Report configuration tab legal entities include all click`,
                container_id: workingContainer?.containerId
              });
              handleRadioBtnChange(e);
            }}
          />
        )
      },
      {
        description: 'Exclude All',
        selected: selected.excludeAll,
        action: (
          <Radio
            checked={selected.excludeAll}
            value={Selection.ByName.ExcludeAll.Id}
            className={classes.radioBtnStyle}
            style={{ color: selected.excludeAll ? tokens.product100 : tokens.core2 }}
            onChange={(e) => {
              void logGoogleAnalyticsEvent({
                event_category: `report_config_entities_exclude_all_click`,
                event_label: `Report configuration tab legal entities exclude all click`,
                container_id: workingContainer?.containerId
              });
              handleRadioBtnChange(e);
            }}
          />
        )
      },
      {
        description: 'Custom Selection',
        selected: selected.custom,
        action: (
          <Radio
            checked={selected.custom}
            value={Selection.ByName.Custom.Id}
            className={classes.radioBtnStyle}
            style={{ color: selected.custom ? tokens.product100 : tokens.core2 }}
            onChange={(e) => {
              void logGoogleAnalyticsEvent({
                event_category: `report_config_entities_custom_selection_click`,
                event_label: `Report configuration tab legal entities custom selection click`,
                container_id: workingContainer?.containerId
              });
              handleRadioBtnChange(e);
            }}
          />
        )
      }
    ];
  }, [optionSelected, classes.radioBtnStyle, workingContainer?.containerId, handleRadioBtnChange]);

  useEffect(() => {
    reset({
      ...getValues(),
      displayOrgChart:
        selectedLegalEntities === undefined || selectedLegalEntities === null
          ? true
          : selectedLegalEntities.displayOrgChart
    });
  }, [reset, getValues, selectedLegalEntities]);

  return (
    <>
      <Box className={classes.header}>
        <Box>
          <Typography className={classes.headerTitle}>{t('reports:configurationTab-legalEntities-title')}</Typography>
          <Typography className={classes.headerSubTitle}>
            {t('reports:configurationTab-legalEntities-subTitle')}
          </Typography>
        </Box>
        <Box className={classes.customizedChip}>{hasCustomization && <Chip size="small" label="CUSTOMIZED" />}</Box>
      </Box>

      {flags.tpiqReportsUiConfigTabAutomatedOrganizationalChart && (
        <Box className={classes.chartSwitchBox}>
          <Box>
            <Typography className={classes.chartSwitchBoxTitle}>
              {t('reports:configurationTab-legalEntities-card-title')}
            </Typography>
            <Typography className={classes.chartSwitchBoxSubTitle}>
              {t('reports:configurationTab-legalEntities-card-subTitle')}
            </Typography>
          </Box>
          <Controller
            name="displayOrgChart"
            control={control}
            render={({ value, onChange }) => (
              <Box display="flex" className={classes.displayOrgChartBox}>
                <Typography
                  className={value ? classes.displayOrgChartSwitchLabelActive : classes.displayOrgChartSwitchLabel}
                >
                  {value
                    ? t('reports:configurationTab-legalEntities-orgChart-included')
                    : t('reports:configurationTab-legalEntities-orgChart-excluded')}
                </Typography>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={value}
                      onChange={(e) => {
                        /* eslint-disable camelcase */
                        void logGoogleAnalyticsEvent({
                          event_category: `report_config_tab_auto_org_chart_click`,
                          event_label: `Report configuration tab legal entities automated organizational chart click`,
                          container_id: workingContainer?.containerId
                        });
                        onChange(e.target.checked);
                        emitOrganizationalChartToggle(e.target.checked);
                      }}
                    />
                  }
                  label={<div />}
                />
              </Box>
            )}
            rules={{ required: true }}
          />
        </Box>
      )}
      <SelectTable
        isSubTable
        columns={tableColumns}
        rows={tableData}
        title={tableTitle}
        subTableSwitch={openSubTable}
        subTableTitle="AVAILABLE ENTITIES"
        subTableColumns={subTableColumns}
        subTableRows={getSubTableDataRows}
      />
    </>
  );
};
