import { useEffect, useRef } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import * as d3 from 'd3';
import { useDimensions } from '../hooks';
import { GeoMapProps, GeoProperties } from '../WorldMap.proptype';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',

    '& .country.selected': {
      cursor: 'pointer'
    }
  }
});

// https://levelup.gitconnected.com/react-d3-rendering-a-map-5e006b2f4895
// https://www.d3indepth.com/geographic/
export function drawGeoJson(
  svg: SVGSVGElement,
  width: number,
  height: number,
  { data, selected, colors, onEnter, onLeave, onClick }: GeoMapProps<GeoProperties>
) {
  if (data !== undefined) {
    const projection = d3
      .geoMercator()
      .fitSize([width, height], data as unknown as d3.ExtendedGeometryCollection<d3.GeoGeometryObjects>);

    const geoGenerator: any = d3.geoPath().projection(projection);
    d3.select(svg)
      .attr('width', width)
      .attr('height', height)
      .selectAll('.country')
      .data(data.features)
      .join('path')
      .attr('class', ({ properties: { selected } }: any) => `country ${selected ? 'selected' : ''}`)
      .attr('fill', ({ properties: { selected } }: any) => (selected ? colors.selectedBackground : colors.background))
      .attr('stroke', ({ properties: { selected } }: any) => (selected ? colors.selectedBorder : colors.border))
      .on('mouseover', ({ target }, { properties }: any) => {
        if (!selected || properties.selected) {
          onEnter(target, properties);
        }
      })
      .on('mouseout', ({ target }, { properties }: any) => {
        if (!selected || properties.selected) {
          onLeave(target, properties);
        }
      })
      .on('click', ({ target }, { properties }: any) => {
        if (!selected || properties.selected) {
          onClick(target, properties);
        }
      })
      .attr('d', geoGenerator);
  }
}

export const GeoMap = (props: GeoMapProps<GeoProperties>) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const { width, height, wrapperRef } = useDimensions();
  const classes = useStyles();

  useEffect(() => {
    if (svgRef.current && props.data) {
      drawGeoJson(svgRef.current, width, height, props);
    }
  }, [width, height, props]);

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      {props.data ? <svg ref={svgRef} /> : <CircularProgress />}
    </div>
  );
};
