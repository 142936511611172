import { combineReducers } from '@reduxjs/toolkit';
import baseData from './baseData';
import complianceCentral from './complianceCentral';
import conversionRates from './conversionRates';
import entities from './entities';
import globalGuides from './globalGuides';
import localReports from './localReports';
import reports from './reports';
import transactions from './transactions';

const mainReducer = combineReducers({
  baseData,
  conversionRates,
  entities,
  complianceCentral,
  globalGuides,
  localReports,
  reports,
  transactions
});

export type RootState = ReturnType<typeof mainReducer>;

export default mainReducer;
