import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalLocalFileReportInstance } from '../../../models';
import httpService from '../../../services/http';

export interface ReportPublishPayload {
  reportId: number;
  reportInstanceId: number;
  containerId: number | undefined;
}

export interface ReportUnpublishPayload {
  reportId: number;
  reportInstanceId: number;
}

export const fetchAllInternalLocalFileReportVersions = createAsyncThunk<
  InternalLocalFileReportInstance[],
  number,
  { rejectValue: Error }
>('reports/versions/fetch', async (reportId, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<InternalLocalFileReportInstance[]>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${String(reportId)}/instance`
      })
    ).data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const updateReportActiveVersion = createAsyncThunk<
  InternalLocalFileReportInstance[],
  any,
  { rejectValue: Error }
>('reports/versions/patch', async (params, { rejectWithValue }) => {
  const { reportId, internalLocalfileReportInstanceId } = params;

  try {
    return (
      await httpService.request<InternalLocalFileReportInstance[]>({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${String(reportId)}/instance/${String(
          internalLocalfileReportInstanceId
        )}/active-status`,
        data: {}
      })
    ).data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const deleteReport = createAsyncThunk<
  InternalLocalFileReportInstance[],
  { reportId: number },
  { rejectValue: Error }
>('reports/versions/delete', async (params, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<InternalLocalFileReportInstance[]>({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${String(params.reportId)}`
      })
    ).data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const deleteReportVersion = createAsyncThunk<
  InternalLocalFileReportInstance[],
  { reportId: number; internalLocalfileReportInstanceId: number },
  { rejectValue: Error }
>('reports/versions/delete/version', async (params, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<InternalLocalFileReportInstance[]>({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${String(params.reportId)}/instance/${String(
          params.internalLocalfileReportInstanceId
        )}`
      })
    ).data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const fetchInternalLocalFileReportInstance = createAsyncThunk<
  InternalLocalFileReportInstance,
  { reportId: number; instanceId: number },
  { rejectValue: Error }
>('reports/instance/fetch', async (params, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<InternalLocalFileReportInstance>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${String(params.reportId)}/instance/${String(params.instanceId)}`
      })
    ).data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const publishReport = createAsyncThunk<void, ReportPublishPayload, { rejectValue: Error }>(
  'reports/publish',
  async ({ reportId, reportInstanceId, containerId }, { rejectWithValue }) => {
    try {
      await httpService.request<void>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${reportId}/instance/${reportInstanceId}/publish`,
        data: { containerId }
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const unpublishReport = createAsyncThunk<void, ReportUnpublishPayload, { rejectValue: Error }>(
  'reports/unpublish',
  async ({ reportId, reportInstanceId }, { rejectWithValue }) => {
    try {
      await httpService.request<void>({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${reportId}/instance/${reportInstanceId}/unpublish`
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);
