import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ConfigurationButton } from './components/ConfigurationButton/ConfigurationButton';
import { ConfigurationTabProps, SectionsOrder } from './ConfigurationTab.proptype';
import { Appendix } from './sections/Appendix';
import { AppendixNav } from './sections/Appendix/AppendixNav/AppendixNav';
import { ControlledTransactions } from './sections/ControlledTransaction';
import { LegalEntities } from './sections/LegalEntities';
import { ProfitBasedAnalysis } from './sections/ProfitBasedAnalysis';
import { ReportSummary } from './sections/ReportSummary';
import { useWorkingContainer } from '../../../hooks/container/useWorkingContainer';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { selectWorkingContainer } from '../../../selectors';
import tokens from '../../../styles/designTokens';
import { captionOverline } from '../../../styles/typography';
import { hasAccess } from '../../../utils';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticsEvent';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center'
  },
  containerLeft: {
    height: '100%',
    width: '30%',
    minWidth: '30%',
    boxShadow: '3px 0px 3px 0px rgba(0,0,0,0.11)',
    padding: '1.375rem',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  containerRight: {
    height: '100%',
    minWidth: '70%',
    paddingLeft: '3.69rem',
    paddingRight: '3.69rem',
    paddingBottom: '3.69rem',
    overflowY: 'auto'
  },
  settingsBox: {
    width: '100%',
    height: 'fit-content',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  settingsHeader: {
    marginBottom: '15px',
    color: tokens.core2,
    ...captionOverline,
    marginLeft: '5px'
  }
}));

export const ConfigurationTab = ({
  selectedTransactions,
  selectedSection,
  jurisdictionName,
  upe,
  primaryEntities,
  tradingPartners,
  emitSectionSelected,
  emitCurrentAppendixInAppendixConfigState,
  emitAddAppendixInAppendixConfigState,
  appendixConfigState,
  entityStatementFactsConfigState,
  controlledTransactionsConfigState,
  customizedLegalEntities,
  customizedControlledTransactions,
  customizedReportSections,
  selectedPBAs,
  profitBasedAnalysisConfigState
}: ConfigurationTabProps) => {
  const classes = useStyles();
  const flags = useFlags();
  const [featureFlagIsActive] = useFeatureFlags();

  const { t } = useTranslation();
  const container = useWorkingContainer();
  const [sectionSelected, setSectionSelected] = useState<number>(selectedSection);
  const hasAccessToAppendixSection = hasAccess(flags.tpiqReportGeneratorCustomAppendices, container?.containerId);
  const showPBAReportConfiguration = featureFlagIsActive('showPbaReportConfiguration');
  const workingContainer = useSelector(selectWorkingContainer);

  const configurationSections = [
    {
      name: 'Report Summary',
      component: () => {
        return (
          <ReportSummary
            jurisdictionName={jurisdictionName}
            upe={upe}
            transactions={selectedTransactions}
            primaryEntities={primaryEntities}
            tradingPartners={tradingPartners}
            controlledTransactionsConfigState={controlledTransactionsConfigState}
            entityStatementFactsConfigState={entityStatementFactsConfigState}
            hasCustomization={customizedReportSections}
          />
        );
      }
    },
    {
      name: 'Legal Entities',
      component: () => {
        return (
          <LegalEntities
            entityStatementFactsConfigState={entityStatementFactsConfigState}
            primaryEntities={primaryEntities}
            tradingPartners={tradingPartners}
            upe={upe}
            hasCustomization={customizedLegalEntities}
          />
        );
      }
    },
    {
      name: 'Controlled Transaction',
      component: () => {
        return (
          <ControlledTransactions
            controlledTransactionsConfigState={controlledTransactionsConfigState}
            transactions={selectedTransactions}
            hasCustomization={customizedControlledTransactions}
          />
        );
      }
    },
    {
      name: 'Profit Based Analysis',
      component: () => {
        return (
          <ProfitBasedAnalysis
            hasCustomization
            profitBasedAnalysisConfigState={profitBasedAnalysisConfigState}
            pbas={selectedPBAs}
          />
        );
      }
    },
    {
      name: 'Appendix',
      component: () => {
        return <Appendix />;
      }
    }
  ];

  const displayReportSummary = () => {
    setSectionSelected(() => {
      const section = SectionsOrder.ReportSummary;
      emitSectionSelected(section);
      return section;
    });
  };

  const displayLegalEntities = () => {
    setSectionSelected(() => {
      const section = SectionsOrder.LegalEntities;
      emitSectionSelected(section);
      return section;
    });
  };

  const displayControlledTransaction = () => {
    setSectionSelected(() => {
      const section = SectionsOrder.ControlledTransactions;
      emitSectionSelected(section);
      return section;
    });
  };

  const displayProfitBasedAnalysis = () => {
    setSectionSelected(() => {
      const section = SectionsOrder.ProfitBasedAnalysis;
      emitSectionSelected(section);
      return section;
    });
  };

  const displayAppendixSection = () => {
    setSectionSelected((prevState) => {
      const section = hasAccessToAppendixSection ? SectionsOrder.Appendix : prevState;
      emitSectionSelected(section);
      return section;
    });
  };

  const Section = () => configurationSections[sectionSelected].component();

  return (
    <Box className={classes.root}>
      <Box className={classes.containerLeft}>
        <ConfigurationButton
          change={customizedReportSections}
          active={sectionSelected === SectionsOrder.ReportSummary}
          action={displayReportSummary}
          text={t('reports:configurationTab-sections-reportSummary')}
        />
        <Box className={classes.settingsBox}>
          <Typography className={classes.settingsHeader}>
            {t('reports:configurationTab-component-left-nav-bar')}
          </Typography>
          <ConfigurationButton
            change={customizedLegalEntities}
            active={sectionSelected === SectionsOrder.LegalEntities}
            action={() => {
              /* eslint-disable camelcase */
              void logGoogleAnalyticsEvent({
                event_category: `report_config_legal_entities_click`,
                event_label: `Report configuration tab legal entities click`,
                container_id: workingContainer?.containerId
              });
              displayLegalEntities();
            }}
            text={t('reports:configurationTab-sections-legalEntities')}
          />
          <ConfigurationButton
            action={() => {
              void logGoogleAnalyticsEvent({
                event_category: `report_config_cont_trans_click`,
                event_label: `Report configuration tab controlled transaction click`,
                container_id: workingContainer?.containerId
              });
              displayControlledTransaction();
            }}
            text={t('reports:configurationTab-sections-controlledTransaction')}
            active={sectionSelected === SectionsOrder.ControlledTransactions}
            change={customizedControlledTransactions}
          />
          {showPBAReportConfiguration && (
            <ConfigurationButton
              action={() => {
                void logGoogleAnalyticsEvent({
                  event_category: `report_config_cont_pbas_click`,
                  event_label: `Report configuration tab pbas click`,
                  container_id: workingContainer?.containerId
                });
                displayProfitBasedAnalysis();
              }}
              text={t('reports:configurationTab-sections-profitBasedAnalysis')}
              active={sectionSelected === SectionsOrder.ProfitBasedAnalysis}
            />
          )}
          {hasAccessToAppendixSection && (
            <AppendixNav
              displayAppendixSection={displayAppendixSection}
              isAppendixDisplay={sectionSelected === SectionsOrder.Appendix}
              appendixConfigState={appendixConfigState}
              emitAddAppendixInAppendixConfigState={emitAddAppendixInAppendixConfigState}
              emitCurrentAppendixInAppendixConfigState={emitCurrentAppendixInAppendixConfigState}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.containerRight}>
        <Section />
      </Box>
    </Box>
  );
};
