import { format, parse } from 'date-fns';

export const hoursToAdd = 23;
export const minutesToAdd = 59;
export const minutesPerHour = 60;
export const milisecondsPerMinute = 60000;

export const parseAndFormatForDisplay = (dateString: string) => {
  try {
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    return format(parsedDate, 'dd MMM yyyy');
  } catch {
    return '';
  }
};

const getLocalTimezoneOffset = () => {
  return new Date().getTimezoneOffset();
};

export const dateInUTC = (timestamp: number) => {
  const localTimeOffsetInMiliseconds = getLocalTimezoneOffset() * milisecondsPerMinute;
  const UTCDate = timestamp + localTimeOffsetInMiliseconds;
  return UTCDate;
};

export const dateToTimestamp = (year: number, month: number, day: number) => {
  const timestamp = new Date(year, month, day).getTime();
  const timeToAddInMiliseconds = (hoursToAdd * minutesPerHour + minutesToAdd) * milisecondsPerMinute;
  const dateToTimestamp = timestamp + timeToAddInMiliseconds;
  return dateToTimestamp;
};

export const timestampToDate = (timestamp: number | string) => {
  const date = new Date(timestamp);
  return date;
};

export const getDateAsTimestamp = (date: number | string) => {
  const formattedDate = new Date(date).getTime();
  const timeToAddInMiliseconds = (hoursToAdd * minutesPerHour + minutesToAdd) * milisecondsPerMinute;
  const dateToTimestamp = formattedDate + timeToAddInMiliseconds;
  return dateToTimestamp;
};

export const normalizeMonth = (date: Date) => {
  let month = date.getMonth();
  month++;
  return month;
};
