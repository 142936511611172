import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { selectWorkingContainer } from '../../../selectors';
import appColors from '../../../styles/appColors';
import tokens from '../../../styles/designTokens';
import { captionOverline } from '../../../styles/typography';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticsEvent';
import { ReportStatus } from '../LocalReports.proptype';

export interface StatusButtonProps {
  isGenerating: boolean;
  status: ReportStatus;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles(() => ({
  statusButton: {
    ...captionOverline,
    width: '12.31rem',
    height: '2.25rem',
    textTransform: 'uppercase',
    '&.Mui-disabled': {
      background: tokens.neutral70
    }
  },
  draft: {
    color: appColors.reports.draft.text,
    background: appColors.reports.draft.fill
  },
  review: {
    color: appColors.reports.review.text,
    background: appColors.reports.review.fill
  },
  published: {
    color: appColors.reports.published.text,
    background: appColors.reports.published.fill
  },
  final: {
    color: appColors.reports.final.text,
    background: appColors.reports.final.fill
  }
}));

export const StatusButton = ({ isGenerating, status, onClick }: StatusButtonProps) => {
  const classes = useStyles();
  const workingContainer = useSelector(selectWorkingContainer);
  const { t } = useTranslation();

  return (
    <Button
      fullWidth
      color="secondary"
      variant="contained"
      disabled={isGenerating}
      className={`${classes[status]} ${classes.statusButton}`}
      endIcon={<ExpandMore />}
      onClick={(e) => {
        /* eslint-disable camelcase */
        void logGoogleAnalyticsEvent({
          event_category: 'report_repository_status_button_click',
          event_label: `Report repository status button click`,
          container_id: workingContainer?.containerId
        });
        onClick(e);
      }}
    >
      {t('reports:status')}: {t(`reports:${status}`)}
    </Button>
  );
};
