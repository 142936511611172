import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, makeStyles, Typography, Switch } from '@material-ui/core';
import { Launch as LaunchIcon } from '@material-ui/icons';
import { ProfitBasedAnalysisProps } from './ProfitBasedAnalysis.proptype';
import { ProfitBasedAnalysisConfig, PBAItem } from '../../../../../redux/localReports/localReports.proptype';
import tokens from '../../../../../styles/designTokens';
import { body1, captionOverline, title1, title2 } from '../../../../../styles/typography';
import { SelectTable } from '../../components/SelectTable/SelectTable';

const useStyles = makeStyles(() => ({
  header: {
    marginTop: '2.875rem',
    marginBottom: '1.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...captionOverline
  },
  headerTitle: {
    color: tokens.core3,
    ...title2
  },
  headerSubTitle: {
    color: tokens.core1,
    ...body1
  },
  chartSwitchBox: {
    width: '100%',
    height: '5.25rem',
    display: 'flex',
    padding: '1.25rem',
    borderRadius: '0.25rem',
    marginBottom: '1.5rem',
    alignItems: 'center',
    backgroundColor: tokens.white,
    justifyContent: 'space-between',
    boxShadow: '0 0 0.25rem 0 rgba(0,0,0,0.12)'
  },
  radioBtnStyle: {
    color: tokens.product100,
    height: '1.25rem',
    width: '1.25rem'
  },
  chartSwitchBoxSubTitle: {
    color: tokens.core2,
    ...captionOverline
  },
  tableTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableTitleDescription: {
    ...title1,
    color: tokens.core3,
    marginRight: '1.25rem'
  },
  tableTitleChip: {
    backgroundColor: tokens.product15,
    ...captionOverline,
    color: tokens.product100,
    padding: 'none'
  },
  checkboxSelection: {
    height: '1.125rem',
    width: '1.125rem'
  },
  switchito: {
    '& .MuiIconButton-label': {
      color: tokens.white,
      '& .MuiSwitch-thumb': {
        marginTop: '2px',
        marginLeft: '2px'
      },
      '& .MuiSwitch-track': {
        backgroundColor: tokens.core1
      }
    },
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: tokens.product100,
      opacity: 1
    }
  },
  customizedChip: {
    '& .MuiChip-root': {
      borderRadius: '0.25rem',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product25,
      marginLeft: '0.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem'
    }
  },
  chip: {
    '& .MuiChip-root': {
      borderRadius: '0.25rem',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product25
    }
  },
  tableChip: {
    '& .MuiChip-root': {
      borderRadius: '0.25rem',
      ...captionOverline,
      color: tokens.product100,
      padding: '0.25rem',
      backgroundColor: tokens.product15
    },
    '& .MuiChip-label': {
      padding: '0.25rem'
    }
  },
  linkIcon: {
    width: '1.25rem',
    height: '1.25rem',
    '&:hover': {
      cursor: 'pointer',
      fill: tokens.product100
    }
  }
}));

export const ProfitBasedAnalysis = ({
  pbas,
  hasCustomization,
  profitBasedAnalysiSelection,
  emitNewProfitBasedAnalysiselection
}: ProfitBasedAnalysisProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentPBAs = profitBasedAnalysiSelection.pbaHideFinancials;

  const tableTitle = useMemo(() => {
    const totalPBAs = pbas.length;
    const selectedPBAs = Object.keys(currentPBAs).filter((pbaId: string) => Boolean(currentPBAs[Number(pbaId)])).length;

    const ChipLabel = `${selectedPBAs}/${totalPBAs} SELECTED`;

    return (
      <Box className={classes.tableTitle}>
        <Typography className={classes.tableTitleDescription}>
          {t('reports:configurationTab-ProfitBasedAnalysis-table-title')}
        </Typography>
        <Box className={classes.tableChip}>
          <Chip size="small" label={ChipLabel} />
        </Box>
      </Box>
    );
  }, [classes.tableChip, classes.tableTitle, classes.tableTitleDescription, currentPBAs, t, pbas.length]);

  const tableColumns: any[] = [
    { key: 'description', header: 'PBA NAME', width: '70%' },
    { key: 'label', header: '', width: '10%', align: 'center' },
    { key: 'action', header: 'HIDE FINANCIALS', width: '10%', align: 'left' },
    { key: 'link', header: '', width: '10%', align: 'right' }
  ];

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleRedirectUrl = (url: string) => {
    window.top?.location.replace(`${window.top.location.origin}/${url}`);
  };

  const handleSwitchBtnChange = (pbaId: number) => {
    const newValue = !currentPBAs[pbaId];
    const newState: ProfitBasedAnalysisConfig = {
      pbaHideFinancials: { ...currentPBAs, [pbaId]: newValue }
    };

    emitNewProfitBasedAnalysiselection(newState);
  };

  const tablePBAs = useMemo(
    () =>
      pbas.map((pba: PBAItem) => {
        const pbaUrl = `newexperience/analysis/${pba.studyId!}/pba-dashboard/${pba.pbaId}/`;
        const { pbaHideFinancials } = profitBasedAnalysiSelection;
        const selected = Boolean(pbaHideFinancials[pba.pbaId]);
        return {
          // TODO: Replace this with pba.name, this requires changes on
          // the API side.
          // Ticket: // Ticket for that work: https://crossbordersolutions.atlassian.net/browse/STREAM-883
          description: pba.name ?? pba.pbaId,
          label: <span>{selected ? 'YES' : 'NO'}</span>,
          action: (
            <Box>
              <Switch
                className={classes.switchito}
                defaultChecked={selected}
                onChange={() => {
                  handleSwitchBtnChange(pba.pbaId);
                }}
              />
            </Box>
          ),
          // TODO: Implement <Link> component to redirect to PBA dashboard.
          // NOTE: In order to redirect, we need the `studyId` value, that
          // currently is not available in the PBA data.
          // Ticket for that work: https://crossbordersolutions.atlassian.net/browse/STREAM-883
          link: (
            <LaunchIcon
              className={classes.linkIcon}
              onClick={() => {
                handleRedirectUrl(pbaUrl);
              }}
            />
          )
        };
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pbas, profitBasedAnalysiSelection]
  );

  return (
    <>
      <Box className={classes.header}>
        <Box>
          <Typography className={classes.headerTitle}>
            {t('reports:configurationTab-ProfitBasedAnalysis-title')}
          </Typography>
          <Typography className={classes.headerSubTitle}>
            {t('reports:configurationTab-ControlledTransactions-sub-title')}
          </Typography>
        </Box>

        <Box className={classes.customizedChip}>{hasCustomization && <Chip size="small" label="CUSTOMIZED" />}</Box>
      </Box>

      <SelectTable fullWidth title={tableTitle} columns={tableColumns} rows={tablePBAs} />
    </>
  );
};
