import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  SignedUrl,
  UploadedAppendixSignedUrlInfo,
  UploadedReportSignedUrlInfo
} from '../../../app/LocalReports/LocalReports.proptype';
import {
  GetSignedUrlAppendixFilePayload,
  UploadReportPayloadProps
} from '../../../app/LocalReportsActions/UploadReportModal/UploadReportModal.proptype';
import httpService from '../../../services/http';

export const getSignedUrlForUploadedReport = createAsyncThunk<
  UploadedReportSignedUrlInfo,
  UploadReportPayloadProps,
  { rejectValue: Error }
>('localReports/instance/upload/post', async (payload, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/internal-localfile-report-instance/upload`;
    const { data }: any = await httpService.request<SignedUrl>({
      method: 'post',
      apiUrlKey: 'docApiUrl',
      relativePath,
      data: payload
    });

    return data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const uploadReportToSignedUrl = createAsyncThunk<
  any,
  { awsSignedUrlPath: string; reportFile: File },
  { rejectValue: Error }
>('localReports/instance/upload/put', async ({ awsSignedUrlPath, reportFile }, { rejectWithValue }) => {
  try {
    const response = await httpService.requestWithoutAuthHeader<any>(awsSignedUrlPath, 'put', reportFile, {
      'Content-Type': reportFile.type
    });
    return response.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const getSignedUrlForAppendix = createAsyncThunk<
  UploadedAppendixSignedUrlInfo,
  GetSignedUrlAppendixFilePayload,
  { rejectValue: Error }
>('localReports/instance/appendix/upload/post', async (payload, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/appendix/upload`;
    const { data }: any = await httpService.request<SignedUrl>({
      method: 'post',
      apiUrlKey: 'docApiUrl',
      relativePath,
      data: payload
    });

    return data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});
