import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { NotificationsContext } from './Notifications';
import Router from './Router';
import designTokens from '../styles/designTokens';
import { buildTheme } from '../utils';

const DIYTheme = buildTheme(designTokens);

const App = () => {
  return (
    <ThemeProvider theme={DIYTheme}>
      <CssBaseline />
      <NotificationsContext>
        <Router />
      </NotificationsContext>
    </ThemeProvider>
  );
};

export default App;
