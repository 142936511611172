import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { ComplianceCentralCounter, ReportsInfo, MasterFileReportsInfo } from '../../../models';
import { selectWorkingContainer } from '../../../selectors';
import httpService from '../../../services/http';

export const fetchComplianceCentralCounter = createAsyncThunk<
  ComplianceCentralCounter,
  void,
  { state: RootState; rejectValue: Error }
>('complianceCentral/counter', async (_, { getState, rejectWithValue }) => {
  try {
    const container = selectWorkingContainer(getState());

    return (
      await httpService.request<{ data: ComplianceCentralCounter }>({
        method: 'get',
        apiUrlKey: 'complianceCentralApiUrl',
        relativePath: `/v1/containers/${container!.containerId}/counters`
      })
    ).data.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const fetchComplianceCentralReportsInfo = createAsyncThunk<
  ReportsInfo[],
  number,
  { state: RootState; rejectValue: Error }
>('complianceCentral/reportsInfo', async (containerId: number, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: ReportsInfo[] }>({
        method: 'get',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/containers/${containerId}/report/report-information`
      })
    ).data.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const fetchComplianceCentralMasterFileReportsInfo = createAsyncThunk<
  MasterFileReportsInfo[],
  number,
  { state: RootState; rejectValue: Error }
>('complianceCentral/masterFileReportsInfo', async (containerId: number, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: MasterFileReportsInfo[] }>({
        method: 'get',
        apiUrlKey: 'complianceCentralApiUrl',
        relativePath: `/v1/containers/${containerId}/master-file-report-information`
      })
    ).data.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});
