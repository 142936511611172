import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Chip, makeStyles, Radio, Checkbox, Typography } from '@material-ui/core';
import { InternalLocalFileTransactionDocumentationVisibility as Selection } from '@xbs/xbs-enums';
import { ControlledTransactionsProps } from './ControlledTransaction.proptype';
import {
  ControlledTransactionsDocumentationConfig,
  OptionSelected,
  TransactionItem
} from '../../../../../redux/localReports/localReports.proptype';
import { selectWorkingContainer } from '../../../../../selectors';
import tokens from '../../../../../styles/designTokens';
import { body1, captionOverline, title1, title2 } from '../../../../../styles/typography';
import { logGoogleAnalyticsEvent } from '../../../../../utils/sendGoogleAnalyticsEvent';
import { SelectTable } from '../../components/SelectTable/SelectTable';

const useStyles = makeStyles(() => ({
  header: {
    marginTop: '2.875rem',
    marginBottom: '1.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...captionOverline
  },
  headerTitle: {
    color: tokens.core3,
    ...title2
  },
  headerSubTitle: {
    color: tokens.core1,
    ...body1
  },
  chartSwitchBox: {
    width: '100%',
    height: '5.25rem',
    display: 'flex',
    padding: '1.25rem',
    borderRadius: '0.25rem',
    marginBottom: '1.5rem',
    alignItems: 'center',
    backgroundColor: tokens.white,
    justifyContent: 'space-between',
    boxShadow: '0 0 0.25rem 0 rgba(0,0,0,0.12)'
  },
  radioBtnStyle: {
    color: tokens.product100,
    height: '1.25rem',
    width: '1.25rem'
  },
  chartSwitchBoxSubTitle: {
    color: tokens.core2,
    ...captionOverline
  },
  tableTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableTitleDescription: {
    ...title1,
    color: tokens.core3,
    marginRight: '1.25rem'
  },
  tableTitleChip: {
    backgroundColor: tokens.product15,
    ...captionOverline,
    color: tokens.product100,
    padding: 'none'
  },
  checkboxSelection: {
    height: '1.125rem',
    width: '1.125rem'
  },
  customizedChip: {
    '& .MuiChip-root': {
      borderRadius: '0.25rem',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product25,
      marginLeft: '0.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem'
    }
  },
  chip: {
    '& .MuiChip-root': {
      borderRadius: '0.25rem',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product25
    }
  },
  tableChip: {
    '& .MuiChip-root': {
      borderRadius: '0.25rem',
      ...captionOverline,
      color: tokens.product100,
      padding: '0.25rem',
      backgroundColor: tokens.product15
    },
    '& .MuiChip-label': {
      padding: '0.25rem'
    }
  }
}));

export const ControlledTransactions = ({
  transactions,
  hasCustomization,
  controlledTransactionSelection,
  emitNewControlledTransactionSelection
}: ControlledTransactionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const workingContainer = useSelector(selectWorkingContainer);

  const currentSelection = controlledTransactionSelection.optionSelected;
  const currentCustomTransaction = controlledTransactionSelection.transactionsSelectedIds;
  const openSubTable = currentSelection === Selection.ByName.Custom.Id;
  const { optionSelected } = controlledTransactionSelection;

  const tableTitle = useMemo(() => {
    const totalTransactions = transactions.length;
    const selectedTransactions = currentCustomTransaction.length;

    const ChipLabel = `${selectedTransactions}/${totalTransactions} SELECTED`;

    return (
      <Box className={classes.tableTitle}>
        <Typography className={classes.tableTitleDescription}>
          {t('reports:configurationTab-ControlledTransactions-table-title')}
        </Typography>
        <Box className={classes.tableChip}>
          <Chip size="small" label={ChipLabel} />
        </Box>
      </Box>
    );
  }, [
    classes.tableChip,
    classes.tableTitle,
    classes.tableTitleDescription,
    currentCustomTransaction.length,
    t,
    transactions.length
  ]);

  const tableColumns: any[] = [
    { key: 'description', header: '', width: '85%' },
    { key: 'action', header: '', width: '15%', align: 'center' }
  ];

  const subTableColumns: any[] = [
    { key: 'description', header: '', width: '85%' },
    { key: 'action', header: '', width: '15%', align: 'center' }
  ];

  const handleRadioBtnChange = (e: any) => {
    const value = Number(e.target.value) as OptionSelected;
    const newState: ControlledTransactionsDocumentationConfig = {
      optionSelected: value,
      transactionsSelectedIds: value === Selection.ByName.IncludeAll.Id ? transactions.map((t) => t.transactionId) : []
    };

    emitNewControlledTransactionSelection(newState);
  };

  const handleSelectionOfCustomTransaction = useMemo(
    () => (e: any) => {
      const transactionId = Number(e.target.value);
      const { transactionsSelectedIds } = controlledTransactionSelection;
      const currentTransactions = [...transactionsSelectedIds];

      if (currentTransactions.some((id) => id === transactionId)) {
        const index = currentTransactions.indexOf(transactionId);
        currentTransactions.splice(index, 1);
      } else {
        currentTransactions.push(transactionId);
      }

      emitNewControlledTransactionSelection({
        ...controlledTransactionSelection,
        transactionsSelectedIds: currentTransactions
      });
    },
    [controlledTransactionSelection, emitNewControlledTransactionSelection]
  );

  const subTableTransactions = useMemo(
    () =>
      transactions.map((t: TransactionItem) => {
        const { transactionsSelectedIds } = controlledTransactionSelection;
        const selected = Boolean(transactionsSelectedIds.some((id: number) => id === t.transactionId));
        return {
          description: t.identifier,
          selected,
          action: (
            <Checkbox
              checked={selected}
              className={classes.checkboxSelection}
              value={t.transactionId}
              style={selected ? { color: tokens.product100 } : undefined}
              onChange={handleSelectionOfCustomTransaction}
            />
          )
        };
      }),
    [transactions, controlledTransactionSelection, classes.checkboxSelection, handleSelectionOfCustomTransaction]
  );

  const tableData = () => {
    const selected = {
      includeAll: optionSelected === Selection.ByName.IncludeAll.Id,
      excludeAll: optionSelected === Selection.ByName.ExcludeAll.Id,
      custom: optionSelected === Selection.ByName.Custom.Id
    };

    return [
      {
        description: t('reports:configurationTab-ControlledTransactions-radioBtn-include-all'),
        selected: currentSelection === Selection.ByName.IncludeAll.Id,
        action: (
          <Radio
            style={{ color: selected.includeAll ? tokens.product100 : tokens.core2 }}
            checked={currentSelection === Selection.ByName.IncludeAll.Id}
            value={Selection.ByName.IncludeAll.Id}
            className={classes.radioBtnStyle}
            onChange={(e) => {
              /* eslint-disable camelcase */
              void logGoogleAnalyticsEvent({
                event_category: `report_config_cont_trans_include_all_click`,
                event_label: `Report configuration tab controlled transaction include all click`,
                container_id: workingContainer?.containerId
              });
              handleRadioBtnChange(e);
            }}
          />
        )
      },
      {
        description: t('reports:configurationTab-ControlledTransactions-radioBtn-exclude-all'),
        selected: currentSelection === Selection.ByName.ExcludeAll.Id,
        action: (
          <Radio
            style={{ color: selected.excludeAll ? tokens.product100 : tokens.core2 }}
            checked={currentSelection === Selection.ByName.ExcludeAll.Id}
            value={Selection.ByName.ExcludeAll.Id}
            className={classes.radioBtnStyle}
            onChange={(e) => {
              void logGoogleAnalyticsEvent({
                event_category: `report_config_cont_trans_exclude_all_click`,
                event_label: `Report configuration tab controlled transaction exclude all click`,
                container_id: workingContainer?.containerId
              });
              handleRadioBtnChange(e);
            }}
          />
        )
      },
      {
        description: t('reports:configurationTab-ControlledTransactions-radioBtn-custom'),
        selected: currentSelection === Selection.ByName.Custom.Id,
        action: (
          <Radio
            style={{ color: selected.custom ? tokens.product100 : tokens.core2 }}
            checked={currentSelection === Selection.ByName.Custom.Id}
            value={Selection.ByName.Custom.Id}
            className={classes.radioBtnStyle}
            onChange={(e) => {
              void logGoogleAnalyticsEvent({
                event_category: `report_config_cont_trans_custom_selection_click`,
                event_label: `Report configuration tab controlled transaction custom selection click`,
                container_id: workingContainer?.containerId
              });
              handleRadioBtnChange(e);
            }}
          />
        )
      }
    ];
  };

  return (
    <>
      <Box className={classes.header}>
        <Box>
          <Typography className={classes.headerTitle}>
            {t('reports:configurationTab-ControlledTransactions-title')}
          </Typography>
          <Typography className={classes.headerSubTitle}>
            {t('reports:configurationTab-ControlledTransactions-sub-title')}
          </Typography>
        </Box>

        <Box className={classes.customizedChip}>{hasCustomization && <Chip size="small" label="CUSTOMIZED" />}</Box>
      </Box>

      <SelectTable
        isSubTable
        title={tableTitle}
        columns={tableColumns}
        rows={tableData()}
        subTableSwitch={openSubTable}
        subTableTitle={t('reports:configurationTab-ControlledTransactions-sub-table-title')}
        subTableColumns={subTableColumns}
        subTableRows={subTableTransactions}
      />
    </>
  );
};
