import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { CustomAppendixFile } from '../../../../../../models/reports.interface';
import tokens from '../../../../../../styles/designTokens';
import { body1, title2 } from '../../../../../../styles/typography';
import { MenuDots } from '../../../components/Menu/Menu';
import { MoreActionsIcon } from '../Appendix.icons';
import { GenerateAppendixProps } from '../Appendix.proptype';
import { AppendixFileTable } from '../AppendixFileTable/AppendixFileTable';

const useStyles = makeStyles(() => ({
  header: {
    marginTop: '2.5rem',
    marginBottom: '1.6rem'
  },
  title: {
    ...title2,
    color: tokens.core3
  },
  subTitle: {
    ...body1,
    marginTop: '0.4rem',
    color: tokens.core1
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export const AppendixGenerate = ({
  appendix,
  deleteAppendix,
  deleteFileForAppendix,
  updateAppendixTitle,
  uploadFileForAppendix
}: GenerateAppendixProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onHandleChangeTitle = (title: string) => {
    updateAppendixTitle(title, appendix.identifier);
  };

  const onHandleAddFileInAppendix = (appendixFile: CustomAppendixFile, file: File) => {
    uploadFileForAppendix(appendix, appendixFile, file);
  };

  const onHandleDeleteAppendix = () => {
    deleteAppendix(appendix.identifier);
  };

  const onHandleDeleteFile = (file: CustomAppendixFile) => {
    deleteFileForAppendix(file, appendix);
  };

  const menuAction = [
    { action: onHandleDeleteAppendix, description: t('reports:configurationTab-Appendix-GenerateAppendix-delete') }
  ];

  return (
    <Box>
      <Box className={classes.header}>
        <Box className={classes.headerTop}>
          <Typography className={classes.title}>
            {t('reports:configurationTab-Appendix-GenerateAppendix-title')}
          </Typography>
          <MenuDots icon={() => <MoreActionsIcon />} options={menuAction} />
        </Box>
        <Typography className={classes.subTitle}>
          {t('reports:configurationTab-Appendix-GenerateAppendix-sub-title')}
        </Typography>
      </Box>
      <AppendixFileTable
        files={appendix.files}
        title={appendix.title}
        onChangeTitle={onHandleChangeTitle}
        onAddFile={onHandleAddFileInAppendix}
        onDeleteFile={onHandleDeleteFile}
      />
    </Box>
  );
};
