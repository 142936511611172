import { FunctionComponent, ReactNode } from 'react';
import { Entity } from '../../../models';
import { CustomAppendix } from '../../../models/reports.interface';
import {
  AppendixConfigState,
  ControlledTransactionsDocumentationConfig,
  EntityStatementOfFactsConfig,
  PBAItem,
  PrimaryEntityItem,
  ProfitBasedAnalysisConfig,
  TradingPartnerItem,
  TransactionItem
} from '../../../redux/localReports/localReports.proptype';

export interface ConfigurationTabProps {
  jurisdictionName: string;
  selectedSection: number;
  upe: Entity;
  selectedTransactions: TransactionItem[];
  primaryEntities: PrimaryEntityItem[];
  tradingPartners: TradingPartnerItem[];
  emitSectionSelected: (n: number) => number;
  emitCurrentAppendixInAppendixConfigState: (appendixId: CustomAppendix['identifier']) => void;
  emitAddAppendixInAppendixConfigState: (appendix: CustomAppendix) => void;
  appendixConfigState: AppendixConfigState;
  entityStatementFactsConfigState: EntityStatementOfFactsConfig;
  controlledTransactionsConfigState: ControlledTransactionsDocumentationConfig;
  customizedLegalEntities: boolean;
  customizedControlledTransactions: boolean;
  customizedReportSections: boolean;
  selectedPBAs: PBAItem[];
  profitBasedAnalysisConfigState: ProfitBasedAnalysisConfig;
}

export interface ConnectorProps {
  component: FunctionComponent<ConfigurationTabProps>;
  jurisdictionName: string;
  emitSection: (n: number) => number;
  selectedSection: number;
}

export interface TableColumn {
  key: string;
  header?: ReactNode | string;
  width?: string | number;
}

export interface MenuDotsProps {
  options?: Option[];
  icon?: FunctionComponent;
}

export interface Option {
  description: string;
  action: () => void;
}

export enum SectionsOrder {
  ReportSummary = 0,
  LegalEntities,
  ControlledTransactions,
  ProfitBasedAnalysis,
  Appendix
}
