import { useDispatch } from 'react-redux';
import { ConnectorProps } from './TransactionsTab.proptype';
import { useSelectedTransactions } from '../../../hooks/transaction/useSelectedTransactions';
import { useTransactionsListByTradingPartners } from '../../../hooks/transaction/useTransactionsListByTradingPartners';
import { useUPECurrency } from '../../../hooks/upe/useUPECurrency';
import { actions as localReportsActions } from '../../../redux/localReports';
import { SelectedTransactionOrdering } from '../../../redux/localReports/localReports.proptype';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const transactions = useTransactionsListByTradingPartners();
  const selectedTransactions = useSelectedTransactions();
  const upeCurrency = useUPECurrency();

  const onSelectionChange = (selectedIds: number[]) => {
    dispatch(localReportsActions.setSelectedTransactions(selectedIds));
  };

  const onSelectionOrdering = (ordering: SelectedTransactionOrdering) => {
    dispatch(localReportsActions.setSelectedTransactionsOrdering(ordering));
  };

  return (
    <Component
      transactions={transactions}
      upeCurrency={upeCurrency!}
      selectedTransactions={selectedTransactions.map((trans) => trans.transactionId)}
      onSelectionChange={onSelectionChange}
      onSelectionOrdering={onSelectionOrdering}
    />
  );
};

export default Connector;
