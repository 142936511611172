import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CenteredProgress, NoContainersMessage } from '../../components';
import { useBaseDataFetched } from '../../hooks/base-data/useBaseDataFetched';
import { useContainers } from '../../hooks/container/useContainers';
import { fetchBaseData } from '../../redux/baseData/thunks/baseData';

interface baseDataLoaderProps {
  children: JSX.Element;
}

export const BaseDataLoader = ({ children }: baseDataLoaderProps) => {
  const dispatch = useDispatch();
  const containers = useContainers();
  const isBaseDataFetch = useBaseDataFetched();

  useEffect(() => {
    if (!isBaseDataFetch) {
      dispatch(fetchBaseData());
    }
  }, [isBaseDataFetch, dispatch]);

  if (!isBaseDataFetch) {
    return <CenteredProgress />;
  }

  return containers.length > 0 ? children : <NoContainersMessage />;
};
