import { useState, useEffect, useCallback, RefObject } from 'react';

interface useDraggableProps {
  areaRef: RefObject<HTMLDivElement>;
  multiple?: boolean | false;
  handleChanges: (arg0: File[]) => void;
}

export const useDraggable = ({ areaRef, multiple, handleChanges }: useDraggableProps): boolean => {
  const [dragging, setDragging] = useState(false);
  const [draggingCount, setDraggingCount] = useState(0);

  const handleDragIn = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      setDraggingCount(draggingCount + 1);
      if (ev.dataTransfer.items && ev.dataTransfer.items.length > 0) {
        setDragging(true);
      }
    },
    [draggingCount]
  );

  const handleDragOut = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      const draggingCountTemp = draggingCount - 1;
      setDraggingCount(draggingCountTemp);
      if (draggingCountTemp > 0) return;
      setDragging(false);
    },
    [draggingCount]
  );

  const handleDrag = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      setDragging(false);
      setDraggingCount(0);

      const eventFiles = ev.dataTransfer.files;
      if (eventFiles && eventFiles.length > 0) {
        const files = multiple ? eventFiles : eventFiles[0];
        handleChanges(files);
      }
    },
    [handleChanges, multiple]
  );

  useEffect(() => {
    const ele = areaRef.current;
    ele?.addEventListener('dragenter', handleDragIn);
    ele?.addEventListener('dragleave', handleDragOut);
    ele?.addEventListener('dragover', handleDrag);
    ele?.addEventListener('drop', handleDrop);
    return () => {
      ele?.removeEventListener('dragenter', handleDragIn);
      ele?.removeEventListener('dragleave', handleDragOut);
      ele?.removeEventListener('dragover', handleDrag);
      ele?.removeEventListener('drop', handleDrop);
    };
  }, [handleDragIn, handleDragOut, handleDrag, handleDrop, areaRef]);

  return dragging;
};
