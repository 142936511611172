import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchGlobalGuides } from './thunks/globalGuides';
import { GlobalGuide, NormalizedStateSlice } from '../../models';
import { getWorkingContainer } from '../baseData/thunks/baseData';

interface GlobalGuidesState extends NormalizedStateSlice<GlobalGuide> {
  error?: string;
}

const initialState: GlobalGuidesState = { byId: null, allIds: [] };

const globalGuidesSlice = createSlice({
  name: 'globalGuides',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchGlobalGuides.fulfilled, (state, { payload: guides }) => {
        state.byId = {};
        state.allIds = [];
        for (const guide of guides ?? []) {
          state.byId[guide.countryId] = guide;
          state.allIds.push(guide.countryId);
        }
      })
      .addMatcher(
        (action) => action.type.match(/^globalGuides\/.+\/pending$/),
        (state) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^globalGuides\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default globalGuidesSlice.reducer;
