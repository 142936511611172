import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import tokens from '../../../../../styles/designTokens';
import { body1, buttonText2 } from '../../../../../styles/typography';
import { AppendixDragIcon, AttachmentIconSvg, LockIcon } from '../../sections/Appendix/Appendix.icons';
import { AppendixNavBtnProps } from '../../sections/Appendix/Appendix.proptype';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    height: '2.75rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0.25rem',
    paddingLeft: '0.9rem',
    ...body1,
    overflowX: 'hidden'
  },
  btnContainer: {
    width: '100%',
    height: '100%',
    paddingTop: '0.7rem'
  },
  autoGeneratedBtn: {
    justifyContent: 'space-between'
  },
  addButton: {
    backgroundColor: tokens.neutral70,
    border: 'solid 1px',
    borderColor: tokens.neutral90,
    color: tokens.core2,
    ...buttonText2
  },
  appendixBtn: {
    backgroundColor: tokens.white,
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    border: 'none',
    color: tokens.core1
  },
  appendixBtnActive: {
    ...buttonText2,
    backgroundColor: tokens.product15,
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    border: 'solid 1px',
    borderColor: tokens.purpleLight2,
    color: tokens.themeAp100
  },
  textContainer: {
    width: '100%',
    textAlign: 'left',
    overflowX: 'hidden',
    marginLeft: '0.5rem'
  },
  dragIcon: {
    paddingLeft: '1rem',
    paddingRight: '0.875rem',
    cursor: 'grab'
  },
  lockIcon: {
    paddingLeft: '1rem',
    paddingRight: '0.3rem'
  }
}));

const generateText = (text: string) => {
  const charArray = text.split('');
  if (charArray.length > 33) {
    charArray.length = 33;
    let truncateText = charArray.join('');
    truncateText += '...';
    return truncateText;
  }

  return text;
};

export const AppendixNavBtn = ({ subText, addButton, appendixBtn, isAuto, active, action }: AppendixNavBtnProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const addButtonText = t('reports:configurationTab-Appendix-AppendixNavBtn-addAppendix');
  const autogeneratedButtonText = t('reports:configurationTab-Appendix-AppendixNavBtn-appendixAUTO');
  const defaultAppendixTitle = t('reports:configurationTab-Appendix-AppendixNavBtn-appendixDefaultTitle');

  const getMuiStylesClasses = useMemo(() => {
    return `
      ${classes.button} 
      ${addButton ? classes.addButton : ''} 
      ${appendixBtn ? classes.appendixBtn : ''} 
      ${active ? classes.appendixBtnActive : ''} 
      ${isAuto ? classes.autoGeneratedBtn : ''}
    `;
  }, [
    addButton,
    appendixBtn,
    active,
    isAuto,
    classes.addButton,
    classes.appendixBtn,
    classes.appendixBtnActive,
    classes.autoGeneratedBtn,
    classes.button
  ]);

  const buttonIcon = useMemo(() => {
    if (isAuto) {
      return (
        <Box className={classes.lockIcon}>
          <LockIcon active={active} />
        </Box>
      );
    }

    if (!addButton) {
      return (
        <Box className={classes.dragIcon}>
          <AppendixDragIcon active={active} />
        </Box>
      );
    }

    return <div />;
  }, [active, addButton, isAuto, classes]);

  return (
    <Box className={classes.btnContainer}>
      <button type="button" className={getMuiStylesClasses} onClick={action}>
        {!addButton && <AttachmentIconSvg active={active} />}
        <Box className={classes.textContainer}>
          <Typography>
            {addButton && addButtonText}
            {isAuto && autogeneratedButtonText}
            {!subText && !isAuto && !addButton && defaultAppendixTitle}
            {subText && generateText(subText)}
          </Typography>
        </Box>
        {buttonIcon}
      </button>
    </Box>
  );
};
