import { useMemo, useState } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LocalReportCreationModalProps } from './LocalReportCreationModal.proptypes';
import { ConfigurationTab } from '../LocalReportCreationTabs/ConfigurationTab';
import { EntitiesTab } from '../LocalReportCreationTabs/EntitiesTab';
import { SetupTab } from '../LocalReportCreationTabs/SetupTab';
import { SummariesTab } from '../LocalReportCreationTabs/SummariesTab';
import { TransactionsTab } from '../LocalReportCreationTabs/TransactionsTab';
import { LocalReportCreationTabsContainer } from '../LocalReportCreationTabsContainer/LocalReportCreationTabsContainer';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      height: '100%'
    }
  }
}));

enum Section {
  REPORT_SUMMARY = 0,
  LEGAL_ENTITIES = 1,
  CONTROLLED_TRANSACTIONS = 2
}

export const LocalReportCreationModal = ({
  title,
  jurisdictionId,
  jurisdictionName,
  stageCompleteness,
  onClose,
  onSubmit
}: LocalReportCreationModalProps) => {
  const styles = useStyles();
  const flags = useFlags();
  const [configurationSection, setConfigurationSection] = useState(Section.REPORT_SUMMARY);

  const updateConfigurationSectionSelected = (n: number) => {
    setConfigurationSection(n);

    return n;
  };

  const tabs = useMemo(() => {
    const activeTabs: typeof tabs = [];
    const activeFlags: Record<string, boolean> = {
      // add other flags if it's necessary.
      Setup: true,
      Summary: true,
      Entities: true,
      Transactions: true,
      Configuration: flags.tpiqUiNewReportConfigurationTab
    };

    const configurationProps = {
      jurisdictionName,
      selectedSection: configurationSection,
      emitSection: updateConfigurationSectionSelected
    };

    const tabs = [
      {
        key: 'Setup',
        label: 'Setup',
        component: <SetupTab jurisdictionId={jurisdictionId} />
      },
      {
        key: 'Entities',
        label: 'Entities',
        component: <EntitiesTab jurisdictionId={jurisdictionId} />
      },
      {
        key: 'Transactions',
        label: 'Transactions',
        component: <TransactionsTab />
      },
      {
        key: 'Summary',
        label: 'Summary',
        component: <SummariesTab />
      },
      {
        key: 'Configuration',
        label: 'Configuration',
        component: <ConfigurationTab {...configurationProps} />
      }
    ];

    tabs.forEach((tab) => {
      if (activeFlags[tab.key]) {
        activeTabs.push(tab);
      }
    });

    return activeTabs;
  }, [configurationSection, flags.tpiqUiNewReportConfigurationTab, jurisdictionId, jurisdictionName]);

  return (
    <Dialog fullWidth disableEnforceFocus open className={styles.root} maxWidth="lg" onClose={onClose}>
      <LocalReportCreationTabsContainer
        title={title}
        jurisdictionName={jurisdictionName}
        stageCompleteness={stageCompleteness}
        tabs={tabs}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
};
