import { Container, Currency, CurrencyType, Language, LocalReportData } from '../../../models';

export interface UploadReportInfo {
  reportTitle: string;
  currency: string;
  language: string;
  file: File;
}

export interface UploadReportModalProps {
  open: boolean;
  currencyOptions: CurrencyType[];
  languageOptions: Array<Partial<Language>>;
  setIsUploadReportModalOpen: (open: boolean) => void;
  executeUploadReport: (data: UploadReportPayloadProps, file: File, reportInfo: LocalReportData) => void;
  reportInfo: Partial<LocalReportData>;
  container: Container;
  upeCurrency: Currency;
  defaultCurrency: Currency;
  isUploadingReport: boolean;
}

export interface UploadReportPayloadProps {
  reportStage: string;
  currencyId: number;
  internalLocalfileReportId: number;
  primaryEntityId: number;
  tradingPartnerEntityId: number;
  primaryEntityIds: number[];
  tradingPartnerIds: number[];
  executiveSummaryIntro: string;
  executiveSummaryConclusion: string;
  orgChart?: string;
  languageIso: string;
  containerId: number;
  isUploadedReport: boolean;
  selectedTransactionIds: number[];
  mimeType: string;
  fileExtension: string;
}

export interface GetSignedUrlAppendixFilePayload {
  containerId: number;
  mimeType: string;
  fileExtension: string;
}

export const mimeTypePDF = 'application/pdf';
export const fileExtensionPDF = '.pdf';
export const defaultReportStage = 'Draft';
