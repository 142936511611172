import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FinancialConversion } from '../../models';
import { getWorkingContainer } from '../baseData/thunks/baseData';

interface ConversionRatesState {
  rates: FinancialConversion[] | null;
  error?: string;
}

const initialState: ConversionRatesState = { rates: null };

const conversionRatesSlice = createSlice({
  name: 'conversionRates',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addMatcher(
        (action) => action.type.match(/^conversionRates\/.+\/pending$/),
        (state: ConversionRatesState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^conversionRates\/.+\/fulfilled$/),
        (state: ConversionRatesState, action: PayloadAction<FinancialConversion[]>) => {
          state.rates = action.payload;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^conversionRates\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default conversionRatesSlice.reducer;
