import { InternalLocalFileSofEntitiesVisibility as Selection } from '@xbs/xbs-enums';
import {
  ControlledTransactionsDocumentationConfig,
  TransactionItem
} from '../../../../redux/localReports/localReports.proptype';

export function createIncludeAllTransactionsConfig(
  transactions: Array<Partial<TransactionItem>>
): ControlledTransactionsDocumentationConfig {
  return {
    optionSelected: Selection.ByName.IncludeAll.Id,
    transactionsSelectedIds: transactions.map((t) => t.transactionId!)
  };
}
