import { useSelector } from 'react-redux';
import { selectedEntityStatementOfFactsConfigForReportPayload } from '../../selectors';

export const useEntityStatementOfFactsConfigForReportPayload = (): ReturnType<
  typeof selectedEntityStatementOfFactsConfigForReportPayload
> => {
  const selectedEntityStatementOfFactsConfig = useSelector(selectedEntityStatementOfFactsConfigForReportPayload);

  return selectedEntityStatementOfFactsConfig;
};
