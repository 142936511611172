import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import tokens from '../../../../../../styles/designTokens';
import { body1, captionOverline, title1, title2 } from '../../../../../../styles/typography';
import { SelectTable } from '../../../components/SelectTable/SelectTable';
import { TableColumn } from '../../../ConfigurationTab.proptype';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  header: {
    marginTop: '2.5rem'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    ...title2,
    color: tokens.core3
  },
  chip: {
    ...captionOverline,
    color: tokens.product100,
    textAlign: 'center',
    backgroundColor: tokens.product25,
    marginLeft: '1rem'
  },
  subTitle: {
    ...body1,
    marginTop: '0.4rem',
    color: tokens.core1
  },
  tableContainer: {
    marginTop: '2.5rem',
    with: '70%'
  },
  tableTitle: {
    ...title1,
    color: tokens.core3
  }
}));

export const AppendixAutoGenerated = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const tableTitle = (
    <Typography className={classes.tableTitle}>
      {t('reports:configurationTab-Appendix-AppendixAutogenerated-tableTitle')}
    </Typography>
  );

  const tableColumns: TableColumn[] = [{ key: 'pba', header: '', width: '100%' }];

  const tableData = useMemo(
    () => [
      { pba: 'PBA - Descriptions of Accepted Comparable Companies' },
      { pba: 'PBA - Financials of Accepted Comparable Companies' },
      { pba: 'PBA - Rejected Comparables' }
    ],
    []
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>
            {t('reports:configurationTab-Appendix-AppendixAutogenerated-title')}
          </Typography>
        </Box>
        <Typography className={classes.subTitle}>
          {t('reports:configurationTab-Appendix-AppendixAutogenerated-appendixDescription')}
        </Typography>
      </Box>

      <Box className={classes.tableContainer}>
        <SelectTable columns={tableColumns} title={tableTitle} rows={tableData} />
      </Box>
    </Box>
  );
};
