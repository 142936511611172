import { useEffect } from 'react';
import { NoSessionMessage } from '../../components';
import { AUTH_REFRESH_TIME_WINDOW_MILLISECONDS } from '../../constants';
import httpService from '../../services/http';
import {
  getAppConfig,
  getAuthInfo,
  isUserAuthenticated,
  logUserLogout,
  redirectToLogin,
  setAuthTokens
} from '../../utils';

export const Authentication = ({ children }: { children: JSX.Element }) => {
  const authInitialState = getAuthInfo();
  const config = getAppConfig();
  const shouldRenderPage = isUserAuthenticated();

  let timerId: number | undefined;
  // pre-emptively refresh the token 20 min before expiration
  useEffect(() => {
    if (!timerId && shouldRenderPage) {
      const refreshToken = async () => {
        const newAuthTokens = await httpService.refreshToken();
        setAuthTokens(newAuthTokens);
      };

      const now = Date.now();

      const nextRefreshMilliseconds = authInitialState.expiration - AUTH_REFRESH_TIME_WINDOW_MILLISECONDS;

      if (now > nextRefreshMilliseconds) {
        void refreshToken();
      } else {
        // ESLint is complaining about this change, even though this code executed once
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timerId = window.setTimeout(() => {
          void refreshToken();
        }, nextRefreshMilliseconds - now);

        return () => {
          clearTimeout(timerId);
        };
      }
    }
  }, [authInitialState.expiration, timerId]);

  if (shouldRenderPage) {
    return children;
  }

  // If the last time they refreshed was not today, kick them back to login
  if (config.xbsEnvironment === 'develop') {
    void logUserLogout('last_time_token_refresh_not_current_day');
    void redirectToLogin();
  }

  return <NoSessionMessage />;
};
