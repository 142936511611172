export const TP_TITLE = 'CrossBorder DIY';
export const REFRESH_URL = 'v1/refresh';
export const LOGIN_URL = 'https://login-ui.devxbs.com/';
export const PLATFORM_CONTAINER_CHANGED = 'XBS-Container-Changed';

export const AUTH_REFRESH_TIME_WINDOW_MILLISECONDS = 20 * 60 * 1000;
export const NOTIFICATION_SNACKBAR_DURATION = 30000;
export const NOTIFICATION_SNACKBAR_MAX_COUNT = 10;
export const TEXT_EDITOR_MAX_SIZE = 4194304;

export const MAIL_FORMAT = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

export const EMAIL_CANCELLED = 2; // '2' is the emailStatusId corresponding to when the email is 'CANCELLED'

export const EMAIL_STATUSES = {
  PENDING: 1,
  CANCELED: 2,
  FAILED: 3,
  SUCCESS: 4
};

export enum FetchLoadingStateEnum {
  loading = 'loading',
  fulfilled = 'fulfilled',
  error = 'error',
  reload = 'reload'
}
