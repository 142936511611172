import { AppendixProps } from './Appendix.proptype';
import { AppendixAutoGenerated } from './AppendixAutoGenerated';
import { AppendixGenerate } from './AppendixGenerate';
import { useCustomAppendices } from '../../../../../hooks/custom-appendices/useCustomAppendices';

export const Appendix = ({ appendixConfigState }: AppendixProps) => {
  const customAppendices = useCustomAppendices();
  const appendix = customAppendices.find((appendix) => appendix.identifier === appendixConfigState.currentAppendix);
  const isAppendixAutoGenerated = appendixConfigState.currentAppendix === '';

  if (isAppendixAutoGenerated || appendix === undefined) {
    return <AppendixAutoGenerated />;
  }

  return <AppendixGenerate appendix={appendix} />;
};
