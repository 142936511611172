import { ReactChild, ReactNode } from 'react';
import { Box, Chip, makeStyles, Accordion, AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@mui/material';
import { OpenTableIcon } from './ArrowDownIcon';
import tokens from '../../../../../styles/designTokens';
import { body1, captionOverline, title1 } from '../../../../../styles/typography';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: '0 0 4px 0 rgba(0,0,0,0.12)',
    '& .MuiAccordion-root': {
      boxShadow: '0 0 4px 0 rgba(0,0,0,0.12)',
      backgroundColor: tokens.white
    },
    '& .MuiAccordionSummary-root': {
      height: '4rem'
    }
  },
  selectTransactions: {
    alignItems: 'center',
    display: 'flex'
  },
  wrapperTitle: {
    padding: '1rem 0'
  },
  title: {
    ...title1,
    color: tokens.core3,
    display: 'block'
  },
  subtitle: {
    ...body1,
    color: tokens.core2
  },
  counter: {
    '& .MuiChip-root': {
      borderRadius: '4px',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product15,
      marginLeft: '0.5rem'
    }
  },
  accordionDetails: {
    paddingBottom: '2.25rem'
  }
}));

export interface ActionTableProps {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  chip?: string;
  children: ReactChild;
  isOpen: boolean;
  setIsOpen: () => void;
  id: string;
}

export const ActionTable = ({ id, title, subtitle, chip, children, isOpen, setIsOpen }: ActionTableProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Accordion
        id={id}
        className={classes.root}
        expanded={isOpen}
        TransitionProps={{ unmountOnExit: true, appear: false, enter: true, exit: true }}
        onChange={setIsOpen}
      >
        <AccordionSummary expandIcon={<OpenTableIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box className={classes.selectTransactions}>
            <Box className={classes.wrapperTitle}>
              <span className={classes.title}>{title}</span>
              {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
            </Box>
            {chip && (
              <Box className={classes.counter}>
                <Chip size="small" label={chip} />
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};
