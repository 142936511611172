import { createSelector } from '@reduxjs/toolkit';
import { InternalLocalFileSofEntitiesVisibility as Selection } from '@xbs/xbs-enums';
import { ReportSummaryCoreSection } from '../models/reports.interface';
import { RootState } from '../redux';
import {
  PrimaryEntityItem,
  TradingPartnerItem,
  TransactionItem,
  SelectedTransactionOrdering,
  EntityStatementOfFactsConfig,
  ControlledTransactionsDocumentationConfig,
  PBAItem
} from '../redux/localReports/localReports.proptype';

export const selectLocalReportsData = (state: RootState) => state.localReports;

export const selectLanguageList = (state: RootState) => state.localReports.languageList;

export const selectTemplateList = (state: RootState) => state.localReports.templateList;

export const selectSetupData = (state: RootState) => state.localReports.setup;

export const selectSummariesData = (state: RootState) => state.localReports.summaries;

export const selectReportSummary = (state: RootState) => state.localReports.configurationTab.reportSummary;

export const selectContainerPolicies = (state: RootState) => state.localReports.containerPolicies;

export const selectReportTitle = createSelector(selectSetupData, (setup) => setup.reportTitle);

export const selectReportSummaryReportSectionsCoreSections = createSelector(
  selectReportSummary,
  (reportSummary) => reportSummary.reportSections.core
);

export const selectCustomizedReportSummarySections = createSelector(
  selectReportSummaryReportSectionsCoreSections,
  (sections: ReportSummaryCoreSection[]) => {
    return sections.filter((section) => section.active).length < sections.length;
  }
);

export const selectIsConfigurationTabEnabled = createSelector(
  selectReportSummaryReportSectionsCoreSections,
  (sections) => sections.some((section) => section.active)
);

export const selectReportSummaryReportSectionsIsOpen = createSelector(selectReportSummary, (reportSummary) => {
  return reportSummary.isReportsSectionOpen;
});

export const selectReportSummaryEntitiesIsOpen = createSelector(selectReportSummary, (reportSummary) => {
  return reportSummary.isEntitiesOpen;
});

export const selectReportSummaryTransactionsIsOpen = createSelector(selectReportSummary, (reportSummary) => {
  return reportSummary.isTransactionsOpen;
});

export const selectPrimaryTradingPartnersMap = (state: RootState) => state.localReports.primaryTradingPartnersMap;

export const selectedPrimaryTradingPartnersMapIsCreating = (state: RootState) =>
  state.localReports.primaryTradingPartnersMapIsCreating;

export const selectPrimaryEntitiesList = createSelector(selectPrimaryTradingPartnersMap, (primaryTradingPartnersMap) =>
  primaryTradingPartnersMap ? (Object.values(primaryTradingPartnersMap) as PrimaryEntityItem[]) : []
);

export const selectSelectedPrimaryEntities = createSelector(
  selectPrimaryEntitiesList,
  (primaryEntities: PrimaryEntityItem[]): PrimaryEntityItem[] => primaryEntities.filter((entity) => entity.selected)
);

export const selectedEntityStatementOfFactsConfig = (state: RootState) =>
  state.localReports.configurationTab.entityStatementOfFactsConfig;

export const selectCustomizedEntityStatementOfFactsConfig = createSelector(
  selectedEntityStatementOfFactsConfig,
  (config: EntityStatementOfFactsConfig) => {
    return config.optionSelected !== Selection.ByName.IncludeAll.Id || !config.displayOrgChart;
  }
);

export const selectedEntityStatementOfFactsConfigForReportPayload = createSelector(
  selectedEntityStatementOfFactsConfig,
  (entityStatementOfFactsConfig: EntityStatementOfFactsConfig | undefined) => {
    return {
      showUPE: entityStatementOfFactsConfig!.showUPE,
      optionSelected: entityStatementOfFactsConfig!.optionSelected,
      entitiesSelected: entityStatementOfFactsConfig!.entitiesSelected,
      displayOrgChart: entityStatementOfFactsConfig!.displayOrgChart
    };
  }
);

export const selectControlledTransactionsDocumentationConfig = (state: RootState) => {
  return state.localReports.configurationTab.controlledTransactionsDocumentationConfig;
};

export const selectCustomizedControlledTransactions = createSelector(
  selectControlledTransactionsDocumentationConfig,
  (config: ControlledTransactionsDocumentationConfig) => {
    return config.optionSelected !== Selection.ByName.IncludeAll.Id;
  }
);

export const selectProfitBasedAnalysisConfig = (state: RootState) => {
  return state.localReports.configurationTab.profitBasedAnalysisConfig;
};

export const selectAppendixConfigState = (state: RootState) => {
  return state.localReports.configurationTab.appendixConfigState;
};

export const selectCustomAppendices = (state: RootState) => {
  return state.localReports.configurationTab.reportSummary.reportSections.appendix;
};

export const selectLocalReportsLoadingState = (state: RootState) => {
  return state.localReports.loading;
};

export const selectTradingPartnersList = createSelector(
  selectSelectedPrimaryEntities,
  (primaryEntities: PrimaryEntityItem[]): TradingPartnerItem[] => {
    const tradingPartners: TradingPartnerItem[] = [];

    for (const primaryEntity of primaryEntities) {
      tradingPartners.push(...Object.values(primaryEntity.tradingPartners));
    }

    return tradingPartners.filter(
      (tradingPartner, index, self) => index === self.findIndex((item) => item.entityId === tradingPartner.entityId)
    );
  }
);

export const selectTradingPartnersListWithDuplicates = createSelector(
  selectSelectedPrimaryEntities,
  (primaryEntities: PrimaryEntityItem[]): TradingPartnerItem[] => {
    const tradingPartners: TradingPartnerItem[] = [];

    for (const primaryEntity of primaryEntities) {
      tradingPartners.push(...Object.values(primaryEntity.tradingPartners));
    }

    return tradingPartners;
  }
);

export const selectSelectedTradingPartners = createSelector(
  selectTradingPartnersList,
  (tradingPartners: TradingPartnerItem[]): TradingPartnerItem[] => tradingPartners.filter((entity) => entity.selected)
);

export const selectSelectedTradingPartnersWithDuplicates = createSelector(
  selectTradingPartnersListWithDuplicates,
  (tradingPartners: TradingPartnerItem[]): TradingPartnerItem[] => tradingPartners.filter((entity) => entity.selected)
);

export const selectLocalFileReports = (state: RootState) => state.localReports.localFileReports;

export const selectLocalFileReportsConfig = (state: RootState) =>
  state.localReports.configurationTab.entityStatementOfFactsConfig;

export const selectWorkingLocalFileReports = (state: RootState) => state.localReports.workingLocalFiles;

export const selectFinalLocalFileReports = (state: RootState) => state.localReports.finalLocalFiles;

export const selectTransactionsListByTradingPartners = createSelector(
  selectSelectedTradingPartnersWithDuplicates,
  (tradingPartners: TradingPartnerItem[]): TransactionItem[] => {
    const transactions: TransactionItem[] = [];

    tradingPartners.forEach((trading) => {
      for (const transaction of trading.transactions) {
        const isTxAlreadyExist = transactions.some((tx) => tx.transactionId === transaction.transactionId);
        if (!isTxAlreadyExist) {
          transactions.push(transaction);
        }
      }
    });

    return transactions;
  }
);

export const selectSelectedTransactions = createSelector(
  selectTransactionsListByTradingPartners,
  (transactions: TransactionItem[]): TransactionItem[] => transactions.filter((trans) => trans.selected)
);

export const selectPBAsListBySelectedTransactions = createSelector(
  selectSelectedTransactions,
  (transactions: TransactionItem[]): PBAItem[] => {
    const pbas: PBAItem[] = [];

    transactions.forEach((transaction) => {
      for (const txPBA of transaction.pbas) {
        const isPBAAlreadyExists = pbas.some((pba) => txPBA.pbaId === pba.pbaId);
        if (!isPBAAlreadyExists) {
          pbas.push(txPBA);
        }
      }
    });

    return pbas;
  }
);

export const selectSelectedPBAs = createSelector(selectPBAsListBySelectedTransactions, (pbas: PBAItem[]): PBAItem[] =>
  pbas.filter((pba) => pba.selected)
);

export const selectOrderedTransactions = createSelector(
  selectSelectedTransactions,
  (transactions: TransactionItem[]): SelectedTransactionOrdering => {
    const transactionOrdering: SelectedTransactionOrdering = {};
    transactions.forEach((transaction) => {
      const id = transaction.transactionId;
      const { ordering } = transaction;
      transactionOrdering[id] = ordering;
    });
    return transactionOrdering;
  }
);

export const selectTest = (state: RootState) => state.localReports;

export const selectActiveReport = (state: RootState) => state.localReports.activeReport;

export const selectReportVersions = (state: RootState) => state.localReports.reportVersions;
