import ReactGA from 'react-ga4';
import { getPlatformModule } from '../services/platform/platform';

export interface GoogleAnalyticsPayload {
  /* eslint-disable camelcase */
  event_category: string;
  event_label: string;
  container_id: number | undefined;
  userUuid?: string;
}

export const logGoogleAnalyticsEvent = async (eventInfo: GoogleAnalyticsPayload) => {
  const { IdentityInfo } = await getPlatformModule();
  const session = await IdentityInfo.getIdentitySession();
  const { userUuid } = session;

  const payload = {
    userUuid,
    ...eventInfo
  };

  ReactGA.event(eventInfo.event_category, payload);
};
