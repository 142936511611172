import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getAppConfig, logUserLogout, redirectToLoading, redirectToLogin } from '../../utils';
import useDebounce from '../../utils/debounce';
import { CenteredProgress } from '../index';

export const NoContainersMessage = () => {
  const { t } = useTranslation();
  const config = getAppConfig();
  const [debounceTime] = useDebounce(() => {
    void logUserLogout('no_container_found_on_account');
    if (config.xbsEnvironment === 'develop') {
      void redirectToLogin();
    } else {
      redirectToLoading();
    }
  }, 10000);

  useEffect(() => {
    debounceTime();
  });

  return (
    <Box>
      <Alert severity="error">{t('common:message-no-containers')}</Alert>
      <CenteredProgress />
    </Box>
  );
};
