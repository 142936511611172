import { ReactNode, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import {
  Table as MUITable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  useTheme,
  TableCellProps as MaterialUiTableCellProps
} from '@material-ui/core';
import { DragIcon } from './DragIcon';
import { selectWorkingContainer } from '../../selectors';
import tokens from '../../styles/designTokens';
import { getRandomId } from '../../utils';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';

interface TableColumnDef {
  key: string;
  header?: ReactNode;
  align?: MaterialUiTableCellProps['align'];
}
interface TableDNDProps {
  data: any[];
  selected: Set<number>;
  columns: TableColumnDef[];
  orderedList: (items: any[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      left: 'initial',
      zIndex: 1
    },
    '& .MuiTableCell-root': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
      whiteSpace: 'nowrap',

      '&.MuiTableCell-head': {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightRegular,
        whiteSpace: 'normal'
      },

      '&.MuiTableCell-body': {
        height: 45,
        padding: theme.spacing(0, 1),

        '&:first-child': {
          fontWeight: theme.typography.fontWeightMedium
        },

        '& .MuiIconButton-root': {
          color: theme.palette.secondary.dark,
          padding: theme.spacing(0.5)
        }
      }
    },
    overflowX: 'auto'
  }
}));

export const TableDND = (props: TableDNDProps) => {
  const [listItems, setListItems] = useState<any[]>([]);
  const theme = useTheme();
  const classes = useStyles(theme);
  const [movingCellsClassName] = useState(getRandomId());
  const workingContainer = useSelector(selectWorkingContainer);

  const onDragEnd = (result: any) => {
    /* eslint-disable camelcase */
    void logGoogleAnalyticsEvent({
      event_category: 'report_transactions_order_click',
      event_label: `Report transactions tab order click`,
      container_id: workingContainer?.containerId
    });

    const { source, destination } = result;
    const items = listItems;

    if (listItems.length > 0 && source?.index >= 0 && destination?.index >= 0) {
      const item = items.splice(source.index, 1).pop();
      items.splice(destination.index, 0, item);
      props.orderedList(items);
      setListItems(items);
    }
  };

  const renderCells = (row?: any) => {
    const cells = [] as ReactNode[];
    const columnIndex = props.columns.length - 1;

    if (!row) {
      cells.push(<TableCell key="rowIndexCell" align={props.columns[0].align} />);
    }

    props.columns.forEach(({ key, header, align }: any, i: number) => {
      cells.push(
        <TableCell
          key={row ? Number(row.rowId) + i : key}
          align={align}
          className={row ? undefined : movingCellsClassName}
        >
          {row ? row[key] : header}
        </TableCell>
      );
    });

    cells.push(
      <TableCell
        key={row ? Number(row.rowId) + props.columns.length : 'DragIcon'}
        align={props.columns[columnIndex].align}
      >
        {row ? <DragIcon /> : ''}
      </TableCell>
    );

    return cells;
  };

  const renderColgroup = useMemo(() => {
    const cols: any[] = [];

    cols.push(<col key="indexT" style={{ width: '10px' }} />);

    props.columns.forEach(({ width }: any, i: number) => {
      // eslint-disable-next-line react/no-array-index-key
      cols.push(<col key={String(i)} style={width ? { minWidth: width, width } : {}} />);
    });

    return <colgroup>{cols}</colgroup>;
  }, [props.columns]);

  const showItems = () => {
    const rowIndexStyles = {
      backgroundColor: tokens.product15,
      color: tokens.product100,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '30px',
      height: '30px',
      margin: '5px'
    };
    return listItems.map((item: any, i: number) => (
      <Draggable key={item.rowId} index={i} draggableId={`id: ${String(item.rowId)}`}>
        {(provided) => (
          <TableRow
            key={`t: ${item.rowId | i}`}
            data-testid="testing"
            innerRef={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TableCell>
              <div style={rowIndexStyles}>{item.ordering}</div>
            </TableCell>
            {renderCells(item)}
          </TableRow>
        )}
      </Draggable>
    ));
  };

  const orderIncomingTransaction = useMemo(() => {
    const selectedTransactions = props.data.filter((t: any) => props.selected.has(t.rowId));

    const transactionOrdered = selectedTransactions.sort((a: any, b: any) => a.ordering - b.ordering);

    let i = 0;
    let n = 0;
    let indexT: any = {};
    while (i < transactionOrdered.length) {
      if (transactionOrdered[n].ordering) {
        n++;
      } else {
        indexT = transactionOrdered.splice(n, 1).pop();
        transactionOrdered.push(indexT);
      }

      i++;
    }

    return transactionOrdered;
  }, [props]);

  useEffect(() => {
    setListItems(orderIncomingTransaction);
  }, [orderIncomingTransaction]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={getRandomId()}>
        {(provider) => (
          <MUITable stickyHeader className={`${classes.root} `}>
            {renderColgroup}

            <TableHead>
              <TableRow key="Header Row">{renderCells()}</TableRow>
            </TableHead>

            <TableBody {...provider.droppableProps} innerRef={provider.innerRef}>
              {showItems()}
              {provider.placeholder}
            </TableBody>
          </MUITable>
        )}
      </Droppable>
    </DragDropContext>
  );
};
