import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Divider } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GenericDropDown, GenericDropDownItem } from '../../../components/GenericDropDown';
import { useWorkingContainer } from '../../../hooks/container/useWorkingContainer';
import { selectWorkingContainer } from '../../../selectors';
import tokens from '../../../styles/designTokens';
import { DownloadIcon, NewVersionIcon, ReportConvert } from '../../../svgs';
import { canDownloadReport, hasEditAccess, canDownloadWordReport, hasAccess } from '../../../utils';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticsEvent';
import { ReportStatus } from '../LocalReports.proptype';

export interface ActionsDropDownProps {
  anchorEl: Element | null;
  isFailedReport: boolean;
  onClose: () => void;
  onNewReportVersion: () => void;
  onDownloadPDF: () => void;
  onConvertWord: () => void;
  onDownloadWord: () => void;
  onManageVersions: () => void;
  openReportModal: () => void;
  startDeleteReport: () => void;
  status: ReportStatus;
  wordStatus: number;
  wordReportBinaryPath?: string;
}

export const ActionsDropDown = ({
  anchorEl,
  isFailedReport,
  onClose,
  onNewReportVersion,
  onDownloadPDF,
  onConvertWord,
  onDownloadWord,
  onManageVersions,
  openReportModal,
  startDeleteReport,
  status,
  wordStatus,
  wordReportBinaryPath
}: ActionsDropDownProps) => {
  const flags = useFlags();
  const container = useWorkingContainer();
  const workingContainer = useSelector(selectWorkingContainer);

  const { t } = useTranslation();

  let items: Array<GenericDropDownItem | ReactNode> = [];

  if (!hasEditAccess()) {
    if (canDownloadReport(status)) {
      items.push({
        text: t('reports:action-download-pdf'),
        icon: <DownloadIcon style={{ color: tokens.core2 }} />,
        onClick: () => {
          /* eslint-disable camelcase */
          void logGoogleAnalyticsEvent({
            event_category: 'report_repository_download_pdf_button_click',
            event_label: `Report repository download pdf button click`,
            container_id: workingContainer?.containerId
          });
          onDownloadPDF();
        }
      });
    }

    if (canDownloadWordReport(wordStatus)) {
      items.push({
        text: t('reports:action-download-word'),
        icon: <DownloadIcon style={{ color: tokens.core2 }} />,
        onClick: () => {
          void logGoogleAnalyticsEvent({
            event_category: 'report_repository_download_word_file_button_click',
            event_label: `Report repository download word file button click`,
            container_id: workingContainer?.containerId
          });
          onDownloadWord();
        }
      });
    }

    items.push({
      text: t('reports:action-manage-versions'),
      onClick: () => {
        void logGoogleAnalyticsEvent({
          event_category: 'report_repository_manage_versions_button_click',
          event_label: `Report repository manage versions button click`,
          container_id: workingContainer?.containerId
        });
        onManageVersions();
      }
    });
  } else if (hasEditAccess()) {
    items = [
      {
        text: t('reports:action-new-report-version'),
        icon: <NewVersionIcon style={{ color: tokens.core2 }} />,
        onClick: () => {
          void logGoogleAnalyticsEvent({
            event_category: 'report_repository_new_report_version_button_click',
            event_label: `Report repository new report version button click`,
            container_id: workingContainer?.containerId
          });
          onNewReportVersion();
          onClose();
        }
      },
      {
        text: t('reports:action-download-pdf'),
        icon: <DownloadIcon style={{ color: tokens.core2 }} />,
        disabled: isFailedReport,
        onClick: () => {
          void logGoogleAnalyticsEvent({
            event_category: 'report_repository_download_pdf_button_click',
            event_label: `Report repository download pdf button click`,
            container_id: workingContainer?.containerId
          });
          onDownloadPDF();
          onClose();
        }
      },
      hasAccess(flags.reportsConvertToWordOption, container?.containerId)
        ? canDownloadWordReport(wordStatus, wordReportBinaryPath)
          ? {
              text: t('reports:action-download-word'),
              icon: <DownloadIcon style={{ color: tokens.core2 }} />,
              disabled: isFailedReport,
              onClick: () => {
                void logGoogleAnalyticsEvent({
                  event_category: 'report_repository_download_word_file_button_click',
                  event_label: `Report repository download word file button click`,
                  container_id: workingContainer?.containerId
                });
                onDownloadWord();
                onClose();
              }
            }
          : {
              text: t('reports:action-convert-word'),
              icon: <ReportConvert style={{ color: tokens.core2 }} />,
              disabled: isFailedReport,
              onClick: () => {
                void logGoogleAnalyticsEvent({
                  event_category: 'report_repository_convert_word_file_button_click',
                  event_label: `Report repository convert word file button click`,
                  container_id: workingContainer?.containerId
                });
                onConvertWord();
                onClose();
              }
            }
        : null,
      <Divider key="divider" variant="middle" />,
      {
        text: t('reports:action-manage-versions'),
        onClick: () => {
          void logGoogleAnalyticsEvent({
            event_category: 'report_repository_manage_versions_button_click',
            event_label: `Report repository manage versions button click`,
            container_id: workingContainer?.containerId
          });
          onManageVersions();
          onClose();
        }
      },
      {
        text: t('reports:action-upload-version'),
        onClick: () => {
          void logGoogleAnalyticsEvent({
            event_category: 'report_repository_upload_version_button_click',
            event_label: `Report repository upload version button click`,
            container_id: workingContainer?.containerId
          });
          openReportModal();
          onClose();
        }
      },
      {
        text: t('reports:action-delete-report'),
        onClick: () => {
          startDeleteReport();
          onClose();
        }
      }
    ].filter((item) => item !== null); // Added filter for feature flags addition
  }

  return (
    <GenericDropDown
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      items={items}
      anchorEl={anchorEl}
      minWidth="12.313rem"
      onClose={onClose}
    />
  );
};
