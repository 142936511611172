import { createAsyncThunk } from '@reduxjs/toolkit';
import { format as formatDate } from 'date-fns';
import { RootState } from '../..';
import { Entity } from '../../../models';
import { selectCountry, selectUPE } from '../../../selectors';
import httpService from '../../../services/http';

export const fetchEntities = createAsyncThunk<Entity[], void, { rejectValue: Error }>(
  'entities/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: Entity[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'entities',
          params: { sort: 'code', order: 'asc' }
        })
      ).data.data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const saveEntity = createAsyncThunk<Entity, Partial<Entity>, { state: RootState; rejectValue: Error }>(
  'entities/save',
  async (saved, { getState, rejectWithValue }) => {
    try {
      const { entityId, domicile, taxJurisdiction, parentEntities, primaryFunction, upeCurrency } = saved;
      const UPE = selectUPE(getState());
      // A quirk of the API is that the domicile fields in the entities are missing their default jurisdictions
      const domicileWithDefaultJurisdiction = domicile ? selectCountry(domicile.countryId)(getState()) : domicile;
      const fiscalYearEnd = saved.fiscalYearEnd ? new Date(saved.fiscalYearEnd) : new Date();
      const {
        data: { data }
      } = await httpService.request<{ data: Entity }>({
        method: entityId ? 'patch' : 'post',
        apiUrlKey: 'baseUrl',
        relativePath: entityId ? `entities/${entityId}` : 'entities',
        data: {
          ...saved,
          fiscalYearEnd: formatDate(fiscalYearEnd, 'yyyy-MM-dd'),
          domicile: domicileWithDefaultJurisdiction,
          upe: UPE === undefined || UPE.entityId === entityId
        }
      });

      // restore related data from saved entity because API only returns ids
      if (domicileWithDefaultJurisdiction) {
        data.domicile = domicileWithDefaultJurisdiction;
      }

      if (primaryFunction) {
        data.primaryFunction = primaryFunction;
      }

      if (taxJurisdiction) {
        data.taxJurisdiction = taxJurisdiction;
      }

      if (parentEntities) {
        data.parentEntities = parentEntities;
      }

      if (upeCurrency) {
        data.upeCurrency = upeCurrency;
      }

      return data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const deleteEntity = createAsyncThunk<number, number, { rejectValue: Error }>(
  'entities/delete',
  async (entityId, { rejectWithValue }) => {
    try {
      await httpService.request<{ result: { entityId: number } }>({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `entities/${entityId}`
      });
      return entityId;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);
