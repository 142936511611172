import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../services/http';
import { LanguageIsoInfo } from '../localReports.proptype';

export const fetchLanguages = createAsyncThunk<
  LanguageIsoInfo[],
  { jurisdictionId: number; taxYear: number },
  { rejectValue: Error }
>('localReports/fetchLanguages', async (payload, { rejectWithValue }) => {
  try {
    /* eslint-disable camelcase */
    return (
      await httpService.request<{ data: Array<{ language_iso: string }> }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/compsearch/jurisdiction/${payload.jurisdictionId}/tax-year/${payload.taxYear}/languages`
      })
    ).data.data.map((lang) => {
      return {
        isoCode: lang.language_iso
      };
    });
    /* eslint-enable camelcase */
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});
