export const ArrowDownIcon = () => (
  <svg height="25" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.17 17.17l-9.17 9.17-9.17-9.17-2.83 2.83 12 12 12-12z" />
    <path d="M0 0h48v48h-48z" fill="none" />
  </svg>
);

export const CheckIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>D5E302D5-0F86-41CE-BC28-40A2EF66791B</title>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-229.000000, -2315.000000)" id="Icon/Tick-Small">
        <g transform="translate(229.000000, 2315.000000)" id="Icon/Tick">
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
          <polygon
            id="Combined-Shape"
            fill="#3C2A8E"
            points="9.83947754 13.6682373 7.41550293 11.2442627 6 12.6287598 9.83947754 16.4682373 16.9215088 9.38620605 15.5077148 8"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const CrossIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>41509AFF-8347-41E0-AEC1-989782129650</title>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-74.000000, -2315.000000)" id="Icon/RejectedCross-Filled">
        <g transform="translate(74.000000, 2315.000000)">
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
          <path
            d="M12,3 C16.968,3 21,7.032 21,12 C21,16.968 16.968,21 12,21 C7.032,21 3,16.968 3,12 C3,7.032 7.032,3 12,3 Z M14.5455844,8.18162338 L12,10.727 L9.45441559,8.18162338 L8.18162338,9.45441559 L10.727,12 L8.18162338,14.5455844 L9.45441559,15.8183766 L12,13.273 L14.5455844,15.8183766 L15.8183766,14.5455844 L13.273,12 L15.8183766,9.45441559 L14.5455844,8.18162338 Z"
            id="Shape"
            fill="#8E949F"
          />
        </g>
      </g>
    </g>
  </svg>
);
