import { useEffect, useState, useCallback, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, makeStyles, Typography, Checkbox } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { EmailReportFormProps } from './EmailReportForm.proptypes';
import { Editor } from '../../../../components';
import { MAIL_FORMAT } from '../../../../constants';
import { getEmailTemplate } from '../../../../redux/reports/thunks/reports';
import { AppDispatch } from '../../../../store';
import designTokens from '../../../../styles/designTokens';
import useDebounce from '../../../../utils/debounce';

const useStyles = makeStyles(() => ({
  formInput: {
    border: 'none',
    outline: 'none',
    width: '100%',
    paddingInlineStart: '0.5rem',
    fontSize: '1rem'
  },
  formRow: {
    position: 'relative',
    alignItems: 'start',
    padding: '1rem',
    borderBottom: `1px solid ${designTokens.neutral80}`,
    gridTemplateColumns: '30px 1fr',

    '& input': {
      gridColumn: '1/3'
    }
  },
  emailBodyWrapper: {
    margin: '1rem'
  },
  emailBodyDefaultText: {
    border: `1px dashed ${designTokens.product25}`
  },
  editDefaultBtn: {
    fontFamily: designTokens.mediumFont.fontFamily,
    width: '100%',
    backgroundColor: designTokens.product15,
    color: designTokens.themeAp100,
    borderRadius: 0
  },
  emailBodyText: {
    padding: '2rem'
  },
  formAttachmentCheckbox: {
    color: designTokens.product100,
    '&.Mui-checked': {
      color: designTokens.product100
    }
  },
  formAttachmentText: {
    color: designTokens.product100,
    fontFamily: designTokens.mediumFont.fontFamily
  },
  emailNode: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    backgroundColor: designTokens.product25,
    padding: '0.5rem',
    marginRight: '0.5rem',
    borderRadius: '1rem',
    marginBottom: '0.2rem',
    cursor: 'pointer',
    justifyContent: 'space-between',

    '& svg:hover': {
      backgroundColor: designTokens.neutral60,
      borderRadius: '1rem'
    }
  },
  emailNodesWrapper: {
    position: 'relative',
    left: '0.7rem',
    display: 'flex',
    flexWrap: 'wrap'
  },
  suggestedEmailUl: {
    minHeight: '2rem',
    listStyle: 'none',
    position: 'absolute',
    background: designTokens.white,
    top: '100%',
    left: 0,
    margin: 0,
    padding: '0.5rem 0',
    width: '100%',
    zIndex: 9,
    borderRadius: '0.5rem',
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px',
    '& li': {
      padding: '0.5rem 2rem',
      fontSize: '1rem',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: designTokens.neutral90
      }
    },
    '& p': {
      textAlign: 'center',
      padding: '0.5rem',
      fontSize: '1rem'
    }
  }
}));

export const EmailReportForm = ({
  reportData: {
    internalLocalfileReportId,
    reportName,
    internalLocalfileReportInstanceId,
    version,
    containerYear,
    jurisdictionName
  },
  formInputs,
  setFormInputs,
  toEmailList,
  setToEmailList,
  ccEmailList,
  setCcEmailList
}: EmailReportFormProps) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [toPendingEmail, setToPendingEmail] = useState('');
  const [ccPendingEmail, setCcPendingEmail] = useState('');
  const [displaySuggestion, setDisplaySuggestion] = useState(true);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [domainList, setDomainList] = useState<string[]>([]);

  const [debounceDisplaySuggestion] = useDebounce(() => {
    setDisplaySuggestion(true);
  }, 1500);

  function handleDebounceSuggestion(isToEmail: boolean, input: string) {
    // disable the suggestions as the user is typing
    setDisplaySuggestion(false);
    if (isToEmail) {
      setToPendingEmail(input);
    } else {
      setCcPendingEmail(input);
    }

    // re-enable the suggestions after the user finishes typing
    debounceDisplaySuggestion();
  }

  const fetchEmailTemplate = useCallback(async () => {
    const response = await dispatch(
      getEmailTemplate({
        reportId: internalLocalfileReportId,
        localFileReportInstanceId: internalLocalfileReportInstanceId
      })
    )?.unwrap();
    if (response) {
      setFormInputs((prev) => ({ ...prev, body: response.emailBody, hasAttachment: response.canAttach }));
      setEmailList(response.emails);
      setDomainList(response.domains);
    }
  }, [dispatch, internalLocalfileReportId, internalLocalfileReportInstanceId, setFormInputs]);

  useEffect(() => {
    void fetchEmailTemplate();
  }, [fetchEmailTemplate]);

  useEffect(() => {
    const loadEmailSubjectConditions = containerYear && jurisdictionName && reportName && version;
    if (loadEmailSubjectConditions) {
      const subjectStr = `TP Report - FY ${containerYear} - ${jurisdictionName} - ${reportName} (V${version})`;

      setFormInputs((prev) => ({ ...prev, subject: subjectStr }));
    }
  }, [containerYear, jurisdictionName, version, reportName, setFormInputs]);

  const filteredEmailSuggestions = useMemo(() => {
    const toOrCc = toPendingEmail ? toPendingEmail : ccPendingEmail;
    const list = emailList.filter((email) => {
      return email.startsWith(toOrCc) && !toEmailList.includes(email) && !ccEmailList.includes(email);
    });

    return list;
  }, [ccEmailList, ccPendingEmail, emailList, toEmailList, toPendingEmail]);

  const handleAddAnEmail = (inputId: string, mail: string) => {
    if (inputId === 'to') {
      setToEmailList((prev) => [...prev, mail.toLowerCase()]);
      setToPendingEmail('');
    }

    if (inputId === 'cc') {
      setCcEmailList((prev) => [...prev, mail.toLowerCase()]);
      setCcPendingEmail('');
    }
  };

  const handleRemoveAnEmail = (email: string, inputId: string) => {
    if (inputId === 'to') {
      setToEmailList((prev) => prev.filter((item) => item !== email));
    }

    if (inputId === 'cc') {
      setCcEmailList((prev) => prev.filter((item) => item !== email));
    }
  };

  const renderEmailNodes = (list: string[], inputId: string) => {
    return list.map((item) => (
      <span key={item} className={classes.emailNode}>
        <span>{item}</span>
        <CloseIcon
          fontSize="small"
          onClick={() => {
            handleRemoveAnEmail(item, inputId);
          }}
        />
      </span>
    ));
  };

  const renderSuggestedEmail = (mail: string, inputId: string) => {
    return (
      <li
        onClick={() => {
          handleAddAnEmail(inputId, mail);
        }}
      >
        {mail}
      </li>
    );
  };

  const handleEmailListBackdropClick = () => (
    <Box
      sx={{ position: 'fixed', top: 0, right: 0, left: 0, bottom: 0, zIndex: -1 }}
      onClick={() => {
        setCcPendingEmail('');
        setToPendingEmail('');
      }}
    />
  );

  const checkInputValidity = (mail: string) => {
    const domain = mail.split('@')[1];
    const isEmail = MAIL_FORMAT.test(mail);
    const isEmailDomainValid = isEmail && domainList.includes(domain);
    const isEmailAvailable = isEmailDomainValid && !toEmailList.includes(mail) && !ccEmailList.includes(mail);
    return { isEmail, isEmailAvailable, isEmailDomainValid };
  };

  const handleEmailValidity = (mail: string, inputId: string) => {
    const { isEmail, isEmailDomainValid, isEmailAvailable } = checkInputValidity(mail);

    if (isEmailAvailable) {
      return renderSuggestedEmail(mail, inputId);
    }

    if (!isEmail) return <p>Email is not found to be associated with the tax year</p>;
    if (!isEmailDomainValid) return <p>Email does not match known domains for the tax year</p>;
    return <p>Email already selected</p>;
  };

  return (
    <form>
      <Box display={toEmailList.length > 0 ? 'grid' : 'flex'} className={classes.formRow}>
        <label htmlFor="to">{t('reports:email-modal-to')}:</label>
        <Box marginBottom={toEmailList.length > 0 ? '1rem' : 0} className={classes.emailNodesWrapper}>
          {renderEmailNodes(toEmailList, 'to')}
        </Box>
        <input
          placeholder="Add Email Address"
          autoComplete="off"
          value={toPendingEmail}
          id="to"
          className={classes.formInput}
          onChange={(e) => {
            handleDebounceSuggestion(true, e.target.value);
          }}
        />
        {displaySuggestion && toPendingEmail && !ccPendingEmail ? (
          <ul className={classes.suggestedEmailUl}>
            {filteredEmailSuggestions.length > 0
              ? filteredEmailSuggestions?.map((email) => (
                  <Fragment key={email}>{renderSuggestedEmail(email, 'to')}</Fragment>
                ))
              : handleEmailValidity(toPendingEmail, 'to')}
            {handleEmailListBackdropClick()}
          </ul>
        ) : null}
      </Box>
      <Box display={ccEmailList.length > 0 ? 'grid' : 'flex'} className={classes.formRow}>
        <label htmlFor="cc">{t('reports:email-modal-cc')}:</label>
        <Box marginBottom={ccEmailList.length > 0 ? '1rem' : 0} className={classes.emailNodesWrapper}>
          {renderEmailNodes(ccEmailList, 'cc')}
        </Box>
        <input
          placeholder="Cc Other Emails"
          autoComplete="off"
          value={ccPendingEmail}
          id="cc"
          className={classes.formInput}
          onChange={(e) => {
            handleDebounceSuggestion(false, e.target.value);
          }}
        />
        {displaySuggestion && ccPendingEmail && !toPendingEmail ? (
          <ul className={classes.suggestedEmailUl}>
            {filteredEmailSuggestions.length > 0
              ? filteredEmailSuggestions?.map((email) => (
                  <Fragment key={email}>{renderSuggestedEmail(email, 'cc')}</Fragment>
                ))
              : handleEmailValidity(ccPendingEmail, 'cc')}
            {handleEmailListBackdropClick()}
          </ul>
        ) : null}
      </Box>
      <Box display="flex" className={classes.formRow}>
        <label htmlFor="subject">{t('reports:email-modal-subject')}:</label>
        <input
          value={formInputs.subject}
          autoComplete="off"
          id="subject"
          className={classes.formInput}
          maxLength={256}
          onChange={(e) => {
            setFormInputs((prev) => ({ ...prev, subject: e.target.value }));
          }}
        />
      </Box>
      <Box display="flex" className={classes.formRow} style={{ alignItems: 'center' }}>
        <label htmlFor="attach">{t('reports:email-modal-attach')}:</label>
        <Checkbox
          checked={formInputs.hasAttachment}
          id="checkbox"
          className={classes.formAttachmentCheckbox}
          disabled={!formInputs.hasAttachment}
        />
        <Typography className={classes.formAttachmentText}>
          {formInputs.hasAttachment ? t('reports:pdf-as-attachment') : t('reports:report-too-large')}
        </Typography>
      </Box>
      <Box className={classes.emailBodyWrapper}>
        <Editor
          theme="TransferPricing"
          value={formInputs.body}
          convertUrls={false}
          onEditorChange={(value = '') => {
            setFormInputs((prev) => ({ ...prev, body: value }));
          }}
        />
      </Box>
    </form>
  );
};
