import { Entity, Currency } from '.';
import { Transaction } from './transactions.interface';
import { ReportStatus } from '../app/LocalReports/LocalReports.proptype';

export interface InternalLocalFileReportInstanceStatus {
  internalLocalfileReportInstanceStatusId: number;
  name: string;
}

// TODO: Add type from API for profitBasedAnalysisConfig
export interface InternalLocalFileReportInstance {
  internalLocalfileReportInstanceId: number;
  version: number;
  status: InternalLocalFileReportInstanceStatus;
  reportStatus: ReportStatus;
  createdAt: string;
  customerVersionFlag: number;
  finalVersionFlag: number;
  reportCurrency: Currency;
  executiveSummaryConclusion: string;
  executiveSummaryIntro: string;
  orgChart?: string;
  languageIso: string;
  isUploadedReport: number;
  isActive: number;
  binaryPath: string;
  reportTransactions: Array<Partial<Transaction>>;
  primaryEntities: Array<Partial<Entity>>;
  tradingPartners: Array<Partial<Entity>>;
  shouldDisplayPageNumber?: boolean;
  isLimitationWording?: boolean;
  templateId: string;
  wordReportStatusId: number;
  wordReportBinaryPath: string;
  entityStatementOfFactsConfig: EntityStatementOfFactsConfig;
  profitBasedAnalysisConfig: any;
  controlledTransactionsDocumentationConfig: ControlledTransactionsDocumentationConfig;
  customAppendicesConfig: CustomAppendix[];
  sectionsOptionsConfig: ReportSummaryCoreSection[];
  emailStatus: number | null;
}

export interface FinalLocalReport {
  localfileReportId: number;
  name: string;
  binaryPath: string;
  documentType: string;
  comment: string | null;
  contactName: string | null;
  division: string | null;
  productLine: string | null;
  collapsed?: boolean;
  internalLocalfileReportInstance?: InternalLocalFileReportInstance;
}

export interface InternalLocalFileReport {
  internalLocalfileReportId: number;
  name: string;
}

export interface TemplateReport {
  name: string;
  templateId: string;
  useName: string;
}

export interface LocalReportData extends InternalLocalFileReportInstance {
  errorType?: string;
  internalLocalfileReportId: number;
  name: string;
}

export interface CustomAppendix {
  display: boolean;
  order: number;
  title: string;
  identifier: string;
  files: CustomAppendixFile[];
}

export interface CustomAppendixFile {
  name: string;
  order: number;
  link: string;
  state: AppendixFileState;
  uploaded: string;
}

export enum AppendixFileState {
  CREATED,
  UPLOADING,
  UPLOADED,
  FAILED
}

export interface EntityStatementOfFactsConfig {
  showUPE: boolean;
  optionSelected: OptionSelected;
  entitiesSelected: EntitiesSelected;
  displayOrgChart: boolean;
}

export type OptionSelected = 0 | 1 | 2 | 3;

export interface EntitiesSelected {
  primaryEntityIds: number[];
  tradingPartnersIds: number[];
}

export interface ReportSummaryCoreSection {
  name: string;
  code: string;
  active: boolean;
}

export interface ReportSummaryReportSections {
  core: ReportSummaryCoreSection[];
  appendix: CustomAppendix[];
}

export interface ConfigurationTabReportSummary {
  isEntitiesOpen: boolean;
  isReportsSectionOpen: boolean;
  isTransactionsOpen: boolean;
  isPBAsOpen: boolean;
  reportSections: ReportSummaryReportSections;
}

type ExtractByType<TObject extends { [key: string]: any }, TypeToExtract> = {
  [K in keyof TObject as TObject[K] extends TypeToExtract ? K : never]: TObject[K];
};

export type ReportSummaryAccordions = ExtractByType<ConfigurationTabReportSummary, boolean>;

export interface ConfigurationTab {
  reportSummary: ConfigurationTabReportSummary;
}

export interface ControlledTransactionsDocumentationConfig {
  optionSelected: OptionSelected;
  transactionsSelectedIds: number[];
}

export interface CheckReportCompSearchesStatusResponse {
  pbasWithoutCompsearch: Array<{ pbaName: string; transactionName: string }>;
  pbasWithFailedCompsearch: Array<{ pbaName: string; transactionName: string }>;
}
