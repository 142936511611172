import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ConnectorProps } from './ReportSectionArea.proptype';
import { useSelectReportSectionsConfig } from '../../../../../../hooks/report/useSelectReportSectionsConfig';
import { useSelectReportSectionsIsOpen } from '../../../../../../hooks/report/useSelectReportSectionsIsOpen';
import { CustomAppendix, ReportSummaryCoreSection } from '../../../../../../models/reports.interface';
import { actions } from '../../../../../../redux/localReports';
import { getDefaultReportSectionsOptionsConfig } from '../../../utils/getDefaultReportSectionsOptionsConfig';

const Connector = ({ component: Component, jurisdictionName }: ConnectorProps) => {
  const dispatch = useDispatch();

  const emitToggleCoreSection = useCallback(
    (sectionCode: ReportSummaryCoreSection['code']) =>
      dispatch(actions.toggleCoreSectionInReportSummaryReportSection(sectionCode)),
    [dispatch]
  );

  const emitToggleAppendixSection = useCallback(
    (title: CustomAppendix['title']) => dispatch(actions.toggleAppendixSectionInReportSummaryReportSection(title)),
    [dispatch]
  );

  const emitToggleReportSectionsAccordion = useCallback(
    () => dispatch(actions.toggleAccordionInReportSummary('isReportsSectionOpen')),
    [dispatch]
  );

  const selectReportSectionsCoreSections = useSelectReportSectionsConfig();
  const selectReportSectionsIsOpen = useSelectReportSectionsIsOpen();

  useEffect(() => {
    if (selectReportSectionsCoreSections.length === 0) {
      dispatch(actions.setConfigurationTabReportSectionsOptions(getDefaultReportSectionsOptionsConfig()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Component
      jurisdictionName={jurisdictionName}
      coreSections={selectReportSectionsCoreSections}
      emitToggleCoreSection={emitToggleCoreSection}
      setIsOpen={emitToggleReportSectionsAccordion}
      isOpen={selectReportSectionsIsOpen}
      emitToggleAppendixSection={emitToggleAppendixSection}
    />
  );
};

export default Connector;
