import { ProductNames } from '@xbs/xbs-enums';
import decodeJWT from 'jwt-decode';
import { getAppConfig } from './configuration';
import httpService from '../services/http';
export interface JWTToken {
  email: string;
  userId: string;
  role: string;
}

export interface AuthStateInterface {
  authToken: string;
  expiration: number;
  refreshToken: string;
  lastRefreshDate: number;
}
type LogoutReason =
  | 'no_container_found_on_account'
  | 'last_time_token_refresh_not_current_day'
  | 'refresh_token_response_not_ok'
  | 'authorize_request_failed'
  | 'append_xbs_headers_failed'
  | 'handling_error_response_network_error'
  | 'handling_error_response_401_status';
export const redirectToLogin = async () => {
  const { loginStackRelativeUrl } = getAppConfig();
  if (!loginStackRelativeUrl) {
    return;
  }

  if (isUserAuthenticated()) {
    await httpService.request<any>({
      method: 'get',
      apiUrlKey: 'authUrl',
      relativePath: `/v1/logout`
    });
  }

  const message = JSON.stringify({
    kind: 'AUTH',
    message: 'SESSION_EXPIRED'
  });
  window.parent.postMessage(message, '*');
  window.localStorage.clear();

  const productUuid = ProductNames.ByName.Tpiq.Uuid;
  window.localStorage.setItem('productUuid', productUuid);

  window.location.replace(`${window.location.origin}/${loginStackRelativeUrl}`);
};

export const redirectToLoading = () => {
  const { microUIReportsStackRelativeUrl } = getAppConfig();
  if (!microUIReportsStackRelativeUrl) {
    return;
  }

  const message = JSON.stringify({
    kind: 'AUTH',
    message: 'SESSION_EXPIRED'
  });

  window.parent.postMessage(message, '*');
  window.localStorage.clear();
  window.location.replace(`${window.location.origin}/${microUIReportsStackRelativeUrl}/loading`);
};

export const logUserLogout = async (reason: LogoutReason) => {
  if (isUserAuthenticated()) {
    await httpService.request<unknown>({
      method: 'post',
      apiUrlKey: 'tpCoreApiUrl',
      relativePath: `/v1/event/user-logout`,
      data: { reason }
    });
  }
};

export const isUserAuthenticated = () => {
  const auth = getAuthInfo();

  return (
    Boolean(auth.authToken) &&
    Boolean(auth.expiration) &&
    auth.expiration > Date.now() &&
    auth.lastRefreshDate === new Date().getDate()
  );
};

export const getAuthInfo = (): AuthStateInterface => ({
  authToken: localStorage.getItem('idToken') ?? '',
  refreshToken: localStorage.getItem('refreshToken') ?? '',
  expiration: localStorage.getItem('expires') ? Number(localStorage.getItem('expires')) * 1000 : Date.now() - 1000,
  lastRefreshDate: localStorage.getItem('lastRefreshDate')
    ? Number(localStorage.getItem('lastRefreshDate'))
    : new Date().getDate()
});

export const setAuthTokens = ({
  authToken = '',
  refreshToken = '',
  expiration
}: Partial<AuthStateInterface>): AuthStateInterface => {
  localStorage.setItem('idToken', String(authToken));
  localStorage.setItem('refreshToken', String(refreshToken));
  localStorage.setItem('expires', expiration ? `${expiration / 1000}` : '');
  localStorage.setItem('lastRefreshDate', String(new Date().getDate()));

  return getAuthInfo();
};

export const decodeTokens = (token: string) => {
  try {
    const jwt = decodeJWT(token);

    if (!jwt) {
      return null;
    }

    return {
      email: (jwt as JWTToken).email,
      userId: (jwt as JWTToken).userId,
      role: (jwt as JWTToken).role
    };
  } catch {
    return null;
  }
};
