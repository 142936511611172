import { Chip, makeStyles } from '@material-ui/core';
import tokens from '../../../../../styles/designTokens';
import { captionOverline } from '../../../../../styles/typography';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    height: '2.75rem',
    textAlign: 'left',
    ...captionOverline,
    backgroundColor: tokens.white60,
    border: 'solid 1px',
    borderRadius: '0.25rem',
    paddingLeft: '1.25rem',
    boxSizing: 'border-box',
    borderColor: tokens.neutral60,
    color: tokens.core1,
    cursor: 'pointer',
    margin: '0.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  active: {
    width: '100%',
    height: '2.75rem',
    color: tokens.purpleLight2,
    textAlign: 'left',
    ...captionOverline,
    border: 'solid 1px',
    borderRadius: '0.25rem',
    paddingLeft: '1.25rem',
    boxSizing: 'border-box',
    borderColor: tokens.purpleLight2,
    backgroundColor: tokens.product15,
    cursor: 'pointer',
    margin: '0.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  chip: {
    color: tokens.product100,
    ...captionOverline,
    textAlign: 'center',
    backgroundColor: tokens.product25
  }
}));

interface ConfigurationButtonProps {
  action: () => void;
  text: string;
  active: boolean;
  change?: boolean;
}

export const ConfigurationButton = ({ action, text, active, change }: ConfigurationButtonProps) => {
  const classes = useStyles();

  const chimpProps = () => {
    const chipProps = {
      className: classes.chip,
      label: <span>C</span>
    };

    return change && <Chip size="small" {...chipProps} />;
  };

  return (
    <button type="button" className={active ? classes.active : classes.button} onClick={action}>
      {text} {chimpProps()}
    </button>
  );
};
