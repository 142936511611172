import tokens from './designTokens';

const appColors = {
  reports: {
    draft: {
      text: tokens.core1,
      fill: tokens.neutral90,
      border: tokens.neutral100
    },
    review: {
      text: '#0376a0',
      fill: '#e5f4f9',
      border: '#0376a0'
    },
    published: {
      text: '#6e56db',
      fill: '#f4f2fd',
      border: '#6e56db'
    },
    final: {
      text: tokens.white,
      fill: tokens.product100,
      border: tokens.product100
    },
    noReports: {
      fill: tokens.neutral60
    },
    active: {
      text: tokens.product100
    },
    highlight: {
      background: 'rgba(111, 87, 219, 0.03)'
    }
  },
  jurisdictionStatus: {
    inProgress: {
      fill: '#f7eece',
      border: '#f4cd52'
    },
    inReview: {
      fill: '#e5f4f9',
      border: '#0376a0'
    },
    complete: {
      fill: '#efefff',
      border: tokens.product100
    }
  }
};

export default appColors;
