export const ErrorUploadSvg = () => (
  <svg width="18px" height="15px" viewBox="0 0 18 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="UI-designs-current" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="TP-Comp-PBA-Rerun-Modal-2-Alert-1"
        transform="translate(-1320.000000, -247.000000)"
        fill="#B00020"
        fillRule="nonzero"
      >
        <g id="Icon/Warning-small" transform="translate(1320.000000, 247.000000)">
          <path
            d="M0,15 L9,0 L18,15 L0,15 Z M2.646,13.5 L15.354,13.5 L9,2.917 L2.646,13.5 Z M9,12.5 C9.208,12.5 9.385,12.427 9.531,12.281 C9.677,12.135 9.75,11.958 9.75,11.75 C9.75,11.542 9.677,11.365 9.531,11.219 C9.385,11.073 9.208,11 9,11 C8.792,11 8.615,11.073 8.469,11.219 C8.323,11.365 8.25,11.542 8.25,11.75 C8.25,11.958 8.323,12.135 8.469,12.281 C8.615,12.427 8.792,12.5 9,12.5 Z M8.25,10 L9.75,10 L9.75,6 L8.25,6 L8.25,10 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const AttachmentIconSvg = ({ active }: any) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="UI-designs-current" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TP-Reports-Create-CF-V3-Appen-B3" transform="translate(-391.000000, -435.000000)">
        <g id="Config/Content/LeftNav1" transform="translate(360.000000, 141.000000)">
          <g id="Attachment" transform="translate(31.000000, 294.000000)">
            <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            {active ? (
              <path
                d="M15.5,11 C16.2,11 16.7916667,11.2416667 17.275,11.725 C17.7583333,12.2083333 18,12.8 18,13.5 L18,18 L16,18 L16,13.5 C16,13.3666667 15.95,13.25 15.85,13.15 C15.75,13.05 15.6333333,13 15.5,13 C15.3666667,13 15.25,13.05 15.15,13.15 C15.05,13.25 15,13.3666667 15,13.5 L15,18 C15,18.55 15.1956667,19.021 15.587,19.413 C15.979,19.8043333 16.45,20 17,20 C17.55,20 18.021,19.8043333 18.413,19.413 C18.8043333,19.021 19,18.55 19,18 L19,14 L21,14 L21,18 C21,19.1 20.6083333,20.0416667 19.825,20.825 C19.0416667,21.6083333 18.1,22 17,22 C15.9,22 14.9583333,21.6083333 14.175,20.825 C13.3916667,20.0416667 13,19.1 13,18 L13,13.5 C13,12.8 13.2416667,12.2083333 13.725,11.725 C14.2083333,11.2416667 14.8,11 15.5,11 Z M18,2 C18.55,2 19.021,2.19566667 19.413,2.587 C19.8043333,2.979 20,3.45 20,4 L20,9 L18,9 L18,4 L6,4 L6,19 L11,19 L11,21 L6,21 C5.45,21 4.97933333,20.8043333 4.588,20.413 C4.196,20.021 4,19.55 4,19 L4,4 C4,3.45 4.196,2.979 4.588,2.587 C4.97933333,2.19566667 5.45,2 6,2 L18,2 Z M12,10 L12,12 L8,12 L8,10 L12,10 Z M16,6 L16,8 L8,8 L8,6 L16,6 Z"
                fill="#3C2A8E"
              />
            ) : (
              <path
                d="M15.5,11 C16.2,11 16.7916667,11.2416667 17.275,11.725 C17.7583333,12.2083333 18,12.8 18,13.5 L18,18 L16,18 L16,13.5 C16,13.3666667 15.95,13.25 15.85,13.15 C15.75,13.05 15.6333333,13 15.5,13 C15.3666667,13 15.25,13.05 15.15,13.15 C15.05,13.25 15,13.3666667 15,13.5 L15,18 C15,18.55 15.1956667,19.021 15.587,19.413 C15.979,19.8043333 16.45,20 17,20 C17.55,20 18.021,19.8043333 18.413,19.413 C18.8043333,19.021 19,18.55 19,18 L19,14 L21,14 L21,18 C21,19.1 20.6083333,20.0416667 19.825,20.825 C19.0416667,21.6083333 18.1,22 17,22 C15.9,22 14.9583333,21.6083333 14.175,20.825 C13.3916667,20.0416667 13,19.1 13,18 L13,13.5 C13,12.8 13.2416667,12.2083333 13.725,11.725 C14.2083333,11.2416667 14.8,11 15.5,11 Z M18,2 C18.55,2 19.021,2.19566667 19.413,2.587 C19.8043333,2.979 20,3.45 20,4 L20,9 L18,9 L18,4 L6,4 L6,19 L11,19 L11,21 L6,21 C5.45,21 4.97933333,20.8043333 4.588,20.413 C4.196,20.021 4,19.55 4,19 L4,4 C4,3.45 4.196,2.979 4.588,2.587 C4.97933333,2.19566667 5.45,2 6,2 L18,2 Z M12,10 L12,12 L8,12 L8,10 L12,10 Z M16,6 L16,8 L8,8 L8,6 L16,6 Z"
                fill="#677283"
              />
            )}
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const DeleteIcon = () => (
  <svg cursor="pointer" width="14px" height="18px" viewBox="0 0 14 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="UI-designs-current" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TP-Reports-Create-CF-V3-Appen-B3" transform="translate(-1446.000000, -423.000000)" fill="#677283">
        <g id="Config/Content/EntitySoF" transform="translate(759.000000, 140.000000)">
          <g id="Icon/Delete" transform="translate(682.000000, 280.000000)">
            <path
              d="M16,9 L16,19 L8,19 L8,9 L16,9 M14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 L15.5,4 L14.5,3 Z M18,7 L6,7 L6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 Z"
              id="Delete"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const LockIcon = ({ active }: any) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="UI-designs-current" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TP-Reports-Create-CF-V3-Appen-B2" fill="none" transform="translate(-706.000000, -434.000000)">
        <g id="Config/Content/LeftNav1" fill="none" transform="translate(360.000000, 141.000000)">
          <g fill="none" id="Lock" transform="translate(346.000000, 293.000000)">
            <rect fill="none" id="Rectangle" x="0" y="0" width="24" height="24" />
            {active ? (
              <path
                d="M7.5,20 C7.08333333,20 6.72933333,19.854 6.438,19.562 C6.146,19.2706667 6,18.9166667 6,18.5 L6,10.5 C6,10.0833333 6.146,9.72933333 6.438,9.438 C6.72933333,9.146 7.08333333,9 7.5,9 L8,9 L8,7 C8,5.88866667 8.389,4.94433333 9.167,4.167 C9.94433333,3.389 10.8886667,3 12,3 C13.1113333,3 14.0556667,3.389 14.833,4.167 C15.611,4.94433333 16,5.88866667 16,7 L16,9 L16.5,9 C16.9166667,9 17.2706667,9.146 17.562,9.438 C17.854,9.72933333 18,10.0833333 18,10.5 L18,18.5 C18,18.9166667 17.854,19.2706667 17.562,19.562 C17.2706667,19.854 16.9166667,20 16.5,20 L7.5,20 Z M7.5,18.5 L16.5,18.5 L16.5,10.5 L7.5,10.5 L7.5,18.5 Z M12,16 C12.4166667,16 12.7706667,15.854 13.062,15.562 C13.354,15.2706667 13.5,14.9166667 13.5,14.5 C13.5,14.0833333 13.354,13.7293333 13.062,13.438 C12.7706667,13.146 12.4166667,13 12,13 C11.5833333,13 11.2293333,13.146 10.938,13.438 C10.646,13.7293333 10.5,14.0833333 10.5,14.5 C10.5,14.9166667 10.646,15.2706667 10.938,15.562 C11.2293333,15.854 11.5833333,16 12,16 Z M9.5,9 L14.5,9 L14.5,7 C14.5,6.30533333 14.257,5.715 13.771,5.229 C13.285,4.743 12.6946667,4.5 12,4.5 C11.3053333,4.5 10.715,4.743 10.229,5.229 C9.743,5.715 9.5,6.30533333 9.5,7 L9.5,9 Z M7.5,18.5 L7.5,10.5 L7.5,18.5 Z"
                fill="#3C2A8E"
              />
            ) : (
              <path
                d="M7.5,20 C7.08333333,20 6.72933333,19.854 6.438,19.562 C6.146,19.2706667 6,18.9166667 6,18.5 L6,10.5 C6,10.0833333 6.146,9.72933333 6.438,9.438 C6.72933333,9.146 7.08333333,9 7.5,9 L8,9 L8,7 C8,5.88866667 8.389,4.94433333 9.167,4.167 C9.94433333,3.389 10.8886667,3 12,3 C13.1113333,3 14.0556667,3.389 14.833,4.167 C15.611,4.94433333 16,5.88866667 16,7 L16,9 L16.5,9 C16.9166667,9 17.2706667,9.146 17.562,9.438 C17.854,9.72933333 18,10.0833333 18,10.5 L18,18.5 C18,18.9166667 17.854,19.2706667 17.562,19.562 C17.2706667,19.854 16.9166667,20 16.5,20 L7.5,20 Z M7.5,18.5 L16.5,18.5 L16.5,10.5 L7.5,10.5 L7.5,18.5 Z M12,16 C12.4166667,16 12.7706667,15.854 13.062,15.562 C13.354,15.2706667 13.5,14.9166667 13.5,14.5 C13.5,14.0833333 13.354,13.7293333 13.062,13.438 C12.7706667,13.146 12.4166667,13 12,13 C11.5833333,13 11.2293333,13.146 10.938,13.438 C10.646,13.7293333 10.5,14.0833333 10.5,14.5 C10.5,14.9166667 10.646,15.2706667 10.938,15.562 C11.2293333,15.854 11.5833333,16 12,16 Z M9.5,9 L14.5,9 L14.5,7 C14.5,6.30533333 14.257,5.715 13.771,5.229 C13.285,4.743 12.6946667,4.5 12,4.5 C11.3053333,4.5 10.715,4.743 10.229,5.229 C9.743,5.715 9.5,6.30533333 9.5,7 L9.5,9 Z M7.5,18.5 L7.5,10.5 L7.5,18.5 Z"
                fill="#8E949F"
              />
            )}
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const MoreActionsIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-531.000000, -1533.000000)" id="Icon/MoreActions">
        <g transform="translate(531.000000, 1533.000000)">
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
          <path
            d="M7,12 C7,10.8954305 6.1045695,10 5,10 C3.8954305,10 3,10.8954305 3,12 C3,13.1045695 3.8954305,14 5,14 C6.1045695,14 7,13.1045695 7,12 Z M14,12 C14,10.8954305 13.1045695,10 12,10 C10.8954305,10 10,10.8954305 10,12 C10,13.1045695 10.8954305,14 12,14 C13.1045695,14 14,13.1045695 14,12 Z M21,12 C21,10.8954305 20.1045695,10 19,10 C17.8954305,10 17,10.8954305 17,12 C17,13.1045695 17.8954305,14 19,14 C20.1045695,14 21,13.1045695 21,12 Z"
            id="Combined-Shape"
            fill="#677283"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const AppendixDragIcon = ({ active }: any) => (
  <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Rounded" transform="translate(-685.000000, -246.000000)">
        <g id="Action" transform="translate(100.000000, 100.000000)">
          <g id="-Round-/-Action-/-drag_indicator" transform="translate(578.000000, 142.000000)">
            <g transform="translate(0.000000, 0.000000)">
              <polygon id="Path" points="0 0 24 0 24 24 0 24" />
              {active ? (
                <path
                  d="M11,18 C11,19.1 10.1,20 9,20 C7.9,20 7,19.1 7,18 C7,16.9 7.9,16 9,16 C10.1,16 11,16.9 11,18 Z M9,10 C7.9,10 7,10.9 7,12 C7,13.1 7.9,14 9,14 C10.1,14 11,13.1 11,12 C11,10.9 10.1,10 9,10 Z M9,4 C7.9,4 7,4.9 7,6 C7,7.1 7.9,8 9,8 C10.1,8 11,7.1 11,6 C11,4.9 10.1,4 9,4 Z M15,8 C16.1,8 17,7.1 17,6 C17,4.9 16.1,4 15,4 C13.9,4 13,4.9 13,6 C13,7.1 13.9,8 15,8 Z M15,10 C13.9,10 13,10.9 13,12 C13,13.1 13.9,14 15,14 C16.1,14 17,13.1 17,12 C17,10.9 16.1,10 15,10 Z M15,16 C13.9,16 13,16.9 13,18 C13,19.1 13.9,20 15,20 C16.1,20 17,19.1 17,18 C17,16.9 16.1,16 15,16 Z"
                  id="🔹Icon-Color"
                  fill="#3C2A8E"
                />
              ) : (
                <path
                  d="M11,18 C11,19.1 10.1,20 9,20 C7.9,20 7,19.1 7,18 C7,16.9 7.9,16 9,16 C10.1,16 11,16.9 11,18 Z M9,10 C7.9,10 7,10.9 7,12 C7,13.1 7.9,14 9,14 C10.1,14 11,13.1 11,12 C11,10.9 10.1,10 9,10 Z M9,4 C7.9,4 7,4.9 7,6 C7,7.1 7.9,8 9,8 C10.1,8 11,7.1 11,6 C11,4.9 10.1,4 9,4 Z M15,8 C16.1,8 17,7.1 17,6 C17,4.9 16.1,4 15,4 C13.9,4 13,4.9 13,6 C13,7.1 13.9,8 15,8 Z M15,10 C13.9,10 13,10.9 13,12 C13,13.1 13.9,14 15,14 C16.1,14 17,13.1 17,12 C17,10.9 16.1,10 15,10 Z M15,16 C13.9,16 13,16.9 13,18 C13,19.1 13.9,20 15,20 C16.1,20 17,19.1 17,18 C17,16.9 16.1,16 15,16 Z"
                  id="🔹Icon-Color"
                  fill="#ABB1BA"
                />
              )}
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
