import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tabs, Tab, makeStyles } from '@material-ui/core';
import { ReportsCircle, ReportsTable } from '.';
import { CenteredProgress, ContentLayout } from '../../../components';
import { ReportsCircleProps, ReportsProps, ReportsTabProps } from '../Reports.proptype';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  contentHeaderBorder: {
    backgroundColor: theme.palette.background.default
  },
  jurisdictionCircleDivider: {
    borderRight: '1px solid #E2E3E6',
    paddingRight: '1.2rem',
    marginRight: '0.5rem',
    marginBottom: '-1.2rem'
  }
}));

export const Reports = ({
  entityReportData,
  tabs,
  onSelectTab,
  selectedTab,
  masterFileReportsInfo,
  onNavigate,
  counterCircles,
  jurisdictionsCircle,
  containerPolicies,
  isLoading
}: ReportsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  return (
    <Box height={1}>
      {isLoading ? (
        <CenteredProgress />
      ) : (
        <ContentLayout
          title={t('compliance:title')}
          actions={
            <>
              <ReportsCircle
                key={jurisdictionsCircle.type}
                percent={jurisdictionsCircle.percent}
                metric={jurisdictionsCircle.metric}
                type={jurisdictionsCircle.type}
                color={jurisdictionsCircle.color}
              />
              <span className={classes.jurisdictionCircleDivider} />
              {counterCircles.map((counterCircle: ReportsCircleProps) => (
                <ReportsCircle
                  key={counterCircle.type}
                  percent={counterCircle.percent}
                  metric={counterCircle.metric}
                  type={counterCircle.type}
                  color={counterCircle.color}
                />
              ))}
            </>
          }
          main={
            <Box className={classes.container}>
              <Tabs
                className={classes.contentHeaderBorder}
                value={tab}
                onChange={(event, value) => {
                  onSelectTab(value);
                  setTab(value);
                }}
              >
                {tabs.map((tab: ReportsTabProps) => (
                  <Tab key={tab.key} label={tab.label} />
                ))}
              </Tabs>
              <ReportsTable
                entityReportData={entityReportData}
                selectedTab={selectedTab}
                masterFileReportsInfo={masterFileReportsInfo}
                containerPolicies={containerPolicies}
                onNavigate={onNavigate}
              />
            </Box>
          }
        />
      )}
    </Box>
  );
};
