import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { Chip } from '@mui/material';
import { EntityAreaProps } from './EntitySectionArea.proptype';
import tokens from '../../../../../../styles/designTokens';
import { body2, captionOverline } from '../../../../../../styles/typography';
import { Table } from '../../../../../Table';
import { TableColumnDef } from '../../../../../Table/Table.proptype';
import { ActionTable } from '../../../components/ActionTable/ActionTable';
import { CheckIcon, CrossIcon } from '../Icons';

const useStyles = makeStyles(() => ({
  actionTableMargin: {
    margin: '1.5rem 0'
  },
  columnHeader: {
    ...captionOverline
  },
  sectionRow: {
    '& .MuiTableCell-root': {
      ...body2
    }
  },
  chip: {
    '& .MuiChip-root': {
      borderRadius: '4px',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product15
    },
    '& .MuiChip-label': {
      paddingLeft: '7.5px'
    }
  },
  chipDisabled: {
    '& .MuiChip-root': {
      borderRadius: '4px',
      ...captionOverline,
      color: tokens.core1,
      backgroundColor: tokens.neutral70
    },
    '& .MuiChip-label': {
      paddingLeft: '7.5px'
    }
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  table: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  spinner: {
    height: '2.3rem',
    width: '2.3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export const EntitySectionArea = ({
  selectedEntities,
  selectedTradingPartners,
  selectedLegalEntities,
  upe,
  setIsOpen,
  isOpen
}: EntityAreaProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const entitiesTableColumns: TableColumnDef[] = [
    {
      key: 'entity',
      header: (
        <span className={classes.columnHeader}>
          {t('reports:configurationTab-ReportSummary-entitiesTableColumns-header-entity')}
        </span>
      ),
      width: '75%'
    },
    {
      key: 'details',
      header: (
        <span className={classes.columnHeader}>
          {t('reports:configurationTab-ReportSummary-entitiesTableColumns-header-details')}
        </span>
      ),
      width: '15%'
    },
    { key: 'action', header: '', width: '10%' }
  ];

  const entities = useMemo(() => {
    const legalEntities = selectedLegalEntities.entitiesSelected;

    const getUpeRow = () => {
      const selected = selectedLegalEntities.showUPE;
      return {
        className: classes.sectionRow,
        entity: upe.code,
        details: (
          <Box className={selected ? classes.chip : classes.chipDisabled}>
            <Chip
              size="small"
              label={
                <span className={classes.chipLabel}>
                  {selected ? <CheckIcon /> : <CrossIcon />}{' '}
                  {t('reports:label-local-report-creation-modal-configuration-report-summary-entity-section-sof')}
                </span>
              }
            />
          </Box>
        )
      };
    };

    const rows = [...selectedEntities, ...selectedTradingPartners]
      .filter((entity) => entity.entityId !== upe.entityId)
      .map((entity: any) => {
        const selected =
          legalEntities?.primaryEntityIds.includes(entity.entityId) ||
          legalEntities?.tradingPartnersIds.includes(entity.entityId);

        return {
          className: classes.sectionRow,
          entity: entity.code,
          details: (
            <Box className={selected ? classes.chip : classes.chipDisabled}>
              <Chip
                size="small"
                label={
                  <span className={classes.chipLabel}>
                    {selected ? <CheckIcon /> : <CrossIcon />}{' '}
                    {t('reports:label-local-report-creation-modal-configuration-report-summary-entity-section-sof')}
                  </span>
                }
              />
            </Box>
          )
        };
      });
    const upeRow = getUpeRow();
    rows.sort(function (a, b) {
      return a.entity > b.entity ? 1 : b.entity > a.entity ? -1 : 0;
    });
    rows.unshift(upeRow);
    return rows;
  }, [
    selectedLegalEntities.entitiesSelected,
    selectedLegalEntities.showUPE,
    selectedEntities,
    selectedTradingPartners,
    classes.sectionRow,
    classes.chip,
    classes.chipDisabled,
    classes.chipLabel,
    upe.code,
    upe.entityId,
    t
  ]);

  const titleChip = useMemo(() => {
    const totalEntities = entities.length;
    const entitiesSelected =
      [
        ...selectedLegalEntities.entitiesSelected.primaryEntityIds,
        ...selectedLegalEntities.entitiesSelected.tradingPartnersIds
      ].filter((entityId) => entityId !== upe.entityId).length + Number(selectedLegalEntities.showUPE || false);

    return `${entitiesSelected}/${totalEntities}`;
  }, [
    entities.length,
    selectedLegalEntities.entitiesSelected.primaryEntityIds,
    selectedLegalEntities.entitiesSelected.tradingPartnersIds,
    selectedLegalEntities.showUPE,
    upe.entityId
  ]);

  const actionTableProps =
    [...selectedTradingPartners, ...selectedEntities].length === 0
      ? {
          title: (
            <Box className={classes.spinner}>
              <CircularProgress color="primary" />
            </Box>
          )
        }
      : { title: 'Entities', chip: titleChip };

  return (
    <Box className={classes.actionTableMargin}>
      <ActionTable id="report-summary-accordion-2" isOpen={isOpen} setIsOpen={setIsOpen} {...actionTableProps}>
        <Box className={classes.table}>
          <Table columns={entitiesTableColumns} data={entities} />
        </Box>
      </ActionTable>
    </Box>
  );
};
