import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { EmailReportForm } from './EmailReportForm/EmailReportForm';
import { Inputs } from './EmailReportForm/EmailReportForm.proptypes';
import { EmailReportModalProps } from './EmailReportModal.proptype';
import { EMAIL_CANCELLED, EMAIL_STATUSES } from '../../../constants';
import { updateEmailStatus, sendEmail } from '../../../redux/reports/thunks/reports';
import designTokens from '../../../styles/designTokens';
import { ShareReportIcon } from '../../../svgs';
import { useNotifications } from '../../Notifications';
import { TPModal } from '../../TPModal';

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'flex-start'
  },
  headerIcon: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    backgroundColor: designTokens.product15,
    '& svg': {
      fill: theme.palette.primary.main
    }
  },
  headerTitle: {
    marginLeft: theme.spacing(1),
    fontSize: '1.3rem',
    fontFamily: designTokens.regularFont.fontFamily,
    color: designTokens.core2
  },
  headerShare: {
    fontFamily: designTokens.mediumFont.fontFamily,
    color: designTokens.core3,
    marginLeft: '1rem'
  },
  doneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    '&:disabled': {
      backgroundColor: designTokens.product15
    }
  },
  contentWrapper: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 0
  },
  formWrapper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    width: '100%'
  },
  gridWrapper: {
    marginTop: theme.spacing(1)
  },
  contentTitle: {
    fontFamily: designTokens.mediumFont.fontFamily,
    fontSize: '1rem',
    color: designTokens.core3
  },
  label: {
    color: designTokens.core2,
    marginBottom: '6px',
    fontSize: '0.9rem',
    fontFamily: designTokens.mediumFont.fontFamily,
    lineHeight: 1.8
  },
  input: {
    display: 'none'
  },
  wrapperInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  inputFileName: {
    fontFamily: designTokens.mediumFont.fontFamily,
    fontSize: '0.89rem',
    backgroundColor: designTokens.product15,
    color: designTokens.product100,
    padding: theme.spacing(0.7),
    marginLeft: theme.spacing(1),
    borderRadius: '4px'
  },
  placeHolder: {
    color: theme.palette.text.secondary
  },
  formControl: {
    width: '100%',
    boxSizing: 'content-box'
  },
  cancelBtn: {
    color: designTokens.themeAp100,
    borderColor: designTokens.themeAp100,
    marginRight: 'auto'
  },
  sendBtn: {
    backgroundColor: designTokens.product100,
    color: designTokens.white,
    minWidth: '10rem',
    '&:hover': {
      color: designTokens.themeAp100
    },
    '&:disabled': {
      color: designTokens.white,
      backgroundColor: designTokens.neutral95
    }
  }
}));

export const EmailReportModal = ({
  isOpen = false,
  setIsOpen,
  reportData,
  reportStatus,
  setEmailStatus
}: EmailReportModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showNotification } = useNotifications();

  const defaultInputs = {
    subject: '',
    body: '',
    hasAttachment: false
  };

  const [formInputs, setFormInputs] = useState<Inputs>(defaultInputs);
  const [toEmailList, setToEmailList] = useState<string[]>([]);
  const [ccEmailList, setCcEmailList] = useState<string[]>([]);

  const handlerOnClose = useCallback(async () => {
    setIsOpen(false);
    await updateEmailStatus({
      args: {
        reportId: reportData.internalLocalfileReportId,
        localFileReportInstanceId: reportData.internalLocalfileReportInstanceId
      },
      body: {
        emailStatus: EMAIL_CANCELLED,
        reportInstanceStatus: reportStatus
      }
    });
  }, [reportData, reportStatus, setIsOpen]);

  const handleSendAnEmail = async () => {
    const payload = {
      args: {
        reportId: reportData.internalLocalfileReportId,
        localFileReportInstanceId: reportData.internalLocalfileReportInstanceId
      },
      body: {
        attachmentName: reportData.reportName,
        hasAttachment: formInputs.hasAttachment,
        subject: formInputs.subject,
        emailBody: formInputs.body,
        recipients: toEmailList,
        ccRecipients: ccEmailList,
        reportInstanceStatus: reportData.reportStatus
      }
    };
    try {
      const { emailStatus } = await sendEmail(payload);
      if (emailStatus === EMAIL_STATUSES.SUCCESS) {
        showNotification({ type: 'success', message: 'Report successfully sent' });
      }

      if (emailStatus === EMAIL_STATUSES.FAILED) {
        showNotification({ type: 'error', message: 'Report Failed to sent, please attempt to send it manually' });
      }

      if (emailStatus === EMAIL_STATUSES.CANCELED) {
        showNotification({ type: 'error', message: 'Report email has been canceled' });
      }

      if (emailStatus === EMAIL_STATUSES.PENDING) {
        showNotification({ type: 'warning', message: 'Report email is currently pending' });
      }

      setEmailStatus(emailStatus);
      setIsOpen(false);
    } catch {
      setEmailStatus(3);
      showNotification({ type: 'error', message: 'Report Failed to sent, please attempt to send it manually' });
    }
  };

  return (
    <TPModal
      isOpen={isOpen}
      maxWidth="md"
      containerStyle={classes.contentWrapper}
      title={
        <Box display="inline" className={classes.headerWrapper}>
          <Box className={classes.headerIcon}>
            <ShareReportIcon />
          </Box>
          <Box display="flex">
            <Typography className={classes.headerShare}>{t('share')}</Typography>
            <Typography className={classes.headerTitle}>{reportData.reportName}</Typography>
          </Box>
        </Box>
      }
      actions={[
        {
          name: t('action-cancel'),
          handler: handlerOnClose,
          className: classes.cancelBtn,
          variant: 'outlined'
        },
        {
          name: t('action-send-now'),
          handler: handlerOnClose,
          className: classes.sendBtn,
          onClick: async () => handleSendAnEmail(),
          disabled: toEmailList.length === 0 || !formInputs.subject || !formInputs.body
        }
      ]}
      onClose={handlerOnClose}
    >
      <Box className={classes.formWrapper}>
        <EmailReportForm
          reportData={reportData}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
          toEmailList={toEmailList}
          setToEmailList={setToEmailList}
          ccEmailList={ccEmailList}
          setCcEmailList={setCcEmailList}
        />
      </Box>
    </TPModal>
  );
};
