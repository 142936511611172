import { ConnectorReportSummaryProps } from './ReportSummary.proptype';
import { useReportTitle } from '../../../../../hooks/report/useReportTitle';

const Connector = ({
  component: Component,
  jurisdictionName,
  transactions,
  upe,
  primaryEntities,
  tradingPartners,
  entityStatementFactsConfigState,
  controlledTransactionsConfigState,
  hasCustomization
}: ConnectorReportSummaryProps) => {
  const reportTitle = useReportTitle();

  return (
    <Component
      upe={upe}
      jurisdictionName={jurisdictionName}
      selectedTransactions={transactions}
      hasCustomization={hasCustomization}
      reportTitle={reportTitle}
      selectedEntities={primaryEntities}
      selectedTradingPartners={tradingPartners}
      selectedLegalEntities={entityStatementFactsConfigState}
      controlledTransactionSelection={controlledTransactionsConfigState}
    />
  );
};

export default Connector;
