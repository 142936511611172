import { Box, makeStyles } from '@material-ui/core';
import tokens from '../../../../../styles/designTokens';

const useStyles = makeStyles(() => ({
  root: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: tokens.neutral90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export const OpenTableIcon = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="10px"
        height="5px"
        viewBox="0 0 1280.000000 640.000000"
        preserveAspectRatio="xMidYMid meet"
        transform="rotate(90deg)"
      >
        <g transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)" fill="#677283" stroke="none">
          <path
            d="M3198 3205 c-1759 -1756 -3198 -3196 -3198 -3199 0 -3 2880 -6 6400
            -6 3520 0 6400 3 6400 7 0 7 -6388 6387 -6399 6390 -3 1 -1445 -1435 -3203
            -3192z"
          />
        </g>
      </svg>
    </Box>
  );
};
