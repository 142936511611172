import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FinalLocalReport,
  InternalLocalFileReport,
  InternalLocalFileReportInstance,
  EmailTemplate,
  EmailTemplateArgs,
  EmailTemplateResponse,
  StatusBody,
  EmailStatusPayload,
  FormPayload
} from '../../../models';
import httpService from '../../../services/http';

export const fetchInternalReports = createAsyncThunk<InternalLocalFileReport[], number, { rejectValue: Error }>(
  'reports/fetchInternal',
  async (entityId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<InternalLocalFileReport[]>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `entities/${entityId}/internal-local-file-reports`
        })
      ).data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const fetchFinalReports = createAsyncThunk<FinalLocalReport[], number, { rejectValue: Error }>(
  'reports/fetchFinal',
  async (entityId, { rejectWithValue }) => {
    try {
      const reports: FinalLocalReport[] = (
        await httpService.request<FinalLocalReport[]>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `entities/${entityId}/local-file-reports`
        })
      ).data;

      return (
        await Promise.all(
          reports.map(async (report) => {
            const instanceId = report.internalLocalfileReportInstance?.internalLocalfileReportInstanceId;
            return instanceId
              ? httpService.request<{
                  result: { internalLocalfileReportInstance: InternalLocalFileReportInstance };
                }>({
                  method: 'get',
                  apiUrlKey: 'baseUrl',
                  relativePath: `internal-local-file-reports/1/instance/${instanceId}`
                })
              : { data: { result: {} } };
          })
        )
      ).map((details, i) => ({ ...reports[i], ...details.data.result }));
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const getEmailTemplate = createAsyncThunk<EmailTemplate, EmailTemplateArgs, { rejectValue: Error }>(
  'reports/fetchEmailTemplate',
  async (payload, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<EmailTemplateResponse>({
          method: 'get',
          apiUrlKey: 'reportsApiUrl',
          relativePath: `/v1/localfile-report/${payload.reportId}/instance/${payload.localFileReportInstanceId}/email-initialization`
        })
      ).data.data.body;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const getEmailStatus = createAsyncThunk<StatusBody, EmailTemplateArgs, { rejectValue: Error }>(
  'email-status/fetch',
  async (args, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: { body: StatusBody } }>({
          method: 'get',
          apiUrlKey: 'reportsApiUrl',
          relativePath: `/v1/localfile-report/${args.reportId}/instance/${args.localFileReportInstanceId}/email-status`
        })
      ).data.data.body;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);
export const updateEmailStatus = async (payload: EmailStatusPayload) => {
  const { args, body } = payload;
  try {
    return (
      await httpService.request({
        method: 'put',
        apiUrlKey: 'reportsApiUrl',
        relativePath: `/v1/localfile-report/${args.reportId}/instance/${args.localFileReportInstanceId}/email-status`,
        data: body
      })
    ).data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw error;
    }

    throw new Error('Something went wrong');
  }
};

export const sendEmail = async (payload: FormPayload) => {
  const { args, body } = payload;
  try {
    const res = await httpService.request<Record<'data', { emailStatus: number; requestId: string }>>({
      method: 'post',
      apiUrlKey: 'reportsApiUrl',
      relativePath: `/v1/localfile-report/${args.reportId}/instance/${args.localFileReportInstanceId}/send-email`,
      data: body
    });
    return res.data.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw error;
    }

    throw new Error('Something went wrong');
  }
};
