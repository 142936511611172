import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ConnectorProps } from './EntitySectionArea.proptype';
import { useEntitySectionIsOpen } from '../../../../../../hooks/entity/useEntitySectionIsOpen';
import { actions } from '../../../../../../redux/localReports';

const Connector = ({
  component: Component,
  upe,
  selectedEntities,
  selectedLegalEntities,
  selectedTradingPartners
}: ConnectorProps) => {
  const dispatch = useDispatch();

  const emitToggleEntitySectionAccordion = useCallback(
    () => dispatch(actions.toggleAccordionInReportSummary('isEntitiesOpen')),
    [dispatch]
  );

  const selectEntitySectionIsOpen = useEntitySectionIsOpen();

  return (
    <Component
      isOpen={selectEntitySectionIsOpen}
      setIsOpen={emitToggleEntitySectionAccordion}
      selectedEntities={selectedEntities}
      selectedLegalEntities={selectedLegalEntities}
      selectedTradingPartners={selectedTradingPartners}
      upe={upe}
    />
  );
};

export default Connector;
