import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteTransaction,
  fetchPartyRoles,
  fetchServiceEvaluation,
  fetchTransactionById,
  fetchTransactions,
  fetchTransactionTypes,
  saveServiceEvaluation
} from './thunks/transactions';
import { Transaction, TransactionType, PartyRole, TransactionServiceEvaluation } from '../../models';
import { findTransactionDestinationRaw, findTransactionSourceRaw } from '../../utils';
import { getWorkingContainer } from '../baseData/thunks/baseData';

interface TransactionsState {
  transactions: Transaction[] | null;
  transactionSelected: Transaction | null;
  transactionTypes: TransactionType[] | null;
  serviceEvaluationByTransactionId: Record<number, TransactionServiceEvaluation>;
  partyRoles: PartyRole[] | null;
  error?: string;
}

const initialState: TransactionsState = {
  transactions: null,
  transactionSelected: null,
  transactionTypes: null,
  partyRoles: null,
  serviceEvaluationByTransactionId: {}
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchTransactions.fulfilled, (state: TransactionsState, action: PayloadAction<Transaction[]>) => {
        state.transactions = action.payload;
      })
      .addCase(fetchPartyRoles.fulfilled, (state: TransactionsState, action: PayloadAction<PartyRole[]>) => {
        state.partyRoles = action.payload;
      })
      .addCase(
        fetchTransactionTypes.fulfilled,
        (state: TransactionsState, action: PayloadAction<TransactionType[]>) => {
          state.transactionTypes = action.payload;
        }
      )
      .addCase(deleteTransaction.fulfilled, (state: TransactionsState, action: PayloadAction<number>) => {
        const idx = (state.transactions ?? []).findIndex(({ transactionId }) => transactionId === action.payload);
        if (idx >= 0) {
          state.transactions!.splice(idx, 1);
        }
      })
      .addCase(fetchServiceEvaluation.fulfilled, (state: TransactionsState, action) => {
        state.serviceEvaluationByTransactionId[action.meta.arg] = action.payload;
      })
      .addCase(saveServiceEvaluation.fulfilled, (state: TransactionsState, action) => {
        const { transactionId, evaluation } = action.meta.arg;
        state.serviceEvaluationByTransactionId[transactionId] = evaluation;
        if (state.transactions) {
          const transaction = state.transactions.find((transaction) => transactionId === transaction.transactionId);
          if (transaction) {
            const source = findTransactionSourceRaw(transaction);
            if (source) {
              source.completed = evaluation.isSourceComplete;
            }

            const destination = findTransactionDestinationRaw(transaction);
            if (destination) {
              destination.completed = evaluation.isDestinationComplete;
            }
          }
        }
      })
      .addCase(fetchTransactionById.fulfilled, (state: TransactionsState, action) => {
        state.transactionSelected = action.payload;
      })
      .addMatcher(
        (action) => action.type.match(/^transactions\/.+\/pending$/),
        (state: TransactionsState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^transactions\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default transactionsSlice.reducer;
