import { ContainerPolicy, Entity, GlobalGuide, MasterFileReportsInfo, ReportsInfo, Transaction } from '../../models';

export interface ReportDataProps {
  countryName: string;
  domicileId: number;
  domicileStatus: number;
  dueDate: string;
  draft: number;
  entityId: number;
  filingTiming?: string;
  final: number;
  isoCode: string;
  hasReports: boolean;
  jurisdictionId: number;
  published: number;
  review: number;
}

export interface FilingTimingByJurisdiction {
  [isoCode: string]: string | undefined;
}

export interface ReportsProps {
  entityReportData: ReportDataProps[];
  masterFileReportsInfo: MasterFileReportsInfo[];
  containerPolicies: ContainerPolicy[];
  onNavigate: (location: string) => void;
  jurisdictionsCircle: CounterCircle;
  counterCircles: CounterCircle[];
  onSelectTab: (tab: number) => void;
  selectedTab: string;
  tabs: Tab[];
  isLoading: boolean;
}

export interface ReportsTabProps {
  key: string;
  label: string;
  disabled: boolean;
}

export interface ReportsCircleProps {
  percent: number;
  metric: number;
  type: string;
  color: string;
}

export interface ReportsTableProps {
  entityReportData: ReportDataProps[];
  masterFileReportsInfo: MasterFileReportsInfo[];
  containerPolicies: ContainerPolicy[];
  onNavigate: (location: string) => void;
  selectedTab: string;
}

export interface ReportsUtilitiesProps {
  reportsInfo: ReportsInfo[] | null;
  allEntities: Entity[] | null;
  allTransactions: Transaction[] | null;
  globalGuides: GlobalGuide[] | null;
}

export enum ReportsCentralTabs {
  LocalReportTab = 'Local',
  MasterFileReportTab = 'Master File'
}

export interface CounterCircle {
  percent: number;
  metric: number;
  type: string;
  color: string;
}

export interface Tab {
  key: string;
  label: string;
  disabled: boolean;
}

export type ReportsSortBy = 'countryName' | 'dueDate';

export interface ReportsUtilitiesGetNameProps {
  reportName: string;
  reportTemplateId: string;
  version: number;
}

export enum ReportFileTypeName {
  analysisBenchmarkReport = 'Analysis-Benchmark-Report',
  localFile = 'Transfer Pricing Documentation',
  benchmarkReport = 'Benchmark Report'
}
