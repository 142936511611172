import { TFunction } from 'react-i18next';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from '../..';
import { PartyRole, Transaction, TransactionServiceEvaluation, TransactionType } from '../../../models';
import httpService from '../../../services/http';

interface SaveServiceEvaluationParams {
  transactionId: number;
  evaluation: TransactionServiceEvaluation;
}

export const saveTransaction = createAsyncThunk<
  Transaction,
  { transaction: Partial<Transaction>; t: TFunction },
  { state: RootState; rejectValue: Error }
>('transactions/save', async ({ transaction, t }, { rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });
    const isEdit = typeof transaction.transactionId === 'number';
    const { data } = await httpService.request<{ result: Transaction }>({
      method: isEdit ? 'patch' : 'post',
      apiUrlKey: 'baseUrl',
      relativePath: isEdit ? `transactions/${transaction.transactionId!}` : 'transactions',
      data: transaction
    });

    return data.result;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const fetchTransactions = createAsyncThunk<Transaction[], void, { rejectValue: Error }>(
  'transactions/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { transactions: Transaction[] } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'transactions',
          params: { sort: 'identifier', order: 'asc' }
        })
      ).data.result.transactions.map((transaction) => {
        const transferDate = transaction.transferDate ?? '';

        return {
          ...transaction,
          transferDate,
          legalEntityTransactions: (transaction.legalEntityTransactions ?? []).map((pair) => ({
            ...pair,
            // unfortunately, the API returns an inn (0, 1) instead of a boolean.
            // please remove when the API will support boolean.
            completed: Boolean(pair.completed as unknown)
          }))
        };
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const fetchPartyRoles = createAsyncThunk<PartyRole[], void, { rejectValue: Error }>(
  'transactions/party-roles/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: PartyRole[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'party-roles'
        })
      ).data.data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const fetchTransactionTypes = createAsyncThunk<TransactionType[], void, { rejectValue: Error }>(
  'transactions/types/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { transactionTypes: TransactionType[] } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `transaction-types`
        })
      ).data?.result?.transactionTypes;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const deleteTransaction = createAsyncThunk<number, number, { rejectValue: Error }>(
  'transactions/delete',
  async (transactionId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { transactionId: number } }>({
          method: 'delete',
          apiUrlKey: 'baseUrl',
          relativePath: `transactions/${transactionId}`
        })
      ).data.result.transactionId;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const fetchServiceEvaluation = createAsyncThunk<TransactionServiceEvaluation, number, { rejectValue: Error }>(
  'transactions/fetchServiceEvaluation',
  async (transactionId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: TransactionServiceEvaluation }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `transactions/${transactionId}/service-evaluation`
        })
      ).data.result;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const saveServiceEvaluation = createAsyncThunk<void, SaveServiceEvaluationParams, { rejectValue: Error }>(
  'transactions/saveServiceEvaluation/put',
  async ({ transactionId, evaluation }, { rejectWithValue }) => {
    try {
      await httpService.request<{ result: TransactionServiceEvaluation }>({
        method: 'put',
        apiUrlKey: 'baseUrl',
        relativePath: `transactions/${transactionId}/service-evaluation`,
        data: {
          transaction: { transactionId },
          ...evaluation
        }
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const saveServiceEvaluationEditorText = createAsyncThunk<
  void,
  SaveServiceEvaluationParams,
  { rejectValue: Error }
>('transactions/saveServiceEvaluationEditorText/patch', async ({ transactionId, evaluation }, { rejectWithValue }) => {
  try {
    await httpService.request<{ result: TransactionServiceEvaluation }>({
      method: 'patch',
      apiUrlKey: 'baseUrl',
      relativePath: `transactions/${transactionId}/service-evaluation`,
      data: {
        transaction: { transactionId },
        ...evaluation
      }
    });
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const fetchTransactionById = createAsyncThunk<Transaction, number, { rejectValue: Error }>(
  'transactions/by-id/fetch',
  async (transactionId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { transaction: Transaction } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `transactions/${transactionId}`
        })
      ).data.result.transaction;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);
