import { useSelector } from 'react-redux';
import { ControlledTransactionsDocumentationConfig } from '../../models/reports.interface';
import { selectControlledTransactionsDocumentationConfig } from '../../selectors';

export const useSelectedControlledTransactionsDocumentationConfig = (): ControlledTransactionsDocumentationConfig => {
  const selectedControlledTransactionsDocumentationConfig = useSelector(
    selectControlledTransactionsDocumentationConfig
  );

  return selectedControlledTransactionsDocumentationConfig;
};
