/* eslint-disable camelcase */
import ReactGA from 'react-ga4';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { ReportInstanceDownloadProp, SignedUrl } from '../../../app/LocalReports/LocalReports.proptype';
import {
  FinalLocalReport,
  InternalLocalFileReport,
  LocalReportData,
  TemplateReport,
  CheckReportCompSearchesStatusResponse
} from '../../../models';
import { selectTransactionsList } from '../../../selectors';
import httpService from '../../../services/http';
import { getPlatformModule } from '../../../services/platform/platform';
import {
  ConvertToWordPayload,
  PartialEntity,
  PrimaryTradingPartnersMap,
  ReportOrgChartGetPayload,
  ReportOrgChartPayload,
  ReportPayload
} from '../localReports.proptype';

export interface ReportStatusPayload {
  reportId: number;
  reportInstanceId: number;
  containerId: number | undefined;
  reportStatus: string;
}

const generateNewReportOrgChart = async (reportOrgChartPayload: ReportOrgChartPayload) => {
  const { userInputData, containerId, reportId } = reportOrgChartPayload;
  const inputDataTypeId = 1; // org chart
  const url = `container/${String(containerId)}/report/${String(reportId)}/user-input-data`;

  return httpService.request<{ data: { result: Record<string, unknown> } }>({
    method: 'post',
    apiUrlKey: 'tpReportGeneratorApiUrl',
    relativePath: `/v1/${url}`,
    data: { userInputData, inputDataTypeId }
  });
};

export const getReportOrgChart = async (reportOrgChartGetPayload: ReportOrgChartGetPayload) => {
  const { containerId, reportId } = reportOrgChartGetPayload;
  const url = `container/${String(containerId)}/report/${String(reportId)}/user-input-data/1`;
  return httpService.request<{
    data: {
      userInputData: string | undefined;
      result: Record<string, unknown>;
    };
  }>({
    method: 'get',
    apiUrlKey: 'tpReportGeneratorApiUrl',
    relativePath: `/v1/${url}`
  });
};

export const createPrimaryTradingPartnersMap = createAsyncThunk<
  PrimaryTradingPartnersMap,
  number,
  { state: RootState; rejectValue: Error }
>('localReports/createPrimaryTradingPartnersMap', async (jurisdictionId, { getState, rejectWithValue }) => {
  try {
    const primaryEntityList = (
      await httpService.request<{ data: PartialEntity[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `entities/?domicile=${jurisdictionId}&sort=code&order=asc`
      })
    ).data.data;

    const state = getState();
    const transactions = selectTransactionsList(state);
    const primaryEntitiesIds = new Set(primaryEntityList.map((ent) => ent.entityId));
    const primaryTradingPartnersMap: PrimaryTradingPartnersMap = {};

    for (const transaction of transactions ?? []) {
      // filter all the transactions that have one of the primary entities involved or both
      const legalTransactions = transaction.legalEntityTransactions.filter((legalEntityTransaction) => {
        if (legalEntityTransaction?.entity?.entityId) {
          return primaryEntitiesIds.has(legalEntityTransaction.entity.entityId);
        }

        return false;
      });

      if (legalTransactions.length === 0) {
        continue;
      }

      legalTransactions.forEach((e) => {
        const primaryEntity = e.entity;
        const tradingPartner = transaction.legalEntityTransactions.find(
          (tx) => tx.entity.code !== primaryEntity.code
        )?.entity;

        if (tradingPartner) {
          if (!primaryTradingPartnersMap[primaryEntity.entityId]) {
            primaryTradingPartnersMap[primaryEntity.entityId] = {
              selected: false,
              entityId: primaryEntity.entityId,
              code: primaryEntity.code,
              tradingPartners: {}
            };
          }

          if (!primaryTradingPartnersMap[primaryEntity.entityId].tradingPartners[tradingPartner.entityId]) {
            primaryTradingPartnersMap[primaryEntity.entityId].tradingPartners[tradingPartner.entityId] = {
              selected: false,
              entityId: tradingPartner.entityId,
              code: tradingPartner.code,
              transactions: []
            };
          }

          primaryTradingPartnersMap[primaryEntity.entityId].tradingPartners[tradingPartner.entityId].transactions.push({
            ...transaction,
            selected: false,
            ordering: transaction.transactionIdOrder ? transaction.transactionIdOrder : null
          });
        }
      });
    }

    return primaryTradingPartnersMap;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const downloadReportInstance = createAsyncThunk<
  { data: SignedUrl },
  ReportInstanceDownloadProp,
  { rejectValue: Error }
>('localReports/instance/download', async (payload, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/download`;
    const { data }: any = await httpService.request<SignedUrl>({
      method: 'post',
      apiUrlKey: 'docApiUrl',
      relativePath,
      data: payload
    });
    // TODO Move this to connector calling this function
    window.open(data.data.url, '_self');
    return data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const fetchTemplates = createAsyncThunk<TemplateReport[], number, { rejectValue: Error }>(
  'localReports/fetchTemplates',
  async (entityId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: { templates: TemplateReport[] } }>({
          method: 'get',
          apiUrlKey: 'tpReportGeneratorApiUrl',
          relativePath: `/v1/entity/${entityId}/templates`
        })
      ).data.data.templates.map((temp) => {
        /**
         * There are two possible template name formats: The one that has 'Analysis-Benchmark-Report' as a suffix
         * and the one that hasn't.The latter one should use the UI name 'Local File Report'.
         */
        return temp.name.includes('Analysis-Benchmark-Report') ? temp : { ...temp, useName: 'Local File Report' };
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const generateNewReport = createAsyncThunk<
  { reportId: string; reportInstanceId: string },
  ReportPayload,
  { state: RootState; rejectValue: Error }
>('localReports/generateNewReport', async (reportPayload, { rejectWithValue }) => {
  try {
    const { IdentityInfo } = await getPlatformModule();
    const session = await IdentityInfo.getIdentitySession();
    const { userUuid } = session;

    const reportResult = await httpService.request<{ data: { reportId: string; reportInstanceId: string } }>({
      method: 'post',
      apiUrlKey: 'tpReportGeneratorApiUrl',
      relativePath: '/v1/generate-reports',
      data: reportPayload
    });

    const payload = {
      event_category: 'Event Measurement',
      event_label: 'Report Generated',
      containerId: reportPayload.containerId,
      userUuid,
      orgChart: reportPayload.entityStatementOfFactsConfig.displayOrgChart
    };

    ReactGA.set({
      user_id: userUuid,
      container_id: reportPayload.containerId
    });

    ReactGA.event('measurement_report_generation', payload);

    const { reportId } = reportResult.data.data;
    if (reportPayload.orgChart !== undefined) {
      const { orgChart: userInputData, containerId } = reportPayload;

      await generateNewReportOrgChart({
        userInputData,
        containerId,
        reportId
      });
    }

    return reportResult.data.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const fetchWorkingLocalFileReports = createAsyncThunk<InternalLocalFileReport[], number, { rejectValue: Error }>(
  'localReports/workingLocalFile/fetch',
  async (jurisdictionId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<InternalLocalFileReport[]>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `internal-local-file-reports/domicile/${jurisdictionId}/working-reports`
        })
      ).data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const fetchFinalLocalFileReports = createAsyncThunk<FinalLocalReport[], number, { rejectValue: Error }>(
  'localReports/finalLocalFile/fetch',
  async (jurisdictionId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<FinalLocalReport[]>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `internal-local-file-reports/domicile/${jurisdictionId}/final-reports`
        })
      ).data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const fetchReportInstancesForJurisdiction = createAsyncThunk<
  LocalReportData[],
  { jurisdictionId: number; isActive?: boolean; reportId?: string; containerId?: number },
  { rejectValue: Error }
>('localReports/reportInstancesForJurisdiction/fetch', async (params, { rejectWithValue }) => {
  const { jurisdictionId, isActive, containerId } = params;
  try {
    const reportsResult = await httpService.request<LocalReportData[]>({
      method: 'get',
      apiUrlKey: 'baseUrl',
      relativePath: `internal-local-file-reports/domicile-instances/${jurisdictionId}${
        isActive === undefined ? '' : `?isActive=${String(isActive)}`
      }`
    });
    const reportsData: LocalReportData[] = reportsResult.data;

    // now fetch the orgChart for each one.
    if (containerId) {
      const orgChartPromises = reportsData.map(async (report) => {
        const reportId = String(report.internalLocalfileReportId);
        return getReportOrgChart({
          containerId,
          reportId
        });
      });
      const orgChartResults = await Promise.all(orgChartPromises);
      const orgChartData = orgChartResults.map((orgChartResult) => orgChartResult.data.data);
      return reportsData.map((orgChartResult, index) => {
        orgChartResult.orgChart = orgChartData[index].userInputData;

        return orgChartResult;
      });
    }

    return reportsData;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const fetchReportInstancesForJurisdictionNew = createAsyncThunk<
  LocalReportData[],
  { jurisdictionId: number; reportId?: string; containerId?: number },
  { rejectValue: Error }
>('localReports/reportInstancesForJurisdiction/fetch', async (params, { rejectWithValue }) => {
  const { jurisdictionId, containerId } = params;
  try {
    const reportsResult = await httpService.request<{ data: LocalReportData[] }>({
      method: 'get',
      apiUrlKey: 'tpReportGeneratorApiUrl',
      relativePath: `/v1/domicile-instances/${jurisdictionId}`
    });
    const reportsData: LocalReportData[] = reportsResult.data.data;

    // now fetch the orgChart for each one.
    if (containerId) {
      const orgChartPromises = reportsData.map(async (report) => {
        const reportId = String(report.internalLocalfileReportId);
        return getReportOrgChart({
          containerId,
          reportId
        });
      });
      const orgChartResults = await Promise.all(orgChartPromises);
      const orgChartData = orgChartResults.map((orgChartResult) => orgChartResult.data.data);
      return reportsData.map((orgChartResult, index) => {
        orgChartResult.orgChart = orgChartData[index].userInputData;

        return orgChartResult;
      });
    }

    return reportsData;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const convertReportToWord = createAsyncThunk<
  Record<string, unknown>,
  ConvertToWordPayload,
  { state: RootState; rejectValue: Error }
>('localReports/convertToWord', async (payload, { rejectWithValue }) => {
  try {
    const reportResult = await httpService.request<{ data: Record<string, unknown> }>({
      method: 'post',
      apiUrlKey: 'tpReportGeneratorApiUrl',
      relativePath: '/v1/convert-to-word-report',
      data: payload
    });
    return reportResult.data.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const saveReportStatus = createAsyncThunk<
  { reportId: string; reportInstanceId: string; reportStatus: string },
  ReportStatusPayload,
  { state: RootState; rejectValue: Error }
>('localReports/saveReportStatus', async (reportPayload, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: { reportId: string; reportInstanceId: string; reportStatus: string } }>({
        method: 'post',
        apiUrlKey: 'tpReportGeneratorApiUrl',
        relativePath: `/v1/container/${String(reportPayload.containerId)}/report/${String(
          reportPayload.reportId
        )}/instance/${String(reportPayload.reportInstanceId)}/report-status`,
        data: { reportStatus: reportPayload.reportStatus }
      })
    ).data.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});

export const checkReportTransactionCSStatus = createAsyncThunk<
  CheckReportCompSearchesStatusResponse,
  { selectedTransactionIds: number[] },
  { rejectValue: Error }
>('run-report-validations', async ({ selectedTransactionIds }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: CheckReportCompSearchesStatusResponse }>({
        method: 'post',
        apiUrlKey: 'tpReportGeneratorApiUrl',
        relativePath: '/v1/run-report-validations',
        data: { selectedTransactionIds }
      })
    ).data.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error);
    }

    return rejectWithValue(new Error('Something went wrong'));
  }
});
