import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  makeStyles,
  Theme,
  TextField,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import { SetupTabProps } from './SetupTab.proptype';
import { CustomSwitch } from '../../../components/CustomSwitch';
import tokens from '../../../styles/designTokens';
import { body1, body2, body3 } from '../../../styles/typography';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '1.43rem 4.75rem 2.37rem 4.75rem',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  formWrapper: {
    width: '60%',
    minWidth: '43.87rem',
    margin: 'auto',
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    padding: theme.spacing(3),
    paddingBottom: '6.18rem'
  },
  gridWrapper: {
    marginTop: theme.spacing(3)
  },
  label: {
    ...body2,
    color: tokens.core2,
    marginBottom: '0.375rem'
  },
  TextField: {
    width: '100%',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  PlaceHolder: {
    color: theme.palette.text.secondary
  },
  FormControl: {
    width: '100%'
  },
  title: {
    fontFamily: tokens.mediumFont.fontFamily,
    fontSize: '1rem'
  },
  description: {
    ...body3,
    color: tokens.core2
  },
  switchsContainer: {
    marginTop: '1rem',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      display: 'flex',
      alignItems: 'center',
      '&:last-child': {
        marginTop: '1.1rem'
      }
    },
    '& .label': {
      ...body1,
      color: tokens.core1,
      '&.selected': {
        color: tokens.product100
      }
    }
  },
  switch: {
    marginRight: '0.89rem'
  }
}));

export const SetupTab = ({
  defaultTitle,
  currencyOptions,
  defaultCurrency,
  languageOptions,
  defaultLanguage,
  reportTemplateOptions,
  defaultReportTemplate,
  defaultLimitWording,
  defaultAddPageNumber,
  disableTitle,
  onComplete
}: SetupTabProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const formMethods = useForm({
    defaultValues: {
      reportTitle: defaultTitle,
      language: defaultLanguage,
      currency: defaultCurrency,
      reportTemplate: defaultReportTemplate,
      limitWording: defaultLimitWording,
      addPageNumber: defaultAddPageNumber
    }
  });

  const { watch, control, reset, getValues } = formMethods;

  const watchAllFields = watch();

  useEffect(() => {
    reset({
      ...getValues(),
      reportTemplate: defaultReportTemplate,
      reportTitle: defaultTitle,
      language: defaultLanguage,
      limitWording: defaultLimitWording,
      addPageNumber: defaultAddPageNumber
    });
  }, [
    defaultReportTemplate,
    reset,
    getValues,
    defaultLanguage,
    defaultCurrency,
    defaultTitle,
    defaultLimitWording,
    defaultAddPageNumber
  ]);

  useEffect(() => {
    onComplete({
      reportTitle: watchAllFields.reportTitle,
      language: watchAllFields.language,
      currency: watchAllFields.currency,
      reportTemplate: watchAllFields.reportTemplate,
      limitWording: watchAllFields.limitWording,
      addPageNumber: watchAllFields.addPageNumber
    });
  }, [
    watchAllFields.reportTitle,
    watchAllFields.language,
    watchAllFields.currency,
    watchAllFields.reportTemplate,
    watchAllFields.limitWording,
    watchAllFields.addPageNumber,
    onComplete
  ]);

  return (
    <Box className={classes.root}>
      <Box className={classes.formWrapper}>
        <Box className={classes.title}>{t('reports:title-local-report-creation-modal-setup')}</Box>
        <Box className={classes.description}>{t('reports:message-local-report-creation-modal-setup')}</Box>

        <Grid container justify="center" spacing={2} className={classes.gridWrapper}>
          <Grid item xs={12}>
            <Box className={classes.label}>{t('reports:label-local-report-creation-modal-report-title')}</Box>
            <FormControl size="small" variant="outlined" className={classes.FormControl}>
              <Controller
                name="reportTitle"
                control={control}
                render={({ value, onChange }) => (
                  <TextField
                    id="report-title"
                    placeholder={t('reports:placeholder-local-report-creation-modal-report-title')}
                    className={classes.TextField}
                    value={value}
                    margin="dense"
                    variant="outlined"
                    disabled={disableTitle}
                    onChange={onChange}
                  />
                )}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.label}>{t('reports:label-local-report-creation-modal-language')}</Box>
            <FormControl size="small" variant="outlined" className={classes.FormControl}>
              <Controller
                name="language"
                control={control}
                render={({ value, onChange }) => (
                  <Select value={value} onChange={onChange}>
                    {languageOptions.map(({ isoCode, displayName }) => (
                      <MenuItem key={isoCode} value={isoCode}>
                        {displayName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.label}>{t('reports:label-local-report-creation-modal-currency')}</Box>
            <FormControl size="small" variant="outlined" className={classes.FormControl}>
              <Controller
                name="currency"
                control={control}
                render={({ value, onChange }) => (
                  <Select value={value} onChange={onChange}>
                    {currencyOptions.map(({ isoCode, name, currencyId }) => (
                      <MenuItem key={currencyId} value={currencyId}>
                        {`${isoCode} - ${name}`}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.label}>{t('reports:label-local-report-creation-modal-report-template')}</Box>
            <FormControl size="small" variant="outlined" className={classes.FormControl}>
              <Controller
                name="reportTemplate"
                control={control}
                render={({ value, onChange }) => (
                  <Select value={value} onChange={onChange}>
                    {reportTemplateOptions.map(({ templateId, useName }) => (
                      <MenuItem key={templateId} value={templateId}>
                        {useName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs />
          <Grid item xs={6}>
            <FormControl
              size="small"
              variant="outlined"
              className={classes.FormControl + ' ' + classes.switchsContainer}
            >
              <Controller
                name="limitWording"
                control={control}
                render={({ value, onChange }) => (
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={value}
                        className={classes.switch}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Box className={'label' + (value ? ' selected' : '')}>
                        {t('reports:label-local-report-creation-modal-limit-wording')}
                      </Box>
                    }
                  />
                )}
                rules={{ required: true }}
              />
              <Controller
                name="addPageNumber"
                control={control}
                render={({ value, onChange }) => (
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={value}
                        className={classes.switch}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Box className={'label' + (value ? ' selected' : '')}>
                        {t('reports:label-local-report-creation-modal-page-number')}
                      </Box>
                    }
                  />
                )}
                rules={{ required: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs />
        </Grid>
      </Box>
    </Box>
  );
};
