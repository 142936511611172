import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, CircularProgress, makeStyles } from '@material-ui/core';
import { TransactionAreaProps } from './TransactionSectionArea.proptype';
import { Transaction } from '../../../../../../models';
import tokens from '../../../../../../styles/designTokens';
import { captionOverline } from '../../../../../../styles/typography';
import { Table } from '../../../../../Table';
import { TableColumnDef } from '../../../../../Table/Table.proptype';
import { ActionTable } from '../../../components/ActionTable/ActionTable';
import { CheckIcon, CrossIcon } from '../Icons';

const useStyles = makeStyles(() => ({
  actionTableMargin: {
    margin: '1.5rem 0'
  },
  table: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  chip: {
    '& .MuiChip-root': {
      borderRadius: '0.25rem',
      ...captionOverline,
      color: tokens.product100,
      backgroundColor: tokens.product15
    },
    '& .MuiChip-label': {
      paddingLeft: '0.47rem'
    }
  },
  chipDisabled: {
    '& .MuiChip-root': {
      borderRadius: '0.25rem',
      ...captionOverline,
      color: tokens.core1,
      backgroundColor: tokens.neutral70
    },
    '& .MuiChip-label': {
      paddingLeft: '0.47rem'
    }
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  spinner: {
    height: '2.3rem',
    width: '2.3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const getEntitiesStringFromTransaction = (transaction: Transaction): string => {
  const entities = transaction.legalEntityTransactions;
  const sourceEntityIndex = entities.findIndex((ent) => ent.isSource);
  return `${entities[sourceEntityIndex].entity.code} → ${entities[sourceEntityIndex ^ 1].entity.code}`;
};

export const TransactionSectionArea = ({
  selectedTransactions,
  setIsOpen,
  isOpen,
  controlledTransactionSelection
}: TransactionAreaProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const tableTitle = useMemo(() => {
    const activeTransactions = controlledTransactionSelection?.transactionsSelectedIds?.length ?? '0';
    const totalTransactions = selectedTransactions.length;
    return `${activeTransactions}/${totalTransactions}`;
  }, [controlledTransactionSelection, selectedTransactions]);

  const transactionTableColumns: TableColumnDef[] = [
    {
      key: 'transactionName',
      header: t('reports:configurationTab-ReportSummary-TransactionArea-transactionTableColumns-headers-name'),
      width: '75%'
    },
    {
      key: 'state',
      header: t('reports:configurationTab-ReportSummary-TransactionArea-transactionTableColumns-headers-details'),
      width: '15%'
    },
    { key: 'action', header: '', width: '10%' }
  ];

  const isActive = useCallback(
    (t: Transaction) => {
      const selected = Boolean(
        controlledTransactionSelection.transactionsSelectedIds?.some((id: number) => id === t.transactionId)
      );

      return (
        <Box className={selected ? classes.chip : classes.chipDisabled}>
          <Chip
            size="small"
            label={<span className={classes.chipLabel}>{selected ? <CheckIcon /> : <CrossIcon />} SOF</span>}
          />
        </Box>
      );
    },
    [controlledTransactionSelection, classes.chip, classes.chipDisabled, classes.chipLabel]
  );

  const transactions = useMemo(() => {
    return selectedTransactions.map((t: any) => {
      return {
        transactionName: getEntitiesStringFromTransaction(t),
        state: isActive(t),
        action: ''
      };
    });
  }, [selectedTransactions, isActive]);

  const actionTableProps =
    selectedTransactions.length === 0
      ? {
          title: (
            <Box className={classes.spinner}>
              <CircularProgress color="primary" />
            </Box>
          )
        }
      : {
          title: 'Transactions',
          chip: tableTitle
        };
  return (
    <Box className={classes.actionTableMargin}>
      <ActionTable id="report-summary-accordion-3" isOpen={isOpen} setIsOpen={setIsOpen} {...actionTableProps}>
        <Box className={classes.table}>
          <Table columns={transactionTableColumns} data={transactions} />
        </Box>
      </ActionTable>
    </Box>
  );
};
