import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../redux/localReports';

export function useReorderingAppendix() {
  const [isDragging, setDragging] = useState(() => false);
  const dispatch = useDispatch();

  const onDragStart = () => {
    setDragging(() => true);
  };

  const onDragEnd = (result: any) => {
    setDragging(() => false);
    dispatch(
      actions.reorderAppendix({
        appendixIdentifier: result.draggableId,
        source: result.source.index,
        destination: result.destination.index
      })
    );
  };

  return { onDragStart, isDragging, onDragEnd };
}
