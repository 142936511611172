import { createSelector } from 'reselect';
import { NormalizedStateSlice } from '../models';
import { RootState } from '../redux';

function makeArray<T>({ allIds, byId }: NormalizedStateSlice<T>): T[] {
  let response: T[] = [];

  if (byId) {
    response = allIds.map((id) => byId[id]);
  }

  return response;
}

export const selectContainers = createSelector(
  ({ baseData: { containers } }: RootState) => containers,
  (containers) => makeArray(containers)
);

export const selectCountries = createSelector(
  ({ baseData: { countries } }: RootState) => countries,
  (countries) => makeArray(countries)
);

export const selectCountry = (id?: number | null) =>
  createSelector(
    ({ baseData: { countries } }: RootState) => countries.byId ?? {},
    (byId) => (id ? byId[id] : undefined)
  );

export const selectCurrencies = createSelector(
  ({ baseData: { currencies } }: RootState) => currencies,
  (currencies) => makeArray(currencies)
);

export const selectCurrency = (id?: number | null) =>
  createSelector(
    ({ baseData: { currencies } }: RootState) => currencies.byId ?? {},
    (byId) => (id ? byId[id] : undefined)
  );

export const selectPrimaryFunctions = createSelector(
  ({ baseData: { primaryFunctions } }: RootState) => primaryFunctions,
  (primaryFunctions) => makeArray(primaryFunctions)
);

export const selectWorkingContainer = createSelector(
  ({ baseData: { containers } }: RootState) => containers.byId ?? {},
  ({ baseData: { workingContainerId } }: RootState) => workingContainerId,
  (byId, id) => (id ? byId[id] : undefined)
);

export const selectUPECurrency = createSelector(
  ({ baseData: { currencies } }: RootState) => currencies.byId ?? {},
  ({ baseData: { upeCurrencyId } }: RootState) => upeCurrencyId,
  (byId, id) => (id ? byId[id] : undefined)
);

export const selectUPE = createSelector(
  ({ entities: { entities } }: RootState) => entities ?? [],
  ({ baseData: { upeId } }: RootState) => upeId,
  (entities, id) => entities.find((entity) => entity.entityId === id)
);

export const selectLanguages = createSelector(
  ({ baseData: { languages } }: RootState) => languages,
  (languages) => makeArray(languages)
);

export const selectIsBaseDataFetch = (state: RootState) => state.baseData.isBaseDataFetch;
