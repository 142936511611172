import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { ConnectorProps } from './ReportTile.proptype';
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags';
import { CheckReportCompSearchesStatusResponse, Transaction } from '../../../../models';
import { checkReportTransactionCSStatus } from '../../../../redux/localReports/thunks/localReports';
import { getEmailStatus } from '../../../../redux/reports/thunks/reports';
import { AppDispatch } from '../../../../store';
import { hasReportEmailAccess } from '../../../../utils';

const Connector = ({
  component: Component,
  errorType,
  status,
  wordStatus,
  wordReportBinaryPath,
  name,
  primaryEntities,
  tradingPartners,
  transactions,
  version,
  language,
  date,
  generationStatus,
  isUploaded,
  internalLocalfileReportId,
  internalLocalfileReportInstanceId,
  jurisdictionName,
  containerYear,
  startDeleteReport,
  onDownloadPDF,
  onConvertWord,
  onDownloadWord,
  onManageVersions,
  onNewReportVersion,
  quickGenerate,
  openReportPreview,
  openReportModal,
  onUpdateReportStatus
}: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [featureFlagIsActive] = useFeatureFlags();
  const [emailStatus, setEmailStatus] = useState<number | null>(null);
  const [compSearchStatus, setCompSearchStatus] = useState<CheckReportCompSearchesStatusResponse | null>(null);

  useEffect(() => {
    if (hasReportEmailAccess()) {
      const fetchEmailStatus = async () => {
        const response = unwrapResult(
          await dispatch(
            getEmailStatus({
              reportId: internalLocalfileReportId,
              localFileReportInstanceId: internalLocalfileReportInstanceId
            })
          )
        );
        if (response) {
          setEmailStatus(response.emailStatus);
        }
      };

      void fetchEmailStatus();
    }

    const fetchCompsearchStatus = async () => {
      const selectedTransactionIds = transactions
        .map((transaction: Partial<Transaction>) => transaction.transactionId)
        .filter((transactionId: number | undefined) => transactionId!!) as number[];
      const response = unwrapResult(
        await dispatch(
          checkReportTransactionCSStatus({
            selectedTransactionIds
          })
        )
      );
      if (response) {
        setCompSearchStatus(response);
      }
    };

    if (featureFlagIsActive('checkCompSearchStatusOnReportFailure')) {
      void fetchCompsearchStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, transactions, internalLocalfileReportId, internalLocalfileReportInstanceId]);

  return (
    <Component
      status={status}
      errorType={errorType}
      reportCompsearchesStatus={compSearchStatus}
      wordStatus={wordStatus}
      wordReportBinaryPath={wordReportBinaryPath}
      name={name}
      primaryEntities={primaryEntities}
      emailStatus={emailStatus}
      setEmailStatus={(num) => {
        setEmailStatus(num);
      }}
      tradingPartners={tradingPartners}
      transactions={transactions}
      version={version}
      language={language}
      date={date}
      generationStatus={generationStatus}
      isUploaded={isUploaded}
      internalLocalfileReportId={internalLocalfileReportId}
      internalLocalfileReportInstanceId={internalLocalfileReportInstanceId}
      jurisdictionName={jurisdictionName}
      containerYear={containerYear}
      startDeleteReport={startDeleteReport}
      quickGenerate={quickGenerate}
      openReportPreview={openReportPreview}
      openReportModal={openReportModal}
      onUpdateReportStatus={onUpdateReportStatus}
      onDownloadPDF={onDownloadPDF}
      onConvertWord={onConvertWord}
      onDownloadWord={onDownloadWord}
      onManageVersions={onManageVersions}
      onNewReportVersion={onNewReportVersion}
    />
  );
};

export default Connector;
