import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContainerPolicy } from '../../../models';
import httpService from '../../../services/http';

export const fetchContainerPolicies = createAsyncThunk<ContainerPolicy[], void, { rejectValue: Error }>(
  'localReports/fetchContainerPolicies',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<any>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `containers/policies`
        })
      ).data.data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);
