import { createAsyncThunk } from '@reduxjs/toolkit';
import { GlobalGuide } from '../../../models';
import httpService from '../../../services/http';

export const fetchGlobalGuides = createAsyncThunk<GlobalGuide[], void, { rejectValue: Error }>(
  'globalGuides/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: GlobalGuide[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'wtp/mne-world'
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);
