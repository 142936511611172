import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteEntity, fetchEntities, saveEntity } from './thunks/entities';
import { Entity } from '../../models';
import { getDateAsTimestamp } from '../../utils/dates';
import { getWorkingContainer } from '../baseData/thunks/baseData';

interface EntitiesState {
  entities: Entity[] | null;
  error?: string;
}

const initialState: EntitiesState = { entities: null };

const entitiesSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchEntities.fulfilled, (state: EntitiesState, action: PayloadAction<Entity[]>) => {
        state.entities = action.payload.map((entity) => {
          const fiscalYearEnd = getDateAsTimestamp(entity.fiscalYearEnd);
          const prepareByDate = getDateAsTimestamp(entity.prepareByDate);
          return {
            ...entity,
            fiscalYearEnd,
            prepareByDate
          };
        });
      })
      .addCase(deleteEntity.fulfilled, (state: EntitiesState, action: PayloadAction<number>) => {
        const idx = state.entities?.findIndex(({ entityId }) => entityId === action.payload);
        if (idx !== -1 && idx !== undefined) {
          state.entities!.splice(idx, 1);
        }
      })
      .addCase(saveEntity.fulfilled, (state: EntitiesState, action: PayloadAction<Entity>) => {
        if (state.entities === null) {
          state.entities = [action.payload];
        } else {
          const idx = state.entities.findIndex(({ entityId }) => entityId === action.payload.entityId);
          if (idx >= 0) {
            state.entities[idx] = action.payload;
          } else {
            state.entities.push(action.payload);
          }
        }
      })
      .addMatcher(
        (action) => action.type.match(/^entities\/.+\/pending$/),
        (state: EntitiesState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^entities\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default entitiesSlice.reducer;
