import { makeStyles, Box, Button } from '@material-ui/core';
import { CustomTooltip } from '../../components/CustomTooltip';
import { ArrowMove } from '../../svgs';

export interface ActionButtonProps {
  buttonText: string;
  isReady: boolean;
  action: () => void;
  id?: string;
  disabled: boolean;
  tooltipText?: string;
}

const useStyles = makeStyles((theme) => ({
  isReady: {
    color: theme.palette.primary.dark,
    background: theme.palette.action.selected,
    minWidth: '10rem',
    '&:hover': {
      background: theme.palette.action.selected
    },
    svg: {
      color: theme.palette.primary.dark
    }
  },
  notReady: {
    background: theme.palette.secondary.light,
    '&:hover': {
      background: theme.palette.secondary.light
    },
    minWidth: '10rem'
  },
  svg: {
    color: theme.palette.primary.light
  }
}));

export const ActionButton = ({ buttonText, isReady, action, id, disabled, tooltipText }: ActionButtonProps) => {
  const classes = useStyles();
  return tooltipText ? (
    <CustomTooltip title={tooltipText}>
      <Box>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          id={id}
          className={isReady ? classes.isReady : classes.notReady}
          endIcon={<ArrowMove />}
          disabled={disabled}
          style={disabled ? { pointerEvents: 'all' } : {}}
          onClick={() => {
            action();
          }}
        >
          {buttonText}
        </Button>
      </Box>
    </CustomTooltip>
  ) : (
    <Button
      fullWidth
      color="secondary"
      variant="contained"
      id={id}
      className={isReady ? classes.isReady : classes.notReady}
      endIcon={<ArrowMove />}
      disabled={disabled}
      onClick={() => {
        action();
      }}
    >
      {buttonText}
    </Button>
  );
};
