import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app/index';
import { Authentication } from './hocs/authenticate';
import { BaseDataLoader } from './hocs/baseDataLoader';
import { Configuration } from './hocs/configuration';
import GoogleAnalyticsProvider from './hocs/GoogleAnalyticsProvider';
import LaunchDarklyFeatureFlagInit from './hocs/LaunchDarkly';
import { Rum } from './hocs/Rum';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { setupI18n } from './utils';

void setupI18n();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Configuration>
        <GoogleAnalyticsProvider>
          <Authentication>
            <>
              <Rum />
              <BaseDataLoader>
                <LaunchDarklyFeatureFlagInit>
                  <App />
                </LaunchDarklyFeatureFlagInit>
              </BaseDataLoader>
            </>
          </Authentication>
        </GoogleAnalyticsProvider>
      </Configuration>
    </Provider>
  </React.StrictMode>,
  document.querySelector('#root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
